import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';
import {
  RecordVoiceOver as VoiceIcon,
  TextFields as TextIcon,
  VideoLibrary as VideoIcon,
} from '@material-ui/icons';
import moment from 'moment';

import * as firebase from 'firebase/app';
import 'firebase/firestore';

import { withStore } from '../context';

const useStyles = makeStyles((theme) => ({
  normalAvatar: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
  },
  selectedAvatar: {
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    backgroundColor: theme.palette.secondary.main,
  },
}));

const Component = ({ dsns, devices, target, changeTarget }) => {
  const classes = useStyles();
  const [selected, setSelected] = useState(-1);
  const [items, setItems] = useState([]);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection('broadcasts')
      .where('dsns', '==', dsns)
      .onSnapshot((querySnapshot) => {
        const data = [];
        querySnapshot.forEach((doc) => {
          data.push({
            docId: doc.id,
            ...doc.data(),
            time: moment(parseInt(doc.id, 10)),
            sort: parseInt(doc.id, 10),
          });
        });
        data.sort((x, y) => (y.sort > x.sort ? 1 : -1));

        setItems(data);
      });

    return () => unsubscribe();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!target) {
      setSelected(-1);
    }
    // eslint-disable-next-line
  }, [target]);

  return (
    <List>
      {items.map((item, index) => (
        <React.Fragment key={`item-${item.docId}`}>
          <ListItem
            button
            selected={index === selected}
            onClick={() => {
              setSelected(index);
              firebase
                .firestore()
                .collection(`/broadcasts/${item.docId}/devices`)
                .get()
                .then((querySnapshot) => {
                  const items = item.targets.map((target) => {
                    const [data] = devices.filter((d) => target === d.deviceId);

                    let readed = false;
                    if (data) {
                      querySnapshot.forEach((doc) => {
                        if (data.deviceId === doc.id) {
                          readed = true;
                        }
                      });
                    }

                    return { ...data, readed };
                  });

                  changeTarget({ ...item, devices: items });
                });
            }}
          >
            <ListItemAvatar>
              <Avatar
                className={
                  index === selected
                    ? classes.selectedAvatar
                    : classes.normalAvatar
                }
              >
                {
                  {
                    text: <TextIcon />,
                    voice: <VoiceIcon />,
                    video: <VideoIcon />,
                  }[item.type]
                }
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={item.title}
              secondary={item.time.format('YYYY-MM-DD HH:mm')}
            />
          </ListItem>
          {index < items.length - 1 && <Divider variant="fullWidth" />}
        </React.Fragment>
      ))}
    </List>
  );
};

export default withStore(Component);
