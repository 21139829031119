import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Avatar,
  Button,
  BottomNavigation,
  BottomNavigationAction,
  Chip,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Menu,
  MenuItem,
  Slide,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  CheckCircleOutline as ConfirmIcon,
  HighlightOffOutlined as CancleIcon,
  List as ListIcon,
  MoreVert as MoreIcon,
  Pets as PetsIcon,
} from '@material-ui/icons';

import moment from 'moment';
import * as firebase from 'firebase/app';
import 'firebase/firestore';

import { AppContext } from '../../../App';

const useStyles = makeStyles((theme) => ({
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  modal: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
    borderRadius: 0,
    padding: theme.spacing(2),
    backgroundColor: '#fff',
  },
  section: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  centerContent: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
  },
  bottom: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: theme.spacing(7),
    padding: theme.spacing(1),
  },
  avatar: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
  },
  chip: {
    fontSize: '1rem',
    margin: theme.spacing(0.5, 0),
  },
}));

const Component = ({ callback }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { state } = useContext(AppContext);
  const [number, setNumber] = useState(4);
  const [time, setTime] = useState(0);
  const [inputText, setInputText] = useState('');
  const [numberVisible, setNumberVisible] = useState(false);
  const [inputVisible, setInputVisible] = useState(false);
  const [historyVisible, setHistoryVisible] = useState(false);
  const [histories, setHistories] = useState([]);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection(`/classrooms/${state.roomId}/interactives`)
      .onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (doc.id !== '0000000000000') {
            items.push({
              id: doc.id,
              data,
              primaryText: `${data.number} 個選項`,
              secondaryText: moment(data.timestamp).format(
                'YYYY-MM-DD HH:mm:ss',
              ),
            });
          }
        });

        items.sort((x, y) => (x.timestamp > y.timestamp ? 1 : -1));
        setHistories(
          items.map((item, index) => ({ ...item, avatar: index + 1 })),
        );
      });

    return () => unsubscribe();
    // eslint-disable-next-line
  }, []);

  const start = () => {
    if (number === 0) {
      return;
    }

    const timestamp = Date.now();
    const event = {
      timestamp,
      status: 'close',
      number,
      time,
    };

    callback();

    firebase.firestore().doc(`/classrooms/${state.roomId}/events/main`).set({
      key: 'classroom.interactive.open',
      timestamp,
    });

    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/interactives/0000000000000`)
      .set(event);
    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/interactives/${timestamp}`)
      .set(event);
  };

  const renderNumberDialog = () =>
    numberVisible && (
      <div className={clsx(classes.section, classes.modal)}>
        <div className={classes.content}>
          {[
            { key: 'number-1', value: 2 },
            { key: 'number-2', value: 3 },
            { key: 'number-3', value: 4 },
            { key: 'number-4', value: 5 },
            { key: 'number-5', value: 6 },
            { key: 'number-6', value: 7 },
            { key: 'number-7', value: 8 },
          ].map((item) => (
            <Chip
              key={`item-${item.key}`}
              className={classes.chip}
              label={item.value}
              variant="default"
              color="primary"
              onClick={() => {
                setNumberVisible(false);
                setNumber(item.value);
              }}
            />
          ))}
        </div>
        <div className={classes.row}>
          <IconButton color="primary" onClick={() => setNumberVisible(false)}>
            <ConfirmIcon style={{ fontSize: '3rem' }} />
          </IconButton>
        </div>
      </div>
    );

  const renderInputDialog = () =>
    inputVisible && (
      <div className={clsx(classes.section, classes.modal)}>
        <div className={classes.content}>
          <Typography variant="h5" gutterBottom>
            倒數時間
          </Typography>
          <TextField
            type="number"
            variant="outlined"
            onInput={(e) => {
              setInputText(e.target.value);
            }}
          />
        </div>
        <div className={classes.row}>
          <IconButton color="secondary" onClick={() => setInputVisible(false)}>
            <CancleIcon style={{ fontSize: '3rem' }} />
          </IconButton>
          <div style={{ width: theme.spacing(3) }} />
          <IconButton
            color="primary"
            onClick={() => {
              setInputVisible(false);
              setTime(parseInt(inputText, 10) || 0);
            }}
          >
            <ConfirmIcon style={{ fontSize: '3rem' }} />
          </IconButton>
        </div>
      </div>
    );

  const renderHistoryModal = () =>
    historyVisible && (
      <div
        className={clsx(classes.section, classes.modal)}
        style={{ padding: 0 }}
      >
        <List>
          {histories.map((item, index) => (
            <React.Fragment key={item.data.timestamp}>
              <HistoryItem item={item} callback={callback} />
              {index !== histories.length - 1 && (
                <Divider variant="fullWidth" />
              )}
            </React.Fragment>
          ))}
        </List>
      </div>
    );

  const renderContent = () => (
    <div className={classes.section}>
      <div className={clsx(classes.content, classes.centerContent)}>
        <div className={classes.row}>
          <Button onClick={() => setNumberVisible(true)}>
            <Typography variant="h3" color="primary">
              {number}
            </Typography>
          </Button>
          <Typography variant="h5">個選項</Typography>
        </div>
        <div className={classes.row}>
          <Button onClick={() => setInputVisible(true)}>
            <Typography variant="h3" color="primary">
              {time}
            </Typography>
          </Button>
          <Typography variant="h5">秒自動結束</Typography>
        </div>
        <div className={classes.row} style={{ marginTop: theme.spacing(2) }}>
          <IconButton color="primary" onClick={start}>
            <PetsIcon style={{ fontSize: '3rem' }} />
          </IconButton>
        </div>
      </div>
      <div className={classes.bottom}>
        <BottomNavigation showLabels>
          <BottomNavigationAction
            label="歷史記錄"
            icon={<ListIcon />}
            onClick={() => setHistoryVisible(true)}
          />
        </BottomNavigation>
      </div>
    </div>
  );

  return (
    <Slide in direction="up" timeout={1000}>
      <div className={classes.main}>
        {renderNumberDialog()}
        {renderInputDialog()}
        {renderHistoryModal()}
        {renderContent()}
      </div>
    </Slide>
  );
};

export default Component;

const HistoryItem = ({ item, callback }) => {
  const classes = useStyles();
  const { state } = useContext(AppContext);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  return (
    <>
      <Menu
        onClose={() => setMenuAnchorEl(null)}
        open={Boolean(menuAnchorEl)}
        anchorEl={menuAnchorEl}
      >
        <MenuItem
          onClick={() => {
            const timestamp = moment().format('MMDDHHmm');
            const content = [
              `學生,${Array.from({ length: item.data.number })
                .map((i, idx) => `選項 ${idx + 1}`)
                .join(',')}`,
            ];
            Object.keys(item.data).forEach((key) => {
              if (key.includes('student')) {
                const option = Array.from({ length: item.data.number }).map(
                  (i) => '',
                );
                option[item.data[key].content - 1] = 'V';
                content.push(
                  `${item.data[key].name || '訪客'},${option.join(',')}`,
                );
              }
            });

            var element = document.createElement('a');
            element.setAttribute(
              'href',
              `data:text/plain;charset=utf-8,\uFEFF${encodeURIComponent(
                content.join('\r\n'),
              )}`,
            );
            element.setAttribute(
              'download',
              `${state.target.classroom.name}_快問快答_${timestamp}.csv`,
            );

            element.style.display = 'none';
            (document.body || {}).appendChild(element);

            element.click();

            (document.body || {}).removeChild(element);

            setMenuAnchorEl(null);
          }}
        >
          下載
        </MenuItem>
        <MenuItem
          onClick={() => {
            firebase
              .firestore()
              .doc(`/classrooms/${state.roomId}/interactives/${item.id}`)
              .delete();
            setMenuAnchorEl(null);
          }}
        >
          刪除
        </MenuItem>
      </Menu>
      <ListItem
        button
        onClick={() => {
          firebase
            .firestore()
            .doc(`/classrooms/${state.roomId}/events/main`)
            .set({
              key: 'classroom.interactive.open',
              timestamp: Date.now(),
            });
          firebase
            .firestore()
            .doc(`/classrooms/${state.roomId}/interactives/0000000000000`)
            .set(item.data);
          callback();
        }}
      >
        <ListItemAvatar>
          <Avatar className={classes.avatar}>{item.avatar}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={item.primaryText}
          secondary={item.secondaryText}
        />
        <ListItemSecondaryAction>
          <IconButton edge="end" onClick={(e) => setMenuAnchorEl(e.target)}>
            <MoreIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
};
