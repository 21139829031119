import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

import { AppContext } from '../../App';
import { Provider } from './context';

import Broadcast from './broadcast';
import Device from './device';

const firebaseStorage = firebase.initializeApp(
  {
    apiKey: 'AIzaSyBTAR8KFcKwzx-JsdqWlTU7UmXG4NaThGA',
    databaseURL: 'https://classroom-1campus.firebaseio.com',
    projectId: 'classroom-1campus',
    storageBucket: 'classroom-1campus-devices',
    messagingSenderId: '973016711227',
  },
  'classroom-1campus-devices',
);

const counties = [
  '臺北市',
  '新北市',
  '桃園市',
  '臺中市',
  '臺南市',
  '高雄市',
  '基隆市',
  '新竹縣',
  '新竹市',
  '苗栗縣',
  '彰化縣',
  '南投縣',
  '雲林縣',
  '嘉義縣',
  '嘉義市',
  '屏東縣',
  '宜蘭縣',
  '花蓮縣',
  '臺東縣',
  '澎湖縣',
  '金門縣',
  '連江縣',
];

const useStyles = makeStyles((theme) => ({
  loading: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,.5)',
  },
}));

const SERVICE_USER_TOKEN =
  'https://us-central1-classroom-1campus.cloudfunctions.net/getUserToken?accessToken=_ACCESS_TOKEN_';
const SERVICE_ALL_CLASSES =
  'https://dsns.ischool.com.tw/_DSNS_/1campus.mobile.v2.dominator/GetAllClasses?rsptype=json&stt=PassportAccessToken&AccessToken=_ACCESS_TOKEN_&parser=spliter';
const SERVICE_CC_ITEMS =
  'https://dsns.ischool.com.tw/_DSNS_/1campus.mobile.v2.teacher/classroom.GetCCItems?rsptype=json&stt=PassportAccessToken&AccessToken=_ACCESS_TOKEN_&parser=spliter';

const Component = () => {
  const classes = useStyles();
  const { type, userName, dsns, accessToken } = useParams();
  const { state } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useState({});
  const [feeds, setFeeds] = useState([]);
  const [target, changeTarget] = useState(null);

  useEffect(() => {
    const messageListener = (event) => {
      try {
        const { action, data } = JSON.parse(event.data);

        if (action === 'RESPONSE_OPENDATA_PM_25') {
          const feeds = []
            .concat(data.feeds || [])
            .map((item) => ({
              ...item,
              index: counties.indexOf(item.County),
            }))
            .sort((x, y) => (x.index > y.index ? 1 : -1))
            .map((item) => ({
              siteName: item.SiteName,
              deviceId: item.device_id,
              county: item.County,
              caption: `${item.County} - ${item.SiteName}`,
            }));

          setFeeds(feeds);
        }
      } catch (err) {
        /** empty */
      }
    };

    window.addEventListener('message', messageListener);

    state.communication.postMessage(
      JSON.stringify({
        action: 'REQUEST_OPENDATA_PM_25',
      }),
    );

    return () => {
      window.removeEventListener('message', messageListener);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (feeds.length === 0) return;

    fetch(SERVICE_USER_TOKEN.replace(/_ACCESS_TOKEN_/gi, accessToken))
      .then((response) => response.json())
      .then((response) => {
        if (response.token) {
          firebase.auth().signInWithCustomToken(response.token);
          firebaseStorage.auth().signInWithCustomToken(response.token);
        } else {
          window.localStorage.clear();
          window.location.reload();
        }
      });

    if (type === 'device') {
      Promise.all([new Promise(fetchClasses), new Promise(fetchCCItems)]).then(
        (response) => {
          setConfig({
            userName,
            dsns,
            accessToken,
            classes: response[0],
            ccItems: response[1],
            airboxSites: feeds,
            counties,
          });
          setLoading(false);
        },
      );
    } else if (type === 'broadcast') {
      firebase
        .firestore()
        .collection('devices')
        .where('dsns', '==', dsns)
        .get()
        .then((querySnapshot) => {
          const devices = [];
          querySnapshot.forEach((doc) => {
            const item = doc.data();
            devices.push({
              ...item,
              key: doc.id,
              label: item.classroomName.substr(0, 1) || 'C',
            });
          });
          devices.sort((x, y) => (x.deviceId > y.deviceId ? 1 : -1));

          setConfig({ userName, dsns, accessToken, devices });
          setLoading(false);
        })
        .catch(() => {
          setConfig({ userName, dsns, accessToken, devices: [] });
          setLoading(false);
        });
    }
    // eslint-disable-next-line
  }, [feeds]);

  const fetchClasses = (rs) => {
    fetch(
      SERVICE_ALL_CLASSES.replace(/_DSNS_/gi, dsns).replace(
        /_ACCESS_TOKEN_/gi,
        accessToken,
      ),
    )
      .then((response) => response.json())
      .then((response) =>
        rs(
          []
            .concat(((response.__Body || response).ClassList || {}).Class || [])
            .map((item) => ({
              id: `${item.ClassID}`,
              name: `${item.ClassName}`,
            })),
        ),
      )
      .catch(() => rs([]));
  };

  const fetchCCItems = (rs) => {
    fetch(
      SERVICE_CC_ITEMS.replace(/_DSNS_/gi, dsns).replace(
        /_ACCESS_TOKEN_/gi,
        accessToken,
      ),
    )
      .then((response) => response.json())
      .then((response) => {
        rs(
          []
            .concat(((response.__Body || response).Items || {}).Item || [])
            .filter((item) => item.Type === 'Class')
            .map((item) => item.UID),
        );
      })
      .catch(() => rs([]));
  };

  return loading ? (
    <div className={classes.loading}>
      <CircularProgress style={{ color: '#f1f1f1' }} />
    </div>
  ) : (
    <Provider value={{ ...config, target, changeTarget }}>
      {type === 'device' && <Device />}
      {type === 'broadcast' && <Broadcast />}
    </Provider>
  );
};

export default Component;
