import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Avatar,
  Button,
  BottomNavigation,
  BottomNavigationAction,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Menu,
  MenuItem,
  Slide,
  Typography,
} from '@material-ui/core';
import { List as ListIcon, MoreVert as MoreIcon } from '@material-ui/icons';

import moment from 'moment';
import * as firebase from 'firebase/app';
import 'firebase/firestore';

import { AppContext } from '../../../App';

const useStyles = makeStyles((theme) => ({
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  modal: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
    borderRadius: 0,
    padding: theme.spacing(2),
    backgroundColor: '#fff',
  },
  section: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  centerContent: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  bottom: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: theme.spacing(7),
    padding: theme.spacing(1),
  },
  avatar: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
  },
}));

const Component = ({ callback }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { state } = useContext(AppContext);
  const [groups, setGroups] = useState([]);
  const [historyVisible, setHistoryVisible] = useState(false);
  const [histories, setHistories] = useState([]);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection(`/classrooms/${state.roomId}/assess`)
      .onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (doc.id !== '0000000000000') {
            items.push({
              id: doc.id,
              data,
              primaryText: { students: '學生互評', groups: '小組互評' }[
                data.method
              ],
              secondaryText: moment(data.timestamp).format(
                'YYYY-MM-DD HH:mm:ss',
              ),
            });
          }
        });

        items.sort((x, y) => (x.timestamp > y.timestamp ? 1 : -1));
        setHistories(
          items.map((item, index) => ({ ...item, avatar: index + 1 })),
        );
      });

    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/groups/0000000000000`)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const { results } = doc.data();

          if (results) {
            setGroups(
              results.map((group) => {
                return state.students.filter((student) =>
                  group.split(',').includes(`${student.ID}`),
                );
              }),
            );
          }
        }
      });

    return () => unsubscribe();
    // eslint-disable-next-line
  }, []);

  const start = (method) => {
    const timestamp = Date.now();
    const event = { timestamp, method, status: 'close' };

    if (method === 'students') {
      event.data = state.students.map((item) => `${item.SeatNo} ${item.Name}`);
    } else if (method === 'groups') {
      event.data = groups.map((item, index) => `第 ${index + 1} 組`);
    }

    callback();

    firebase.firestore().doc(`/classrooms/${state.roomId}/events/main`).set({
      key: 'classroom.assess.open',
      timestamp: Date.now(),
    });

    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/assess/0000000000000`)
      .set(event);
    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/assess/${timestamp}`)
      .set(event);
  };

  const renderHistoryModal = () =>
    historyVisible && (
      <div
        className={clsx(classes.section, classes.modal)}
        style={{ padding: 0 }}
      >
        <List>
          {histories.map((item, index) => (
            <React.Fragment key={item.data.timestamp}>
              <HistoryItem item={item} callback={callback} />
              {index !== histories.length - 1 && (
                <Divider variant="fullWidth" />
              )}
            </React.Fragment>
          ))}
        </List>
      </div>
    );

  const renderContent = () => (
    <div className={classes.section}>
      <div className={clsx(classes.content, classes.centerContent)}>
        <Button onClick={() => start('students')}>
          <Typography variant="h4" color="primary">
            學生互評
          </Typography>
        </Button>
        <div style={{ height: theme.spacing(4) }} />
        <Button disabled={groups.length === 0} onClick={() => start('groups')}>
          <Typography
            variant="h4"
            color={groups.length === 0 ? 'textSecondary' : 'primary'}
          >
            小組互評
          </Typography>
        </Button>
      </div>
      <div className={classes.bottom}>
        <BottomNavigation showLabels>
          <BottomNavigationAction
            label="歷史記錄"
            icon={<ListIcon />}
            onClick={() => setHistoryVisible(true)}
          />
        </BottomNavigation>
      </div>
    </div>
  );

  return (
    <Slide in direction="up" timeout={1000}>
      <div className={classes.main}>
        {renderHistoryModal()}
        {renderContent()}
      </div>
    </Slide>
  );
};

export default Component;

const HistoryItem = ({ item, callback }) => {
  const classes = useStyles();
  const { state } = useContext(AppContext);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  return (
    <>
      <Menu
        onClose={() => setMenuAnchorEl(null)}
        open={Boolean(menuAnchorEl)}
        anchorEl={menuAnchorEl}
      >
        <MenuItem
          onClick={() => {
            const timestamp = moment().format('MMDDHHmm');
            const content = [
              `${item.data.method === 'students' ? '學生' : '小組'},分數`,
            ];
            const data = item.data.data.map((i) => 0);
            Object.keys(item.data).forEach((key) => {
              if (key.includes('student')) {
                item.data[key].content.forEach((c, i) => {
                  data[i] += c;
                });
              }
            });
            item.data.data.forEach((item, index) => {
              content.push(`${item},${data[index]}`);
            });

            var element = document.createElement('a');
            element.setAttribute(
              'href',
              `data:text/plain;charset=utf-8,\uFEFF${encodeURIComponent(
                content.join('\r\n'),
              )}`,
            );
            element.setAttribute(
              'download',
              `${state.target.classroom.name}_學生互評_${timestamp}.csv`,
            );

            element.style.display = 'none';
            (document.body || {}).appendChild(element);

            element.click();

            (document.body || {}).removeChild(element);

            setMenuAnchorEl(null);
          }}
        >
          下載
        </MenuItem>
        <MenuItem
          onClick={() => {
            firebase
              .firestore()
              .doc(`/classrooms/${state.roomId}/assess/${item.id}`)
              .delete();
            setMenuAnchorEl(null);
          }}
        >
          刪除
        </MenuItem>
      </Menu>
      <ListItem
        button
        onClick={() => {
          firebase
            .firestore()
            .doc(`/classrooms/${state.roomId}/events/main`)
            .set({
              key: 'classroom.assess.open',
              timestamp: Date.now(),
            });
          firebase
            .firestore()
            .doc(`/classrooms/${state.roomId}/assess/0000000000000`)
            .set(item.data);
          callback();
        }}
      >
        <ListItemAvatar>
          <Avatar className={classes.avatar}>{item.avatar}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={item.primaryText}
          secondary={item.secondaryText}
        />
        <ListItemSecondaryAction>
          <IconButton edge="end" onClick={(e) => setMenuAnchorEl(e.target)}>
            <MoreIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
};
