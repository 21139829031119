import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import {
  AlarmOn as AlarmOnIcon,
  AlarmOff as AlarmOffIcon,
  HighlightOffOutlined as CloseIcon,
} from '@material-ui/icons';

import Chart from 'chart.js';
import 'chartjs-plugin-colorschemes/src/plugins/plugin.colorschemes';
import { Tableau10 } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.tableau';

import * as firebase from 'firebase/app';
import 'firebase/firestore';

import { AppContext } from '../../../App';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgba(0,0,0,.75)',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  caption: {
    color: '#f1f1f1',
    marginLeft: theme.spacing(2),
  },
  statusIcon: {
    color: '#f1f1f1',
    fontSize: '3rem',
  },
  closeIcon: {
    color: '#f1f1f1',
    fontSize: '3rem',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3),
  },
}));

const Component = ({ callback }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { state } = useContext(AppContext);
  const [status, setStatus] = useState('close');

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/assess/0000000000000`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const item = doc.data();
          const data = item.data.map((item) => 0);
          Object.keys(item).forEach((key) => {
            if (key.includes('student')) {
              item[key].content.forEach((c, i) => {
                data[i] += c;
              });
            }
          });

          const labels = item.data.map(
            (item, idx) => `${item} : ${data[idx]} 分`,
          );

          var ctx = (document.getElementById('chart') || {}).getContext('2d');

          new Chart(ctx, {
            type: 'doughnut',
            data: {
              labels,
              datasets: [{ data }],
            },
            options: {
              responsive: true,
              legend: {
                position: 'left',
                labels: { fontSize: 14, fontColor: '#f1f1f1' },
              },
              layout: {
                padding: {
                  left: theme.spacing(2),
                  right: theme.spacing(2),
                  top: theme.spacing(2),
                  bottom: theme.spacing(2),
                },
              },
              tooltips: { enabled: false },
              plugins: {
                colorschemes: {
                  scheme: Tableau10,
                },
              },
            },
          });

          setStatus(item.status);
        }
      });

    return () => {
      unsubscribe();
      firebase
        .firestore()
        .doc(`/classrooms/${state.roomId}/assess/0000000000000`)
        .update({ status: 'close' });
    };
    // eslint-disable-next-line
  }, []);

  const toggleStatus = () => {
    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/assess/0000000000000`)
      .update({
        status: status === 'open' ? 'close' : 'open',
      });
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h4" className={classes.caption}>
          學生互評
        </Typography>
        <div style={{ flex: 1 }} />
        <Tooltip title={status === 'open' ? '關閉互評' : '開啟互評'}>
          <IconButton onClick={toggleStatus}>
            {status === 'open' ? (
              <AlarmOnIcon className={classes.statusIcon} />
            ) : (
              <AlarmOffIcon className={classes.statusIcon} />
            )}
          </IconButton>
        </Tooltip>
        <Tooltip title="關閉">
          <IconButton onClick={callback}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </Tooltip>
      </div>
      <div className={classes.content}>
        <canvas id="chart"></canvas>
      </div>
    </div>
  );
};

export default Component;
