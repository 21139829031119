import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Fade,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@material-ui/icons';
import moment from 'moment';

import * as firebase from 'firebase/app';
import 'firebase/firestore';

import { AppContext } from '../../App';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#eb5e60',
    position: 'relative',
    width: theme.spacing(56),
    height: theme.spacing(55),
  },
  moreButton: {
    position: 'absolute',
    bottom: 8,
    left: 8,
    zIndex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  moreNormalDot: {
    color: 'rgba(255, 255, 255, .38)',
    margin: theme.spacing(0, 0.25),
  },
  moreCurrentDot: {
    color: 'rgba(255, 255, 255, 1)',
    margin: theme.spacing(0, 0.25),
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  titleText: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
    color: '#f1f1f1',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-all',
    display: '-webkit-box',
  },
  contentText: {
    flex: 1,
    fontSize: '1.2rem',
    color: '#f1f1f1',
    lineHeight: '1.8rem',
    WebkitLineClamp: 10,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-all',
    display: '-webkit-box',
    whiteSpace: 'pre-line',
    marginTop: theme.spacing(2),
  },
  officeText: {
    fontSize: '1.2rem',
    color: '#f1f1f1',
    textAlign: 'right',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-all',
    display: '-webkit-box',
    marginTop: theme.spacing(2),
  },
  timeText: {
    fontSize: '1.2rem',
    color: '#f1f1f1',
    textAlign: 'right',
    marginTop: theme.spacing(0.5),
  },
  messageContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  messageText: {
    fontSize: '2rem',
    color: '#f1f1f1',
  },
}));

const Component = () => {
  const classes = useStyles();
  const { state } = useContext(AppContext);
  const [current, setCurrent] = useState(-1);
  const [origins, setOrigins] = useState([]);
  const [items, setItems] = useState([]);
  const [timestamp, setTimestamp] = useState(15);
  const [open, setOpen] = useState(false);
  const [viewIndex, setViewIndex] = useState(0);

  useEffect(() => {
    let interval;
    const unsubscribe = firebase
      .firestore()
      .collection(`/devices/${state.device.id}/broadcast`)
      .onSnapshot((querySnapshot) => {
        clearInterval(interval);

        const data = [];
        querySnapshot.forEach((doc) => {
          data.push({
            ...doc.data(),
            time: moment(new Date(parseInt(doc.id, 10))).format(
              'YYYY-MM-DD HH:mm',
            ),
            key: doc.id,
          });
        });
        data.sort((x, y) => (y.time > x.time ? 1 : -1));

        if (data.length > 0) {
          setOrigins([...data]);
          setItems([...data.slice(0, 5)]);
          setCurrent(0);

          let _timestamp = 15;
          interval = setInterval(() => {
            _timestamp -= 1;
            if (_timestamp === 0) {
              setCurrent((current) =>
                current + 1 < data.length ? current + 1 : 0,
              );
              _timestamp = 15;
            }

            setTimestamp(_timestamp);
          }, 1000);
        }
      });

    return () => {
      unsubscribe();
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  const previousItem = () => {
    const index = viewIndex - 1 >= 0 ? viewIndex - 1 : 0;
    setViewIndex(index);
  };

  const nextItem = () => {
    const index =
      viewIndex + 1 < origins.length ? viewIndex + 1 : origins.length - 1;
    setViewIndex(index);
  };

  const renderDialog = () =>
    open && (
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle style={{ wordBreak: 'break-all' }}>
          {origins[viewIndex].title}
        </DialogTitle>
        <DialogContent style={{ minWidth: 320 }}>
          <DialogContentText
            style={{ whiteSpace: 'pre-line', wordBreak: 'break-all' }}
          >
            {origins[viewIndex].content}
          </DialogContentText>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            style={{ wordBreak: 'break-all', marginTop: 15 }}
          >
            {origins[viewIndex].office}
          </Typography>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            style={{ wordBreak: 'break-all' }}
          >
            {origins[viewIndex].time}
          </Typography>
        </DialogContent>
        <DialogActions>
          <IconButton onClick={previousItem} color="primary">
            <ChevronLeftIcon />
          </IconButton>
          <div style={{ flex: 1 }} />
          <Typography variant="subtitle2" color="primary">
            {viewIndex + 1} / {origins.length}
          </Typography>
          <div style={{ flex: 1 }} />
          <IconButton onClick={nextItem} color="primary">
            <ChevronRightIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    );

  if (current === -1) {
    return (
      <Paper elevation={3} className={classes.root}>
        <div className={classes.messageContent}>
          <div className={classes.messageText}>還沒有任何公告耶!</div>
        </div>
      </Paper>
    );
  }

  return (
    <Paper elevation={3} className={classes.root}>
      {renderDialog()}
      <Tooltip title="顯示更多">
        <Button
          className={classes.moreButton}
          onClick={() => {
            setViewIndex(0);
            setOpen(true);
          }}
        >
          {items.map((item, index) => (
            <div
              key={`dot-${item.key}`}
              className={
                current === index
                  ? classes.moreCurrentDot
                  : classes.moreNormalDot
              }
            >
              ●
            </div>
          ))}
        </Button>
      </Tooltip>
      <Fade in={timestamp > 2} timeout={2000}>
        {items[current] ? (
          <div className={classes.content}>
            <div className={classes.titleText}>{items[current].title}</div>
            <div className={classes.contentText}>{items[current].content}</div>
            <div className={classes.officeText}>{items[current].office}</div>
            <div className={classes.timeText}>{items[current].time}</div>
          </div>
        ) : (
          <div className={classes.content} />
        )}
      </Fade>
    </Paper>
  );
};

export default Component;
