import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Menu,
  MenuItem,
  TextField,
} from '@material-ui/core';

import * as firebase from 'firebase/app';
import 'firebase/firestore';

import { withStore } from '../../context';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  content: {
    position: 'relative',
    overflow: 'auto',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    minWidth: theme.spacing(40),
  },
  items: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
}));

const Component = ({ classes, airboxSites, counties, target }) => {
  const styles = useStyles();
  const theme = useTheme();
  const [saving, setSaving] = useState(false);
  const [current, setCurrent] = useState(null);
  const [alert, setAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');

  useEffect(() => {
    if (target) {
      setCurrent(target);
    }
    // eslint-disable-next-line
  }, [target]);

  const save = () => {
    if (saving || !current) {
      return;
    }

    setSaving(true);

    firebase
      .firestore()
      .doc(`/devices/${target.deviceId}`)
      .update({
        classroomName: current.classroomName,
        class: current.class,
        airbox: current.airbox,
        county: current.county,
      })
      .then(() => setSaving(false))
      .catch((error) => {
        setSaving(false);
        setAlertMsg(`Error saving documents: ${error}`);
      });
  };

  return (
    <div className={styles.root}>
      <Dialog open={alert}>
        <DialogTitle>{alertMsg}</DialogTitle>
        <DialogActions>
          <div style={{ flex: 1 }} />
          <Button color="primary" onClick={() => setAlert(false)}>
            確定
          </Button>
        </DialogActions>
      </Dialog>
      {current && (
        <div className={styles.content}>
          <div className={styles.items}>
            <TextField
              label="綁定班級"
              fullWidth
              value={current.class.name || ''}
              margin="normal"
              variant="outlined"
              aria-owns={current.anchorClass ? 'class-menu' : undefined}
              aria-haspopup="true"
              onClick={(e) =>
                setCurrent({ ...current, anchorClass: e.currentTarget })
              }
            />
            <Menu
              id="class-menu"
              anchorEl={current.anchorClass}
              open={Boolean(current.anchorClass)}
              onClose={() => setCurrent({ ...current, anchorClass: null })}
            >
              {classes.map((item, index) => (
                <MenuItem
                  key={`class-${index}`}
                  onClick={() => {
                    setCurrent({
                      ...current,
                      class: item,
                      classroomName: current.classroomName || item.name,
                      anchorClass: null,
                    });
                  }}
                >
                  {item.name}
                </MenuItem>
              ))}
            </Menu>
            <div style={{ height: theme.spacing(1) }} />
            <TextField
              label="教室名稱"
              fullWidth
              value={current.classroomName}
              margin="normal"
              variant="outlined"
              onChange={(e) =>
                setCurrent({ ...current, classroomName: e.currentTarget.value })
              }
            />
            <div style={{ height: theme.spacing(1) }} />
            <TextField
              label="台灣環保署 PM2.5 測站"
              fullWidth
              value={current.airbox.caption || ''}
              margin="normal"
              variant="outlined"
              aria-owns={current.anchorAirbox ? 'class-menu' : undefined}
              aria-haspopup="true"
              onClick={(e) =>
                setCurrent({ ...current, anchorAirbox: e.currentTarget })
              }
            />
            <Menu
              id="airbox-menu"
              anchorEl={current.anchorAirbox}
              open={Boolean(current.anchorAirbox)}
              onClose={() => setCurrent({ ...current, anchorAirbox: null })}
            >
              {airboxSites.map((item, index) => (
                <MenuItem
                  key={`site-${index}`}
                  onClick={() => {
                    setCurrent({
                      ...current,
                      airbox: item,
                      anchorAirbox: null,
                    });
                  }}
                >
                  {item.caption}
                </MenuItem>
              ))}
            </Menu>
            <div style={{ height: theme.spacing(1) }} />
            <TextField
              label="中央氣象局天氣資訊縣市位置"
              fullWidth
              value={current.county || ''}
              margin="normal"
              variant="outlined"
              aria-owns={current.anchorCounty ? 'class-menu' : undefined}
              aria-haspopup="true"
              onClick={(e) =>
                setCurrent({ ...current, anchorCounty: e.currentTarget })
              }
            />
            <Menu
              id="county-menu"
              anchorEl={current.anchorCounty}
              open={Boolean(current.anchorCounty)}
              onClose={() => setCurrent({ ...current, anchorCounty: null })}
            >
              {counties.map((item, index) => (
                <MenuItem
                  key={`county-${index}`}
                  onClick={() => {
                    setCurrent({
                      ...current,
                      county: item,
                      anchorCounty: null,
                    });
                  }}
                >
                  {item}
                </MenuItem>
              ))}
            </Menu>
            <div style={{ height: theme.spacing(2) }} />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <div style={{ flex: 1 }} />
              <Button color="primary" variant="contained" onClick={save}>
                {saving ? '儲存中...' : '儲存'}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default withStore(Component);
