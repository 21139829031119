import React, { useContext, useEffect, useRef, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Button,
  Card,
  CardContent,
  CardActions,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  FormGroup,
  Grow,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  AlarmOn as AlarmOnIcon,
  AlarmOff as AlarmOffIcon,
  Description as DescriptionIcon,
  Equalizer as EqualizerIcon,
  HighlightOffOutlined as CloseIcon,
} from '@material-ui/icons';

import Chart from 'chart.js';
import 'chartjs-plugin-colorschemes/src/plugins/plugin.colorschemes';
import { Tableau10 } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.tableau';

import * as firebase from 'firebase/app';
import 'firebase/firestore';

import { AppContext } from '../../../App';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    backgroundColor: 'rgba(0,0,0,.75)',
  },
  header: {
    position: 'absolute',
    top: 8,
    right: 8,
    left: 8,
    zIndex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  caption: {
    color: '#f1f1f1',
    marginLeft: theme.spacing(2),
  },
  content: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    flex: 1,
    display: 'flex',
  },
  web: {
    flex: 1,
    width: '100vw',
    height: '100vh',
    border: 'none',
  },
  embed: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  statusIcon: {
    color: '#f1f1f1',
    fontSize: '3rem',
  },
  viewIcon: {
    color: '#f1f1f1',
    fontSize: '3rem',
  },
  closeIcon: {
    color: '#f1f1f1',
    fontSize: '3rem',
  },
}));

const Component = ({ callback }) => {
  const classes = useStyles();
  const theme = useTheme();
  const contentRef = useRef(null);
  const { state } = useContext(AppContext);
  const [player, setPlayer] = useState({});
  const [open, setOpen] = useState(false);
  const [unit, setUnit] = useState(null);
  const [status, setStatus] = useState('');
  const [view, setView] = useState('paper');

  useEffect(() => {
    const docRef = firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/oneknow`);

    const unsubscribe1 = docRef.onSnapshot((doc) => {
      if (doc.exists) {
        const { unit, command } = doc.data();

        if (command === 'init') {
          setUnit(unit);

          if (unit.unit_type === 'video') {
            const regYoutube = unit.content_url.match(
              /^.*?youtu(?:\.be|be\.com)\/(?:watch\?[^#]*v=|embed\/)?([a-z0-9_-]+)/i,
            );
            const regVimeo = unit.content_url.match(
              /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/,
            );
            const videoTag = Date.now();

            if (regYoutube) {
              (
                contentRef.current || {}
              ).innerHTML = `<div id="youtube-${videoTag}" style="width:100%;height:100%"></div>`;

              const target = new window.YT.Player(`youtube-${videoTag}`, {
                height: '100%',
                width: '100%',
                videoId: regYoutube[1],
                playerVars: {
                  rel: 0,
                  showinfo: 0,
                  enablejsapi: 1,
                  controls: 1,
                },
                events: {
                  onReady: () => {
                    docRef.update({ command: 'video_ready' });
                  },
                  onStateChange: (event) => {
                    if (event.data === 1) {
                      docRef.update({ command: 'video_playing' });
                    } else if (event.data === 2) {
                      docRef.update({ command: 'video_paused' });
                    } else if (event.data === 0) {
                      docRef.update({ command: 'video_paused' });
                    }
                  },
                },
              });

              setPlayer({
                play: () => target.playVideo(),
                pause: () => target.pauseVideo(),
                replay: () => target.seekTo(target.getCurrentTime() - 5),
                forward: () => target.seekTo(target.getCurrentTime() + 5),
                isPaused: () =>
                  new Promise((rs) => rs(target.getPlayerState() !== 1)),
              });
            } else if (regVimeo) {
              (
                contentRef.current || {}
              ).innerHTML = `<iframe id="vimeo-${videoTag}" src="https://player.vimeo.com/video/${regVimeo[5]}" style="width:100%;height:100%" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen allow="autoplay; encrypted-media"/>`;

              const target = new window.Vimeo.Player(
                document.querySelector(`#vimeo-${videoTag}`),
              );
              target.ready().then(() => {
                docRef.update({ command: 'video_ready' });
              });
              target.on('play', () => {
                docRef.update({ command: 'video_playing' });
              });
              target.on('pause', () => {
                docRef.update({ command: 'video_paused' });
              });
              target.on('ended', () => {
                docRef.update({ command: 'video_paused' });
              });

              setPlayer({
                play: () => target.play(),
                pause: () => target.pause(),
                replay: () =>
                  target.getCurrentTime().then((value) => {
                    target.setCurrentTime(value - 5);
                  }),
                forward: () =>
                  target.getCurrentTime().then((value) => {
                    target.setCurrentTime(value + 5);
                  }),
                isPaused: () =>
                  new Promise((rs) => {
                    target.getPaused().then((paused) => {
                      rs(paused);
                    });
                  }),
              });
            } else {
              (
                contentRef.current || {}
              ).innerHTML = `<video id="video-${videoTag}" src="${unit.content_url}" style="width:100%;height:100%;background:#000" controls preload="auto"><source src="${unit.content_url}" type="video/mp4" /></video>`;

              const target = document.querySelector('video') || {};
              target.onloadeddata = () => {
                docRef.update({ command: 'video_ready' });
              };
              target.onplaying = () => {
                docRef.update({ command: 'video_playing' });
              };
              target.onpause = () => {
                docRef.update({ command: 'video_paused' });
              };
              target.onended = () => {
                docRef.update({ command: 'video_paused' });
              };

              setPlayer({
                play: () => target.play(),
                pause: () => target.pause(),
                replay: () => {
                  target.currentTime -= 5;
                },
                forward: () => {
                  target.currentTime += 5;
                },
                isPaused: () => new Promise((rs) => rs(target.paused)),
              });
            }
          } else if (unit.unit_type === 'embed') {
          }
        }
      }
    });

    const unsubscribe2 = firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/oneknow`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const data = doc.data();

          setStatus(data.status);
          setView(data.view || 'paper');
        }
      });

    return () => {
      unsubscribe1();
      unsubscribe2();

      firebase
        .firestore()
        .doc(`/classrooms/${state.roomId}/events/oneknow`)
        .update({
          status: 'close',
          timestamp: Date.now(),
        });

      if (state.container.type === 'native') {
        state.communication.postMessage(
          JSON.stringify({
            action: 'PAUSE_VIDEO',
          }),
        );
      }
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/oneknow`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const event = doc.data();
          if (event.command === 'toggle') {
            player.isPaused().then((paused) => {
              if (paused) {
                const target = player.play();
                setTimeout(() => {
                  if (target !== undefined) {
                    if (target.catch) {
                      target.catch(() => setOpen(true));
                    } else if (target.getPlayerState) {
                      if (target.getPlayerState() === -1) {
                        setOpen(true);
                      }
                    }
                  } else {
                    setOpen(true);
                  }
                }, 1000);
              } else {
                player.pause();
              }
            });
          } else if (event.command === 'play') {
            const target = player.play();
            setTimeout(() => {
              if (target !== undefined) {
                if (target.catch) {
                  target.catch(() => setOpen(true));
                } else if (target.getPlayerState) {
                  if (target.getPlayerState() === -1) {
                    setOpen(true);
                  }
                }
              } else {
                setOpen(true);
              }
            }, 1000);
          } else if (event.command === 'pause') {
            player.pause();
          } else if (event.command === 'replay') {
            player.replay();
          } else if (event.command === 'forward') {
            player.forward();
          }
        }
      });

    return () => unsubscribe();
    // eslint-disable-next-line
  }, [player]);

  const toggleStatus = () => {
    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/oneknow`)
      .update({
        status: status === 'open' ? 'close' : 'open',
      });
  };

  const toggleView = () => {
    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/oneknow`)
      .update({
        view: view === 'paper' ? 'stats' : 'paper',
      });
  };

  const renderDialog = () =>
    open && (
      <Dialog open={open}>
        <DialogTitle>無法自動播放影片嗎？</DialogTitle>
        <DialogContent>
          <DialogContentText>
            因 Chrome 政策修改(請參考：
            <a
              href="https://developers.google.com/web/updates/2017/09/autoplay-policy-changes"
              target="_blank"
              rel="noopener noreferrer"
            >
              Autoplay Policy Changes
            </a>
            )，麻煩請先點選影片播放按鈕，等影片開始播放後即可以使用 App
            遠端控制。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setOpen(false)}>
            關閉
          </Button>
        </DialogActions>
      </Dialog>
    );

  return (
    <div className={classes.root}>
      {renderDialog()}
      <div className={classes.header}>
        {unit && (
          <Typography variant="h4" className={classes.caption}>
            {unit.name}
          </Typography>
        )}
        <div style={{ flex: 1 }} />
        {unit && ['poll', 'qa'].includes(unit.unit_type) && (
          <>
            <Tooltip title={status === 'open' ? '開啟互動' : '關閉互動'}>
              <IconButton onClick={toggleStatus}>
                {status === 'open' ? (
                  <AlarmOnIcon className={classes.statusIcon} />
                ) : (
                  <AlarmOffIcon className={classes.statusIcon} />
                )}
              </IconButton>
            </Tooltip>
            <Tooltip title={view === 'paper' ? '單元內容' : '作答統計'}>
              <IconButton onClick={toggleView}>
                {view === 'paper' ? (
                  <DescriptionIcon className={classes.statusIcon} />
                ) : (
                  <EqualizerIcon className={classes.statusIcon} />
                )}
              </IconButton>
            </Tooltip>
          </>
        )}
        <Tooltip title="關閉">
          <IconButton onClick={callback}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </Tooltip>
      </div>
      {unit && (
        <div className={classes.content}>
          {unit.unit_type === 'video' && (
            <div ref={contentRef} style={{ flex: 1 }} />
          )}
          {unit.unit_type === 'web' && (
            <iframe
              title={unit.name}
              src={unit.content_url}
              className={classes.web}
            />
          )}
          {unit.unit_type === 'embed' && (
            <div className={classes.embed}>
              <Paper
                style={{ padding: theme.spacing(3) }}
                dangerouslySetInnerHTML={{ __html: unit.content }}
              ></Paper>
            </div>
          )}
          {unit.unit_type === 'poll' && (
            <Poll unit={unit} view={view} status={status} />
          )}
          {unit.unit_type === 'qa' && (
            <Question unit={unit} view={view} status={status} />
          )}
        </div>
      )}
    </div>
  );
};

export default Component;

const pollStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'auto',
  },
}));

const Poll = ({ unit, view }) => {
  const classes = pollStyles();
  const theme = useTheme();
  const { state } = useContext(AppContext);
  const [choose, setChoose] = useState(0);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/oneknow`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const item = doc.data();

          const data = unit.content.options.map((option) => 0);
          Object.keys(item).forEach((key) => {
            if (key.includes('student')) {
              unit.content.options.forEach((o, index) => {
                if ((item[key].content & (2 ** index)) === 2 ** index) {
                  data[index] += 1;
                }
              });
            }
          });

          const labels = Array.from({ length: item.number }).map(() => '');
          unit.content.options.forEach((option, idx) => {
            labels[idx] = `${option.item}：${data[idx]} 票`;
          });

          if (document.getElementById('chart')) {
            var ctx = (document.getElementById('chart') || {}).getContext('2d');

            new Chart(ctx, {
              type: 'doughnut',
              data: {
                labels,
                datasets: [{ data }],
              },
              options: {
                responsive: true,
                legend: {
                  position: 'left',
                  labels: { fontSize: 14, fontColor: '#f1f1f1' },
                },
                layout: {
                  padding: {
                    left: theme.spacing(2),
                    right: theme.spacing(2),
                    top: theme.spacing(2),
                    bottom: theme.spacing(2),
                  },
                },
                tooltips: { enabled: false },
                plugins: {
                  colorschemes: {
                    scheme: Tableau10,
                  },
                },
              },
            });
          }
        }
      });

    return () => unsubscribe();
    // eslint-disable-next-line
  }, []);

  const renderSingleType = () =>
    unit.content.type === 'single' && (
      <RadioGroup
        value={`${choose}`}
        onChange={(event) => setChoose(parseInt(event.target.value, 10))}
        style={{ display: 'flex', flexDirection: 'row' }}
      >
        {unit.content.options.map((option) => (
          <FormControlLabel
            key={`option-${option.value}`}
            control={<Radio color="secondary" />}
            label={`${option.item}`}
            value={`${option.value}`}
          />
        ))}
      </RadioGroup>
    );

  const renderMultiType = () =>
    unit.content.type !== 'single' && (
      <FormGroup style={{ flexDirection: 'row' }}>
        {unit.content.options.map((option) => (
          <FormControlLabel
            key={`option-${option.value}`}
            control={<Checkbox color="secondary" />}
            label={`${option.item}`}
            value={`${option.value}`}
          />
        ))}
      </FormGroup>
    );

  return (
    <Grow in timeout={1000}>
      <div className={classes.root}>
        {view === 'paper' && (
          <Card
            style={{
              maxWidth: 960,
              margin: theme.spacing(2),
              overflow: 'auto',
            }}
          >
            <CardContent
              dangerouslySetInnerHTML={{ __html: unit.content.content }}
            />
            <CardActions
              style={{
                border: '1px solid #ccc',
                borderRadius: theme.spacing(1),
                padding: theme.spacing(1, 2),
                margin: theme.spacing(2),
              }}
            >
              {renderSingleType()}
              {renderMultiType()}
            </CardActions>
          </Card>
        )}
        {view === 'stats' && <canvas id="chart"></canvas>}
      </div>
    </Grow>
  );
};

const questStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'auto',
  },
  statsContent: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'center',
    maxWidth: 960,
    overflow: 'auto',
  },
  statsItem: {
    maxWidth: theme.spacing(40),
    minWidth: theme.spacing(30),
    margin: theme.spacing(1),
  },
  icon: {
    fontSize: 60,
    color: '#f1f1f1',
  },
}));

const Question = ({ unit, view }) => {
  const classes = questStyles();
  const theme = useTheme();
  const { state } = useContext(AppContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/oneknow`)
      .onSnapshot((doc) => {
        const item = doc.data();
        const data = [];

        Object.keys(item).forEach((key) => {
          if (key.includes('student')) {
            data.push({
              name: item[key].name,
              content: item[key].content,
            });
          }
        });

        setData(data);
      });

    return () => unsubscribe();
    // eslint-disable-next-line
  }, []);

  return (
    <Grow in timeout={1000}>
      <div className={classes.root}>
        {view === 'paper' && (
          <Card
            style={{
              maxWidth: 960,
              margin: theme.spacing(2),
              overflow: 'auto',
            }}
          >
            <CardContent dangerouslySetInnerHTML={{ __html: unit.content }} />
          </Card>
        )}
        {view === 'stats' && (
          <div className={classes.statsContent}>
            {data.map(
              (item) =>
                item.content && (
                  <div
                    key={`result-${item.title}`}
                    className={classes.statsItem}
                  >
                    <Paper style={{ padding: theme.spacing(2) }}>
                      <Typography variant="h5" color="primary" gutterBottom>
                        {item.name}
                      </Typography>
                      <div
                        style={{
                          wordBreak: 'break-all',
                          whiteSpace: 'pre-wrap',
                        }}
                        dangerouslySetInnerHTML={{
                          __html: item.content,
                        }}
                      />
                    </Paper>
                  </div>
                ),
            )}
          </div>
        )}
      </div>
    </Grow>
  );
};
