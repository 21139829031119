import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  CircularProgress,
  Divider,
  Fade,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Slide,
  Typography,
} from '@material-ui/core';

import * as firebase from 'firebase/app';
import 'firebase/firestore';

import { AppContext } from '../../../App';

const useStyles = makeStyles((theme) => ({
  loading: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  message: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: theme.spacing(3),
  },
  main: {
    flex: 1,
    overflow: 'auto',
    borderRadius: 0,
    backgroundColor: '#fff',
  },
  logo: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    borderRadius: theme.spacing(3),
    marginRight: theme.spacing(2),
  },
  icon: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
    marginRight: theme.spacing(2),
  },
}));

const SERVICE_TASKS =
  'https://m.1know.net/services/group/_GID_/tasks?accessToken=_ACCESS_TOKEN_';
const SERVICE_TASK_UNITS =
  'https://m.1know.net/services/group/task/_UQID_/units?accessToken=_ACCESS_TOKEN_';
const SERVICE_COURSES =
  'https://m.1know.net/services/course?accessToken=_ACCESS_TOKEN_';
const SERVICE_COURSE_UNITS =
  'https://m.1know.net/services/course/_UQID_/units?accessToken=_ACCESS_TOKEN_';
const SERVICE_UNIT_INFO =
  'https://m.1know.net/services/group/task/unit/_UQID_?accessToken=_ACCESS_TOKEN_';

const Component = ({ callback }) => {
  const classes = useStyles();
  const { state } = useContext(AppContext);
  const [type, setType] = useState('task');
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (state.target.type === '1know') {
      fetchTasks();
    } else {
      fetchCourses();
    }
    // eslint-disable-next-line
  }, []);

  const fetchTasks = () => {
    setType('task');

    fetch(
      SERVICE_TASKS.replace(/_GID_/gi, state.target.classroom.gid).replace(
        /_ACCESS_TOKEN_/gi,
        state.accessToken,
      ),
    )
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          const items = []
            .concat(response || [])
            .sort((x, y) =>
              x.name.toUpperCase() > y.name.toUpperCase() ? 1 : -1,
            )
            .map((item, index) => {
              const temp = item;
              temp.key = `task-${index + 1}`;
              temp.label = temp.name.substr(0, 1);
              temp.type = 'task';
              return temp;
            });

          setData(items);
          setLoading(false);

          (document.querySelector('#oneknow-content') || {}).scrollTo(0, 0);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => setLoading(false));
  };

  const fetchTaskUnits = (task) => {
    setType('task-unit');

    fetch(
      SERVICE_TASK_UNITS.replace(/_UQID_/gi, task.uqid).replace(
        /_ACCESS_TOKEN_/gi,
        state.accessToken,
      ),
    )
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          let items = [].concat(response || []).map((item, index) => {
            const temp = { ...item };
            temp.key = `unit-${index + 1}`;
            temp.type = 'unit';
            return temp;
          });

          items = items
            .filter((item) =>
              ['video', 'web', 'embed', 'poll', 'qa'].includes(item.unit_type),
            )
            .map((item, index) => ({ ...item, label: index + 1 }));

          setData(items);
          setLoading(false);

          (document.querySelector('#oneknow-content') || {}).scrollTo(0, 0);
        }
      })
      .catch((error) => setLoading(false));
  };

  const fetchCourses = () => {
    setType('course');

    fetch(SERVICE_COURSES.replace(/_ACCESS_TOKEN_/gi, state.accessToken))
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          const items = []
            .concat(response || [])
            .sort((x, y) =>
              x.name.toUpperCase() > y.name.toUpperCase() ? 1 : -1,
            )
            .map((item, index) => {
              const temp = item;
              temp.key = `course-${index + 1}`;
              temp.label = temp.name.substr(0, 1);
              temp.type = 'course';
              return temp;
            });

          setData(items);
          setLoading(false);

          (document.querySelector('#oneknow-content') || {}).scrollTo(0, 0);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => setLoading(false));
  };

  const fetchCourseUnits = (course) => {
    setType('course-unit');

    fetch(
      SERVICE_COURSE_UNITS.replace(/_UQID_/gi, course.uqid).replace(
        /_ACCESS_TOKEN_/gi,
        state.accessToken,
      ),
    )
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          let items = [].concat(response || []).map((item, index) => {
            const temp = { ...item };
            temp.key = `unit-${index + 1}`;
            temp.type = 'unit';
            return temp;
          });

          items = items
            .filter((item) =>
              ['video', 'web', 'embed', 'poll', 'qa'].includes(item.unit_type),
            )
            .map((item, index) => ({ ...item, label: index + 1 }));

          setData(items);
          setLoading(false);

          (document.querySelector('#oneknow-content') || {}).scrollTo(0, 0);
        }
      })
      .catch((error) => setLoading(false));
  };

  const fetchUnit = (unit) => {
    fetch(
      SERVICE_UNIT_INFO.replace(/_UQID_/gi, unit.uqid).replace(
        /_ACCESS_TOKEN_/gi,
        state.accessToken,
      ),
    )
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          startUnit(response);
        }
      });
  };

  const startUnit = (unit) => {
    callback();

    firebase.firestore().doc(`/classrooms/${state.roomId}/events/main`).set({
      key: 'classroom.1know.open',
      timestamp: Date.now(),
    });
    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/oneknow`)
      .set({ unit, status: 'close', command: 'init', timestamp: Date.now() });
  };

  const navigate = (item) => {
    if (item.type === 'task') {
      setLoading(true);
      fetchTaskUnits(item);
    } else if (item.type === 'course') {
      setLoading(true);
      fetchCourseUnits(item);
    } else {
      fetchUnit(item);
    }
  };

  const getMessage = (type) => {
    switch (type) {
      case 'task': {
        return (
          <div className={classes.message}>
            <Typography variant="h4" gutterBottom color="secondary">
              哎呀!
            </Typography>
            <Typography variant="h5" gutterBottom>
              群組中找不到任何任務
            </Typography>
          </div>
        );
      }
      case 'task-unit': {
        return (
          <div className={classes.message}>
            <Typography variant="h4" gutterBottom color="secondary">
              哎呀!
            </Typography>
            <Typography variant="h5" gutterBottom>
              沒有指定的單元類型
            </Typography>
            <Typography variant="h6" gutterBottom>
              1. 影片
            </Typography>
            <Typography variant="h6" gutterBottom>
              2. 網頁
            </Typography>
            <Typography variant="h6" gutterBottom>
              3. 嵌入
            </Typography>
            <Typography variant="h6" gutterBottom>
              4. 投票
            </Typography>
            <Typography variant="h6" gutterBottom>
              5. 問答
            </Typography>
          </div>
        );
      }
      case 'course': {
        return (
          <div className={classes.message}>
            <Typography variant="h4" gutterBottom color="secondary">
              哎呀!
            </Typography>
            <Typography variant="h5" gutterBottom>
              請先上 1Know 平台訂閱課程
            </Typography>
          </div>
        );
      }
      case 'course-unit': {
        return (
          <div className={classes.message}>
            <Typography variant="h4" gutterBottom color="secondary">
              哎呀!
            </Typography>
            <Typography variant="h5" gutterBottom>
              沒有指定的單元類型
            </Typography>
            <Typography variant="h6" gutterBottom>
              1. 影片
            </Typography>
            <Typography variant="h6" gutterBottom>
              2. 網頁
            </Typography>
            <Typography variant="h6" gutterBottom>
              3. 嵌入
            </Typography>
            <Typography variant="h6" gutterBottom>
              4. 投票
            </Typography>
            <Typography variant="h6" gutterBottom>
              5. 問答
            </Typography>
          </div>
        );
      }
      default: {
        return <React.Fragment />;
      }
    }
  };

  return (
    <Fade in timeout={1000}>
      {loading ? (
        <div className={classes.loading}>
          <CircularProgress color="primary" />
        </div>
      ) : data.length === 0 ? (
        getMessage(type)
      ) : (
        <div className={classes.main} id="oneknow-content">
          <Slide in direction="left" timeout={1000}>
            <List>
              {data.map((item, index) => (
                <div key={`item-${item.uqid}`}>
                  <ListItem button onClick={() => navigate(item)}>
                    <ListItemAvatar>
                      {item.logo ? (
                        <Avatar
                          src={String(item.logo)}
                          className={classes.logo}
                          imgProps={{
                            style: { backgroundColor: 'transparent' },
                          }}
                        />
                      ) : (
                        <Avatar className={classes.icon}>{item.label}</Avatar>
                      )}
                    </ListItemAvatar>
                    <ListItemText primary={item.name} />
                  </ListItem>
                  {index !== data.length - 1 && <Divider variant="middle" />}
                </div>
              ))}
            </List>
          </Slide>
        </div>
      )}
    </Fade>
  );
};

export default Component;
