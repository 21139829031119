import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Slide, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Component = () => {
  const classes = useStyles();

  return (
    <Slide in direction="up" timeout={1000}>
      <div className={classes.main}>
        <Typography variant="h5" color="secondary">
          此功能請在手機上操作
        </Typography>
      </div>
    </Slide>
  );
};

export default Component;
