import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Avatar,
  Card,
  CardActionArea,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Paper,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core';

import { AppContext } from '../../../App';

const useStyles = makeStyles((theme) => ({
  cards: {
    flex: 1,
    overflow: 'auto',
    padding: theme.spacing(4),
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  caption: {
    fontSize: '1.6rem',
    color: '#f1f1f1',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: theme.spacing(2, 0, 3),
  },
  card: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    margin: theme.spacing(1),
  },
  image: {
    width: theme.spacing(16),
    height: theme.spacing(16),
    margin: theme.spacing(1),
  },
  title: {
    fontSize: '1rem',
    color: '#f1f1f1',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    textAlign: 'center',
    margin: theme.spacing(1, 0),
    width: theme.spacing(17.5),
  },
  lists: {
    flex: 1,
    overflow: 'auto',
    borderRadius: 0,
  },
  icon: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    borderRadius: 'initial',
    marginRight: theme.spacing(2),
  },
  loading: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));

const Component = () => {
  const classes = useStyles();
  const theme = useTheme();
  const xsMediaQuery = useMediaQuery(theme.breakpoints.down('xs'));
  const { state, dispatch } = useContext(AppContext);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (!state.config) return;
    setCategories(state.config);
    // eslint-disable-next-line
  }, [state.config]);

  return categories.length === 0 ? (
    <div className={classes.loading}>
      <CircularProgress style={{ color: '#f1f1f1' }} />
    </div>
  ) : xsMediaQuery ? (
    <Paper className={classes.lists}>
      {categories.map((category, sdx) => (
        <List
          key={`list-${sdx}`}
          component="nav"
          subheader={
            <ListSubheader disableSticky={true}>{category.title}</ListSubheader>
          }
        >
          {category.items.map((item, idx) => (
            <div key={`item-${sdx}-${idx}`}>
              <ListItem
                button
                onClick={() => {
                  dispatch({
                    type: 'CHOOSE_APPS_ITEM',
                    payload: { ...item, timestamp: Date.now() },
                  });
                }}
              >
                <ListItemAvatar>
                  <Avatar
                    src={String(item.icon)}
                    className={classes.icon}
                    imgProps={{ style: { backgroundColor: 'transparent' } }}
                  />
                </ListItemAvatar>
                <ListItemText primary={item.title} />
              </ListItem>
              {idx !== category.items.length - 1 && (
                <Divider variant="middle" />
              )}
            </div>
          ))}
        </List>
      ))}
    </Paper>
  ) : (
    <div className={classes.cards}>
      {categories.map((category) => (
        <div key={`category-${category.title}`}>
          <div className={classes.caption}>{category.title}</div>
          <div className={classes.row}>
            {category.items.map((item) => (
              <Tooltip key={item.key} title={item.title}>
                <Card className={classes.card}>
                  <CardActionArea
                    onClick={() =>
                      dispatch({
                        type: 'CHOOSE_APPS_ITEM',
                        payload: { ...item, timestamp: Date.now() },
                      })
                    }
                  >
                    <img
                      src={String(item.icon)}
                      className={classes.image}
                      alt={item.title}
                    />
                    <div className={classes.title}>{item.title}</div>
                  </CardActionArea>
                </Card>
              </Tooltip>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Component;
