import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Chip, Fade, Paper, Typography } from '@material-ui/core';
import { AccountCircleOutlined as AccountIcon } from '@material-ui/icons';
import QRCode from 'qrcode.react';

import { AppContext } from '../../App';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    width: theme.spacing(25),
    height: theme.spacing(36),
    padding: theme.spacing(2),
  },
  qrcode: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const Component = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { state, dispatch } = useContext(AppContext);
  const [codeValue, setCodeValue] = useState(
    Math.random().toString(10).substr(2, 6),
  );
  const [timestamp, setTimestamp] = useState(300);

  useEffect(() => {
    dispatch({ type: 'SET_CODE_VALUE', payload: codeValue });

    let _timestamp = 300;
    const interval = setInterval(() => {
      _timestamp -= 1;
      if (_timestamp === 0) {
        _timestamp = 300;

        const codeValue = Math.random().toString(10).substr(2, 6);

        setCodeValue(codeValue);
        dispatch({ type: 'SET_CODE_VALUE', payload: codeValue });
      }

      setTimestamp(_timestamp);
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  return (
    <Paper elevation={3} className={classes.root}>
      <Fade in={timestamp > 2} timeout={2000}>
        <div className={classes.qrcode}>
          <QRCode
            value={`https://1campus.net/c/R/${codeValue}`}
            size={200}
            fgColor="#454545"
            bgColor="transparent"
          />
          <div style={{ height: theme.spacing(1) }} />
          <Typography variant="h5" style={{ color: '#454545' }}>
            {codeValue}
          </Typography>
        </div>
      </Fade>
      {state.container.type === 'chrome' && (
        <React.Fragment>
          <div style={{ flex: 1 }} />
          <Chip
            label="使用 1Campus 帳號登入"
            icon={<AccountIcon style={{ color: '#454545' }} />}
            variant="outlined"
            style={{
              color: '#454545',
              borderColor: '#454545',
            }}
            onClick={() => {
              const clientId = '140632d783e5fcc4810c0b08d103ec9b';
              const scope = [
                'User.Mail',
                'User.BasicInfo',
                'User.Application',
                '*:1campus.mobile.v2.teacher',
                '*:1campus.mobile.v2.dominator',
                'http://1know.net/personal',
              ].join();
              const url = `https://auth.ischool.com.tw/oauth/authorize.php?client_id=${clientId}&response_type=token&redirect_uri=${window.location.origin}&scope=${scope}&state=1campus`;

              window.location.replace(url);
            }}
          />
        </React.Fragment>
      )}
    </Paper>
  );
};

export default Component;
