const Background = require('./background.png');
const Banner = require('./banner.png');
const Clock = require('./clock.png');
const LightLogo = require('./light_logo.svg');
const DarkLogo = require('./dark_logo.svg');
const Photo = require('./photo.png');
const Schedule = require('./schedule.png');

const Process = require('./process.mp3');
const Finish = require('./finish.mp3');
const Step = require('./step.mp3');

const Audio = {
  Process,
  Finish,
  Step,
};

const Monster01 = require('./monster-01.png');
const Monster02 = require('./monster-02.png');
const Monster03 = require('./monster-03.png');
const Monster04 = require('./monster-04.png');
const Monster05 = require('./monster-05.png');
const Monster06 = require('./monster-06.png');
const Monster07 = require('./monster-07.png');
const Monster08 = require('./monster-08.png');
const Monster09 = require('./monster-09.png');
const Monster10 = require('./monster-10.png');

const Monster = [
  Monster01,
  Monster02,
  Monster03,
  Monster04,
  Monster05,
  Monster06,
  Monster07,
  Monster08,
  Monster09,
  Monster10,
];

const Student01 = require('./student-01.png');
const Student02 = require('./student-02.png');

const Student = {
  Student01,
  Student02,
};

export {
  Background,
  Banner,
  Clock,
  LightLogo,
  DarkLogo,
  Photo,
  Schedule,
  Audio,
  Monster,
  Student,
};
