import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grow } from '@material-ui/core';

import Bulletin from './bulletin';
import Clock from './clock';
import Code from './code';
import Photo from './photo';
import Schedule from './schedule';
import Weather from './weather';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(2),
    maxWidth: 1280 - theme.spacing(4),
    minWidth: 1280 - theme.spacing(4),
    maxHeight: 800 - theme.spacing(4),
    minHeight: 800 - theme.spacing(4),
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const Component = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [scale, setScale] = useState(
    Math.min(window.innerWidth / 1280, window.innerHeight / 800),
  );

  useEffect(() => {
    window.onresize = () => {
      setScale(Math.min(window.innerWidth / 1280, window.innerHeight / 800));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Grow in timeout={1000}>
      <div className={classes.root}>
        <div
          className={classes.content}
          style={{ transform: `scale(${scale})` }}
        >
          <div className={classes.column}>
            <Clock />
            <div style={{ height: theme.spacing(1) }} />
            <Weather />
          </div>
          <div style={{ width: theme.spacing(1) }} />
          <div className={classes.column}>
            <div className={classes.row}>
              <Photo />
              <div style={{ width: theme.spacing(1) }} />
              <Code />
            </div>
            <div style={{ height: theme.spacing(1) }} />
            <div className={classes.row}>
              <Bulletin />
              <div style={{ width: theme.spacing(1) }} />
              <Schedule />
            </div>
          </div>
        </div>
      </div>
    </Grow>
  );
};

export default Component;
