import React from 'react';

const { Provider, Consumer } = React.createContext({});

const withStore = (Component) => (props) => (
  <Consumer>{(value) => <Component {...value} {...props} />}</Consumer>
);

export { Provider, withStore };

export const Tts = {
  speak: (text) => {
    if (!window.speechSynthesis) {
      return;
    }

    window.speechSynthesis.cancel();

    const tts = new SpeechSynthesisUtterance();
    tts.lang = 'zh-TW';
    tts.text = text;
    window.speechSynthesis.speak(tts);
  },
  stop: () => {
    if (!window.speechSynthesis) {
      return;
    }

    window.speechSynthesis.cancel();
  },
  exists: !!window.speechSynthesis,
};
