import React, { useContext, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Grow,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import {
  HighlightOffOutlined as CloseIcon,
  PauseCircleOutline as PauseIcon,
  PlayCircleOutline as StartIcon,
  Restore as RestoreIcon,
} from '@material-ui/icons';
import moment from 'moment';

import * as firebase from 'firebase/app';
import 'firebase/firestore';

import * as Assets from '../../../assets';
import { AppContext } from '../../../App';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgba(0,0,0,.67)',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  caption: {
    color: '#f1f1f1',
    marginLeft: theme.spacing(2),
  },
  closeIcon: {
    color: '#f1f1f1',
    fontSize: '3rem',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f5f5f5',
    borderRadius: theme.spacing(3),
    padding: theme.spacing(3),
    minWidth: theme.spacing(90),
  },
  playingNumberText: {
    color: '#212121',
    fontSize: '16rem',
    margin: theme.spacing(0, 2),
  },
  finishNumberText: {
    color: '#f1f1f1',
    fontSize: '16rem',
    margin: theme.spacing(0, 2),
  },
  labelBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(5),
  },
  playingIcon: {
    color: '#00796b',
    fontSize: '4rem',
  },
  finishIcon: {
    color: '#f1f1f1',
    fontSize: '4rem',
  },
  animation1: {
    animationName: 'timer_process',
    animationDuration: '1s',
    transformOrigin: '50% 50%',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
  },
  animation2: {
    animationName: 'timer_finish',
    animationDuration: '1s',
    transformOrigin: '50% 50%',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    backgroundColor: 'rgba(255, 0, 0, 0.85)',
  },
}));

const Component = ({ callback }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery('(min-width:768px)');
  const audioProcess = useRef(null);
  const audioFinish = useRef(null);
  const { state } = useContext(AppContext);
  const [method, setMethod] = useState('倒數');
  const [currentNumber, setCurrentNumber] = useState(0);
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    let _startup = false;
    let _playing = false;
    let _timer = null;
    let _method = '';
    let _origin = 0;
    let _number = 0;

    function play() {
      if (_playing) {
        return;
      }

      _playing = true;
      setPlaying(true);

      _timer = setInterval(() => {
        if (_method === '正數') {
          _number += 1;
        } else {
          _number -= 1;
        }

        if (_number === 6) {
          state.tts.speak('時間剩下5秒了');
        } else if (_number <= 5 && _number > 0) {
          (audioProcess.current || {}).play();
        } else if (_number === 0) {
          clearInterval(_timer);

          (audioProcess.current || {}).pause();
          (audioFinish.current || {}).play();

          state.tts.speak('時間到了');

          setTimeout(() => {
            if (audioFinish && audioFinish.current) {
              audioFinish.current.pause();

              _number = _origin;
              setCurrentNumber(_origin);

              _playing = false;
              setPlaying(false);
            }
          }, 2000);
        }

        setCurrentNumber(_number);
      }, 1000);
    }

    const unsubscribe = firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/timer`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const { command, method: aMethod, number: aNumber } = doc.data();

          if (command === 'start') {
            if (!_startup) {
              _startup = true;
              _method = aMethod;
              _origin = parseInt(aNumber, 10);
              _number = parseInt(aNumber, 10);

              setMethod(_method);
              setCurrentNumber(_number);
            }

            play();
          } else if (command === 'pause') {
            clearInterval(_timer);
            state.tts.stop();

            _playing = false;
            setPlaying(false);
            (audioProcess.current || {}).pause();
            (audioFinish.current || {}).pause();
          } else if (command === 'set') {
            clearInterval(_timer);
            state.tts.stop();

            _playing = false;
            setPlaying(false);
            (audioProcess.current || {}).pause();
            (audioFinish.current || {}).pause();

            _origin = parseInt(aNumber, 10);
            _number = parseInt(aNumber, 10);
            setCurrentNumber(_number);
          }
        }
      });

    return () => {
      unsubscribe();
      state.tts.stop();
      clearInterval(_timer);

      state.communication.postMessage(
        JSON.stringify({
          action: 'PAUSE_VIDEO',
        }),
      );
    };
    // eslint-disable-next-line
  }, []);

  const start = () => {
    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/timer`)
      .update({
        command: 'start',
        timestamp: Date.now(),
      });
  };

  const pause = () => {
    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/timer`)
      .update({
        command: 'pause',
        timestamp: Date.now(),
      });
  };

  const restore = () => {
    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/timer`)
      .update({
        command: 'set',
        timestamp: Date.now(),
      });
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h4" className={classes.caption}>
          {method}計時
        </Typography>
        <div style={{ flex: 1 }} />
        <Tooltip title="關閉">
          <IconButton onClick={callback}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </Tooltip>
      </div>
      <Grow in timeout={1000}>
        <div className={classes.content}>
          <div className={classes.main}>
            <div
              className={clsx(
                classes.card,
                method === '倒數'
                  ? {
                      [classes.animation1]: currentNumber <= 5 && playing,
                      [classes.animation2]: currentNumber === 0 && playing,
                    }
                  : {},
              )}
              style={{
                zoom: matches
                  ? 1
                  : Math.min(window.innerWidth / 800, window.innerHeight / 500),
              }}
            >
              <div
                className={
                  method === '倒數' && currentNumber === 0 && playing
                    ? classes.finishNumberText
                    : classes.playingNumberText
                }
              >
                {moment(
                  moment.duration(currentNumber, 'seconds').asMilliseconds(),
                ).format('mm:ss')}
              </div>
              <div className={classes.labelBar}>
                <IconButton color="primary" onClick={pause}>
                  <PauseIcon
                    className={
                      method === '倒數' && currentNumber === 0 && playing
                        ? classes.finishIcon
                        : classes.playingIcon
                    }
                  />
                </IconButton>
                <div style={{ width: theme.spacing(3) }} />
                <IconButton color="primary" onClick={start}>
                  <StartIcon
                    className={
                      method === '倒數' && currentNumber === 0 && playing
                        ? classes.finishIcon
                        : classes.playingIcon
                    }
                  />
                </IconButton>
                <div style={{ width: theme.spacing(3) }} />
                <IconButton color="primary" onClick={restore}>
                  <RestoreIcon
                    className={
                      method === '倒數' && currentNumber === 0 && playing
                        ? classes.finishIcon
                        : classes.playingIcon
                    }
                  />
                </IconButton>
              </div>
            </div>
          </div>
        </div>
      </Grow>
      <audio loop ref={audioProcess}>
        <track kind="captions" />
        <source src={Assets.Audio.Process} type="audio/mpeg" />
      </audio>
      <audio ref={audioFinish}>
        <track kind="captions" />
        <source src={Assets.Audio.Finish} type="audio/mpeg" />
      </audio>
    </div>
  );
};

export default Component;
