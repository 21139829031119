import React, { useContext, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { HighlightOffOutlined as CloseIcon } from '@material-ui/icons';

import * as firebase from 'firebase/app';
import 'firebase/firestore';

import { AppContext } from '../../../App';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    backgroundColor: 'rgba(0,0,0,.67)',
  },
  header: {
    position: 'absolute',
    top: 8,
    right: 8,
    zIndex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  content: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    flex: 1,
    display: 'flex',
  },
  loading: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeIcon: {
    color: '#f1f1f1',
    fontSize: '3rem',
  },
}));

const Component = ({ callback }) => {
  const classes = useStyles();
  const videoRef = useRef(null);
  const { state } = useContext(AppContext);
  const [player, setPlayer] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const docRef = firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/video`);

    const unsubscribe = docRef.onSnapshot((doc) => {
      if (doc.exists) {
        const { command, url } = doc.data();
        if (command === 'set') {
          const youtube1 = url.match(
            /^.*?youtube\.com\/watch\?v=?([a-z0-9_-]+)/i,
          );
          const youtube2 = url.match(/^.*?youtu\.be\/([a-z0-9_-]+)/i);
          const vimeo = url.match(
            /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/,
          );
          const videoTag = Date.now();

          if (youtube1 || youtube2) {
            (
              videoRef.current || {}
            ).innerHTML = `<div id="youtube-${videoTag}" style="width:100%;height:100%"></div>`;

            const videoId = youtube1 ? youtube1[1] : youtube2[1];
            const target = new window.YT.Player(`youtube-${videoTag}`, {
              height: '100%',
              width: '100%',
              videoId,
              playerVars: {
                rel: 0,
                showinfo: 0,
                enablejsapi: 1,
                controls: 1,
              },
              events: {
                onReady: () => {
                  docRef.update({ command: 'video_ready' });
                },
                onStateChange: (event) => {
                  if (event.data === 1) {
                    docRef.update({ command: 'video_playing' });
                  } else if (event.data === 2) {
                    docRef.update({ command: 'video_paused' });
                  } else if (event.data === 0) {
                    docRef.update({ command: 'video_paused' });
                  }
                },
              },
            });

            setPlayer({
              play: () => target.playVideo(),
              pause: () => target.pauseVideo(),
              replay: () => target.seekTo(target.getCurrentTime() - 5),
              forward: () => target.seekTo(target.getCurrentTime() + 5),
              isPaused: () =>
                new Promise((rs) => rs(target.getPlayerState() !== 1)),
            });
          } else if (vimeo) {
            (
              videoRef.current || {}
            ).innerHTML = `<iframe id="vimeo-${videoTag}" src="https://player.vimeo.com/video/${vimeo[5]}" style="width:100%;height:100%" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen allow="autoplay; encrypted-media"/>`;

            const target = new window.Vimeo.Player(
              document.querySelector(`#vimeo-${videoTag}`),
            );
            target.ready().then(() => {
              docRef.update({ command: 'video_ready' });
            });
            target.on('play', () => {
              docRef.update({ command: 'video_playing' });
            });
            target.on('pause', () => {
              docRef.update({ command: 'video_paused' });
            });
            target.on('ended', () => {
              docRef.update({ command: 'video_paused' });
            });

            setPlayer({
              play: () => target.play(),
              pause: () => target.pause(),
              replay: () =>
                target.getCurrentTime().then((value) => {
                  target.setCurrentTime(value - 5);
                }),
              forward: () =>
                target.getCurrentTime().then((value) => {
                  target.setCurrentTime(value + 5);
                }),
              isPaused: () =>
                new Promise((rs) => {
                  target.getPaused().then((paused) => {
                    rs(paused);
                  });
                }),
            });
          }
        }
      }
    });

    return () => {
      unsubscribe();

      if (state.container.type === 'native') {
        state.communication.postMessage(
          JSON.stringify({
            action: 'PAUSE_VIDEO',
          }),
        );
      }
    };

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/video`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const { command } = doc.data();
          switch (command) {
            case 'toggle': {
              player.isPaused().then((paused) => {
                if (paused) {
                  const target = player.play();
                  setTimeout(() => {
                    if (target !== undefined) {
                      if (target.catch) {
                        target.catch(() => setOpen(true));
                      } else if (target.getPlayerState) {
                        if (target.getPlayerState() === -1) {
                          setOpen(true);
                        }
                      }
                    } else {
                      setOpen(true);
                    }
                  }, 1000);
                } else {
                  player.pause();
                }
              });
              break;
            }
            case 'play': {
              const target = player.play();
              setTimeout(() => {
                if (target !== undefined) {
                  if (target.catch) {
                    target.catch(() => setOpen(true));
                  } else if (target.getPlayerState) {
                    if (target.getPlayerState() === -1) {
                      setOpen(true);
                    }
                  }
                } else {
                  setOpen(true);
                }
              }, 1000);
              break;
            }
            case 'pause': {
              player.pause();
              break;
            }
            case 'replay': {
              player.replay();
              break;
            }
            case 'forward': {
              player.forward();
              break;
            }
            default: {
              /** empty */
            }
          }
        }
      });

    return () => unsubscribe();
    // eslint-disable-next-line
  }, [player]);

  const renderDialog = () =>
    open && (
      <Dialog open={open}>
        <DialogTitle>無法自動播放影片嗎？</DialogTitle>
        <DialogContent>
          <DialogContentText>
            因 Chrome 政策修改(請參考：
            <a
              href="https://developers.google.com/web/updates/2017/09/autoplay-policy-changes"
              target="_blank"
              rel="noopener noreferrer"
            >
              Autoplay Policy Changes
            </a>
            )，麻煩請先點選影片播放按鈕，等影片開始播放後即可以使用 App
            遠端控制。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setOpen(false)}>
            關閉
          </Button>
        </DialogActions>
      </Dialog>
    );

  return (
    <div className={classes.root}>
      {renderDialog()}
      <div className={classes.header}>
        <Tooltip title="關閉">
          <IconButton onClick={callback}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </Tooltip>
      </div>
      <div ref={videoRef} className={classes.content}>
        <div className={classes.loading}>
          <CircularProgress style={{ color: '#f1f1f1' }} />
        </div>
      </div>
    </div>
  );
};

export default Component;
