import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';

import * as firebase from 'firebase/app';
import 'firebase/firestore';

import { withStore } from '../context';

const useStyles = makeStyles((theme) => ({
  normalAvatar: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
  },
  selectedAvatar: {
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    backgroundColor: theme.palette.secondary.main,
  },
}));

const Component = ({ dsns, classes, ccItems, target, changeTarget }) => {
  const styles = useStyles();
  const [selected, setSelected] = useState(-1);
  const [items, setItems] = useState([]);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection('devices')
      .where('dsns', '==', dsns)
      .onSnapshot((querySnapshot) => {
        let data = [];
        querySnapshot.forEach((doc) => {
          const item = doc.data();
          data.push({
            ...item,
            key: doc.id,
            label: item.classroomName.substr(0, 1) || 'C',
          });
        });
        data.sort((x, y) => (x.deviceId > y.deviceId ? 1 : -1));

        if (classes.length === 0) {
          data = data.filter((device) => ccItems.includes(device.class.id));
        }

        setItems(data);
      });

    return () => unsubscribe();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!target) {
      setSelected(-1);
    }
    // eslint-disable-next-line
  }, [target]);

  return (
    <List>
      {items.map((item, index) => (
        <React.Fragment key={`item-${item.deviceId}`}>
          <ListItem
            button
            selected={index === selected}
            onClick={() => {
              setSelected(index);
              changeTarget(item);
            }}
          >
            <ListItemAvatar>
              <Avatar
                className={
                  index === selected
                    ? styles.selectedAvatar
                    : styles.normalAvatar
                }
              >
                {item.label}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={item.classroomName}
              secondary={item.deviceId}
            />
          </ListItem>
          {index < items.length - 1 && <Divider variant="fullWidth" />}
        </React.Fragment>
      ))}
    </List>
  );
};

export default withStore(Component);
