import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  BottomNavigation,
  BottomNavigationAction,
  Icon,
  Paper,
} from '@material-ui/core';

import { withStore } from '../../context';

import Bulletin from './bulletin';
import Device from './device';
import Photo from './photo';
import Schedule from './schedule';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: 1,
    display: 'flex',
    padding: theme.spacing(2),
  },
  navigation: {
    borderBottom: '1px solid rgba(0,0,0,.23)',
    borderTopLeftRadius: theme.spacing(0.5),
    borderTopRightRadius: theme.spacing(0.5),
  },
}));

const Component = ({ target }) => {
  const classes = useStyles();
  const [current, setCurrent] = useState(0);

  return (
    target && (
      <Paper className={classes.root}>
        <div className={classes.navigation}>
          <BottomNavigation
            showLabels
            value={current}
            style={{ backgroundColor: 'transparent' }}
            onChange={(event, value) => {
              setCurrent(value);
            }}
          >
            <BottomNavigationAction
              label="裝置管理"
              icon={<Icon>settings</Icon>}
            />
            <BottomNavigationAction
              label="照片管理"
              icon={<Icon>image</Icon>}
            />
            <BottomNavigationAction
              label="公告管理"
              icon={<Icon>list_alt</Icon>}
            />
            <BottomNavigationAction
              label="課表管理"
              icon={<Icon>access_time</Icon>}
            />
          </BottomNavigation>
        </div>
        <div className={classes.content}>
          {current === 0 && <Device />}
          {current === 1 && <Photo />}
          {current === 2 && <Bulletin />}
          {current === 3 && <Schedule />}
        </div>
      </Paper>
    )
  );
};

export default withStore(Component);
