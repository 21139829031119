import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  RssFeed as RssFeedIcon,
} from '@material-ui/icons';
import moment from 'moment';

import * as firebase from 'firebase/app';
import 'firebase/firestore';

import * as Assets from '../../assets';
import { AppContext } from '../../App';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    backgroundColor: '#23c0ed',
    backgroundImage: `url(${Assets.Clock})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'top',
    position: 'relative',
    width: theme.spacing(52),
    height: theme.spacing(55),
  },
  moreButton: {
    position: 'absolute',
    top: 8,
    right: 8,
  },
  main: {
    flex: 3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  timeText: {
    fontSize: '8rem',
    color: '#f1f1f1',
  },
  dateText: {
    fontSize: '1.6rem',
    color: '#f1f1f1',
    marginTop: theme.spacing(1),
  },
  footer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.17)',
  },
  classroomNameText: {
    fontSize: '3rem',
    color: '#f1f1f1',
    padding: theme.spacing(0, 2),
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-all',
    display: '-webkit-box',
  },
}));

const Component = () => {
  const classes = useStyles();
  const { state } = useContext(AppContext);
  const [timestamp, setTimestamp] = useState(moment());
  const [items, setItems] = useState([]);
  const [open, setOpen] = useState(false);
  const [viewIndex, setViewIndex] = useState(0);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection('broadcasts')
      .where('dsns', '==', state.device.dsns)
      .onSnapshot((querySnapshot) => {
        const data = [];
        querySnapshot.forEach((doc) => {
          const item = doc.data();
          if (item.targets.includes(state.device.id)) {
            data.push({
              ...item,
              docId: doc.id,
              time: moment(parseInt(doc.id, 10)).format('YYYY-MM-DD HH:mm'),
              url: (() => {
                if (item.type === 'video') {
                  const regYoutube = item.url.match(
                    /^.*?youtu(?:\.be|be\.com)\/(?:watch\?[^#]*v=|embed\/)?([a-z0-9_-]+)/i,
                  );
                  const regVimeo = item.url.match(
                    /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/,
                  );

                  let url = '';
                  if (regYoutube) {
                    url = `https://www.youtube.com/embed/${regYoutube[1]}`;
                  } else if (regVimeo) {
                    url = `https://player.vimeo.com/video/${regVimeo[5]}`;
                  }
                  return url;
                }
              })(),
            });
          }
        });
        data.sort((x, y) => (y.docId > x.docId ? 1 : -1));

        setItems(data);
      });

    const interval = setInterval(() => {
      setTimestamp(moment());
    }, 1000);

    return () => {
      unsubscribe();
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  const previousItem = () => {
    const index = viewIndex - 1 >= 0 ? viewIndex - 1 : 0;
    setViewIndex(index);
    updateReaded(index);
  };

  const nextItem = () => {
    const index =
      viewIndex + 1 < items.length ? viewIndex + 1 : items.length - 1;
    setViewIndex(index);
    updateReaded(index);
  };

  const updateReaded = (index) => {
    const data = items[index];
    if (data && data.docId && !data.readed) {
      data.readed = true;
      firebase
        .firestore()
        .doc(`/broadcasts/${data.docId}/devices/${state.device.id}`)
        .set({
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        });
    }
  };

  const renderDialog = () =>
    open && (
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle style={{ wordBreak: 'break-all' }}>
          {items[viewIndex].title}
        </DialogTitle>
        <DialogContent style={{ minWidth: 320 }}>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            gutterBottom
            style={{ wordBreak: 'break-all' }}
          >
            {items[viewIndex].time}
          </Typography>
          {items[viewIndex].type !== 'video' && (
            <DialogContentText
              style={{
                wordBreak: 'break-all',
                whiteSpace: 'pre-line',
                marginTop: 10,
              }}
            >
              {items[viewIndex].content}
            </DialogContentText>
          )}
          {items[viewIndex].type === 'video' && (
            <iframe
              title="Broadcast"
              src={items[viewIndex].url}
              style={{
                width: 552,
                height: 310,
                border: 'none',
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <IconButton onClick={previousItem} color="primary">
            <ChevronLeftIcon />
          </IconButton>
          <div style={{ flex: 1 }} />
          <Typography variant="subtitle2" color="primary">
            {viewIndex + 1} / {items.length}
          </Typography>
          <div style={{ flex: 1 }} />
          <IconButton onClick={nextItem} color="primary">
            <ChevronRightIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    );

  return (
    <Paper elevation={3} className={classes.root}>
      {renderDialog()}
      {items.length > 0 && (
        <Tooltip title="廣播訊息">
          <IconButton
            className={classes.moreButton}
            onClick={() => {
              setViewIndex(0);
              updateReaded(0);
              setOpen(true);
            }}
          >
            <RssFeedIcon style={{ color: '#f1f1f1', fontSize: '2rem' }} />
          </IconButton>
        </Tooltip>
      )}
      <div className={classes.main}>
        <div className={classes.timeText}>{timestamp.format('HH:mm')}</div>
        <div className={classes.dateText}>{`${timestamp.format(
          'YYYY 年 MM 月 DD 日',
        )} ${
          [
            '星期日',
            '星期一',
            '星期二',
            '星期三',
            '星期四',
            '星期五',
            '星期六',
          ][timestamp.day()]
        }`}</div>
      </div>
      <div className={classes.footer}>
        <div className={classes.classroomNameText}>
          {state.device.classroomName}
        </div>
      </div>
    </Paper>
  );
};

export default Component;
