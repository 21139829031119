import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Button,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogTitle,
  Drawer,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { ExitToApp as ExitIcon, Menu as MenuIcon } from '@material-ui/icons';

import moment from 'moment';

import * as Assets from '../../../assets';
import { withStore, Tts } from '../context';

import Broadcasts from './broadcasts';
import Modify from './modify';

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    overflow: 'auto',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    backgroundColor: '#e8e5db',
    backgroundImage: `url(${Assets.Background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'bottom',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  toolbar: theme.mixins.toolbar,
}));

const Component = () => {
  const classes = useStyles();
  const [open, toggleOpen] = useState(true);
  const [startupTime] = useState(Date.now());
  const [timestamp, setTimestamp] = useState('');
  const [confirm, setConfirm] = useState(false);
  const [alert, setAlert] = useState(false);

  useEffect(() => {
    const duration = 50 * 60 * 1000 - (Date.now() - startupTime);

    if (duration <= 0) {
      setAlert(true);
      return;
    }

    const timeout = setTimeout(() => {
      setTimestamp(moment(duration).format('mm:ss'));
    }, 1000);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [timestamp]);

  const renderConfirmDialog = () =>
    confirm && (
      <Dialog open={confirm}>
        <DialogTitle>確定離開管理功能？</DialogTitle>
        <DialogActions>
          <div style={{ flex: 1 }} />
          <Button
            color="secondary"
            onClick={() => {
              Tts.stop();
              setConfirm(false);
            }}
          >
            取消
          </Button>
          <Button
            color="primary"
            onClick={() => {
              Tts.stop();
              window.location.reload();
            }}
          >
            確定
          </Button>
        </DialogActions>
      </Dialog>
    );

  const renderAlertDialog = () =>
    alert && (
      <Dialog open={alert}>
        <DialogTitle>登入已過期，請重新登入!</DialogTitle>
        <DialogActions>
          <div style={{ flex: 1 }} />
          <Button color="primary" onClick={() => window.location.reload()}>
            確定
          </Button>
        </DialogActions>
      </Dialog>
    );

  return (
    <div className={classes.root}>
      {renderAlertDialog()}
      {renderConfirmDialog()}
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton color="inherit" onClick={() => toggleOpen(!open)}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            無聲廣播
          </Typography>
          <div style={{ flex: 1 }} />
          <Typography variant="h6" noWrap>
            {`登入有效時間： ${timestamp}`}
          </Typography>
          <Tooltip title="離開管理功能">
            <IconButton
              color="inherit"
              onClick={() => {
                Tts.speak('確定離開管理功能？');
                setConfirm(true);
              }}
            >
              <ExitIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.toolbar} />
        <Broadcasts />
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.toolbar} />
        <Modify />
      </main>
    </div>
  );
};

export default withStore(Component);
