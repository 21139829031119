import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Slide, Typography } from '@material-ui/core';

import GooglePicker from 'react-google-picker';
import * as firebase from 'firebase/app';
import 'firebase/firestore';

import { AppContext } from '../../../App';

const useStyles = makeStyles((theme) => ({
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  section: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
}));

const Component = ({ callback }) => {
  const classes = useStyles();
  const { state } = useContext(AppContext);

  const renderContent = () => (
    <div className={classes.section}>
      <div className={classes.content}>
        <GooglePicker
          clientId={
            '973016711227-aj1ou717grdn7f5l8saaboi224596541.apps.googleusercontent.com'
          }
          developerKey={'AIzaSyBTAR8KFcKwzx-JsdqWlTU7UmXG4NaThGA'}
          scope={['https://www.googleapis.com/auth/drive.readonly']}
          onChange={(data) => {
            if (data.action === 'picked') {
              const { access_token } = window.gapi.auth.getToken();
              firebase
                .firestore()
                .doc(`/classrooms/${state.roomId}/events/main`)
                .set({
                  key: 'classroom.gdrive.embedUrl',
                  url: `${data.docs[0].embedUrl}?access_token=${access_token}`,
                  timestamp: Date.now(),
                });
            }
          }}
          navHidden={true}
          authImmediate={false}
          viewId={'DOCS'}
        >
          <Button onClick={callback}>
            <Typography variant="h4" color="primary">
              選擇檔案
            </Typography>
          </Button>
        </GooglePicker>
      </div>
    </div>
  );

  return (
    <Slide in direction="up" timeout={1000}>
      <div className={classes.main}>{renderContent()}</div>
    </Slide>
  );
};

export default Component;
