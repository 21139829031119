import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer, Fab, IconButton, Typography } from '@material-ui/core';
import { Close as CloseIcon, Menu as MenuIcon } from '@material-ui/icons';
import { Router, Switch, Route } from 'react-router-dom';
import { createMemoryHistory } from 'history';

import { AppContext } from '../../../App';

import Menu from './menu';
import Voice from './voice';
import Video from './video';
import GDrive from './gdrive';
import OneKnow from './oneknow';
import Interactive from './interactive';
import Assess from './assess';
import Vote from './vote';
import Attendance from './attendance';
import Timer from './timer';
import Random from './random';
import Group from './group';

const history = createMemoryHistory();

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1, 0, 2),
    justifyContent: 'flex-end',
    ...theme.mixins.toolbar,
  },
  fab: {
    position: 'fixed',
    right: theme.spacing(1),
    bottom: theme.spacing(1),
    zIndex: theme.zIndex.drawer + 10,
  },
}));

const Component = () => {
  const classes = useStyles();
  const { state } = useContext(AppContext);
  const [status, setStatus] = useState({ drawer: false });

  useEffect(() => {
    if (!state.chooseAppsItem) return;

    const items = {
      'classroom.menu': {
        caption: '功能選單',
        path: '/menu',
      },
      'classroom.voice': {
        caption: '語音指令',
        path: '/voice',
      },
      'classroom.video': {
        caption: '影片播放',
        path: '/video',
      },
      'classroom.gdrive': {
        caption: 'Google Drive',
        path: '/gdrive',
      },
      'classroom.1know': {
        caption: '1Know',
        path: '/1know',
      },
      'classroom.interactive': {
        caption: '快問快答',
        path: '/interactive',
      },
      'classroom.assess': {
        caption: '學生互評',
        path: '/assess',
      },
      'classroom.vote': {
        caption: '學生互投',
        path: '/vote',
      },
      'classroom.attendance': {
        caption: '課堂出席',
        path: '/attendance',
      },
      'classroom.timer': {
        caption: '倒數計時',
        path: '/timer',
      },
      'classroom.random': {
        caption: '隨機抽人',
        path: '/random',
      },
      'classroom.group': {
        caption: '學生分組',
        path: '/group',
      },
    };

    const item = items[state.chooseAppsItem.key];

    if (item) {
      setStatus({
        drawer: true,
        caption: item.caption,
      });

      history.replace(item.path);
    } else {
      setStatus({ drawer: false });
    }
    // eslint-disable-next-line
  }, [state.chooseAppsItem]);

  return (
    <React.Fragment>
      <Drawer
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="right"
        open={status.drawer}
        onClose={() => setStatus({ drawer: false })}
      >
        <div className={classes.drawerHeader}>
          <Typography variant="h5" color="primary">
            {status.caption}
          </Typography>
          <div style={{ flex: 1 }} />
          <IconButton onClick={() => setStatus({ drawer: false })}>
            <CloseIcon />
          </IconButton>
        </div>
        <Router history={history}>
          <Switch>
            <Route path="/menu" children={<Menu />} />
            <Route
              path="/voice"
              children={<Voice callback={() => setStatus({ drawer: false })} />}
            />
            <Route
              path="/video"
              children={<Video callback={() => setStatus({ drawer: false })} />}
            />
            <Route
              path="/gdrive"
              children={
                <GDrive callback={() => setStatus({ drawer: false })} />
              }
            />
            <Route
              path="/1know"
              children={
                <OneKnow callback={() => setStatus({ drawer: false })} />
              }
            />
            <Route
              path="/interactive"
              children={
                <Interactive callback={() => setStatus({ drawer: false })} />
              }
            />
            <Route
              path="/assess"
              children={
                <Assess callback={() => setStatus({ drawer: false })} />
              }
            />
            <Route
              path="/vote"
              children={<Vote callback={() => setStatus({ drawer: false })} />}
            />
            <Route
              path="/attendance"
              children={
                <Attendance callback={() => setStatus({ drawer: false })} />
              }
            />
            <Route
              path="/timer"
              children={<Timer callback={() => setStatus({ drawer: false })} />}
            />
            <Route
              path="/random"
              children={
                <Random callback={() => setStatus({ drawer: false })} />
              }
            />
            <Route
              path="/group"
              children={<Group callback={() => setStatus({ drawer: false })} />}
            />
          </Switch>
        </Router>
      </Drawer>
      <Fab
        className={classes.fab}
        color="secondary"
        onClick={() => {
          setStatus({
            drawer: true,
            caption: '功能選單',
          });
          history.replace('/menu');
        }}
      >
        <MenuIcon />
      </Fab>
    </React.Fragment>
  );
};

export default Component;
