import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Button,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Fade,
  Grow,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { AccountCircleOutlined as AccountIcon } from '@material-ui/icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  fas,
  faChalkboard,
  faEnvelope,
  faPuzzlePiece,
} from '@fortawesome/free-solid-svg-icons';
import {
  fab,
  faAppStoreIos,
  faGooglePlay,
} from '@fortawesome/free-brands-svg-icons';
import { far, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import QRCode from 'qrcode.react';

import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import * as Assets from '../assets';
import { AppContext } from '../App';

library.add(
  fab,
  far,
  fas,
  faAppStoreIos,
  faChalkboard,
  faEnvelope,
  faGooglePlay,
  faPuzzlePiece,
  faTimesCircle,
);

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  signin: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'rgba(0,0,0,.37)',
    borderRadius: theme.spacing(3),
    backgroundColor: 'rgba(255,255,255,.67)',
  },
  codeValue: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  deviceId: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3, 3, 1),
    margin: theme.spacing(1),
    borderRadius: theme.spacing(1),
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  connectbar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: theme.spacing(0, 0, 1),
  },
}));

const Component = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { state, dispatch } = useContext(AppContext);
  const [codeValue, setCodeValue] = useState(
    Math.random().toString(10).substr(2, 6),
  );
  const [deviceId, setDeviceId] = useState('');
  const [view, setView] = useState('signin');
  const [alert, setAlert] = useState(false);
  const [timestamp, setTimestamp] = useState(300);

  useEffect(() => {
    dispatch({ type: 'SET_CODE_VALUE', payload: codeValue });

    let _timestamp = 300;
    const interval = setInterval(() => {
      _timestamp -= 1;
      if (_timestamp === 0) {
        _timestamp = 300;

        const codeValue = Math.random().toString(10).substr(2, 6);

        setCodeValue(codeValue);
        dispatch({ type: 'SET_CODE_VALUE', payload: codeValue });
      }

      setTimestamp(_timestamp);
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  const renderDeviceID = () => (
    <Paper elevation={3} className={classes.deviceId}>
      {!state.communication.connected ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: theme.spacing(1),
          }}
        >
          <Typography variant="h5">電子班牌功能需安裝</Typography>
          <div style={{ height: theme.spacing(2) }} />
          <Button
            href="https://chrome.google.com/webstore/detail/1campus-classroom/inkindidpjmakdjimcehellilgdfmldl?hl=zh-TW"
            target="_blank"
            rel="noopener noreferrer"
            color="primary"
          >
            <Typography variant="h5">Chrome 擴充功能</Typography>
          </Button>
          <div style={{ height: theme.spacing(4) }} />
          <IconButton onClick={() => setView('signin')}>
            <FontAwesomeIcon
              icon={['far', 'times-circle']}
              style={{ fontSize: 36 }}
            />
          </IconButton>
        </div>
      ) : (
        <React.Fragment>
          <Typography variant="h5" color="primary" gutterBottom>
            啟動電子班牌
          </Typography>
          <TextField
            autoFocus
            variant="outlined"
            placeholder="請輸入裝置代碼"
            style={{ width: theme.spacing(30) }}
            onChange={(e) => setDeviceId(e.target.value)}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: 16,
            }}
          >
            <div style={{ flex: 1 }} />
            <Button color="secondary" onClick={() => setView('signin')}>
              取消
            </Button>
            <Button
              color="primary"
              onClick={() => {
                if (!deviceId) {
                  return;
                }

                firebase
                  .firestore()
                  .doc(`/devices/${deviceId}`)
                  .get()
                  .then((doc) => {
                    if (doc.exists) {
                      state.communication.postMessage(
                        JSON.stringify({
                          action: 'SAVE_DEVICE_ID',
                          data: { deviceId },
                        }),
                      );

                      window.location.reload();
                    } else {
                      setAlert(true);
                    }
                  })
                  .catch(() => setAlert(true));
              }}
            >
              儲存
            </Button>
          </div>
        </React.Fragment>
      )}
    </Paper>
  );

  const renderSignin = () => (
    <div className={classes.signin}>
      <Typography variant="body1" style={{ color: 'rgba(0,0,0,.54)' }}>
        請使用 1Campus App
      </Typography>
      <Typography variant="body1" style={{ color: 'rgba(0,0,0,.54)' }}>
        掃描 QRCode 或輸入代碼登入
      </Typography>
      <div style={{ height: theme.spacing(2) }} />
      <Fade in={timestamp > 2} timeout={2000}>
        <div className={classes.codeValue}>
          <QRCode
            value={`https://1campus.net/c/R/${codeValue}`}
            size={220}
            fgColor="#454545"
            bgColor="transparent"
          />
          <div style={{ height: theme.spacing(2) }} />
          <Typography variant="h5" style={{ color: 'rgba(0,0,0,.54)' }}>
            {codeValue}
          </Typography>
        </div>
      </Fade>
      {state.container.type === 'chrome' && (
        <Chip
          label="使用 1Campus 帳號登入"
          icon={<AccountIcon style={{ color: 'rgba(0,0,0,.54)' }} />}
          variant="outlined"
          style={{
            color: 'rgba(0,0,0,.54)',
            borderColor: 'rgba(0,0,0,.54)',
            marginTop: theme.spacing(3),
          }}
          onClick={() => {
            const clientId = '140632d783e5fcc4810c0b08d103ec9b';
            const scope = [
              'User.Mail',
              'User.BasicInfo',
              'User.Application',
              '*:1campus.mobile.v2.teacher',
              '*:1campus.mobile.v2.dominator',
              'http://1know.net/personal',
            ].join();
            const url = `https://auth.ischool.com.tw/oauth/authorize.php?client_id=${clientId}&response_type=token&redirect_uri=${window.location.origin}&scope=${scope}&state=1campus`;

            window.location.replace(url);
          }}
        />
      )}
    </div>
  );

  return state ? (
    <Grow in timeout={1000}>
      <div className={classes.root}>
        <Dialog open={alert}>
          <DialogTitle>唯一碼錯誤</DialogTitle>
          <DialogContent>
            <DialogContentText>
              輸入的唯一碼不合法，請重新輸入!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div style={{ flex: 1 }} />
            <Button color="primary" onClick={() => setAlert(false)}>
              確定
            </Button>
          </DialogActions>
        </Dialog>
        <div className={classes.main}>
          <img
            src={Assets.LightLogo}
            style={{ width: theme.spacing(30), marginBottom: theme.spacing(2) }}
            alt="Logo"
          />
          <div className={classes.content}>
            {view === 'signin' && renderSignin()}
            {view === 'deviceId' && renderDeviceID()}
          </div>
        </div>
        <div className={classes.footer}>
          <div className={classes.connectbar}>
            <Tooltip title="啟動電子班牌">
              <IconButton
                style={{ color: 'rgba(0,0,0,.54)' }}
                onClick={() => setView('deviceId')}
              >
                <FontAwesomeIcon icon={['fas', 'chalkboard']} />
              </IconButton>
            </Tooltip>
            {state.container.type === 'chrome' && (
              <Tooltip title="客服信箱">
                <IconButton
                  style={{ color: 'rgba(0,0,0,.54)' }}
                  onClick={() =>
                    state.communication.postMessage(
                      JSON.stringify({
                        action: 'CREATE_NEW_PAGE',
                        data: {
                          key: 'mailto:support@ischool.com.tw',
                          url: 'mailto:support@ischool.com.tw',
                        },
                      }),
                    )
                  }
                >
                  <FontAwesomeIcon icon={['fas', 'envelope']} />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Facebook 粉絲團">
              <IconButton
                style={{ color: 'rgba(0,0,0,.54)' }}
                onClick={() =>
                  state.communication.postMessage(
                    JSON.stringify({
                      action: 'CREATE_NEW_PAGE',
                      data: {
                        key:
                          'https://www.facebook.com/Classroom-104202620941684',
                        url:
                          'https://www.facebook.com/Classroom-104202620941684',
                      },
                    }),
                  )
                }
              >
                <FontAwesomeIcon icon={['fab', 'facebook']} />
              </IconButton>
            </Tooltip>
            <Tooltip title="1Campus App Android">
              <IconButton
                style={{ color: 'rgba(0,0,0,.54)' }}
                onClick={() =>
                  state.communication.postMessage(
                    JSON.stringify({
                      action: 'CREATE_NEW_PAGE',
                      data: {
                        key:
                          'https://play.google.com/store/apps/details?id=tw.com.ischool.onecampusmobile',
                        url:
                          'https://play.google.com/store/apps/details?id=tw.com.ischool.onecampusmobile',
                      },
                    }),
                  )
                }
              >
                <FontAwesomeIcon icon={['fab', 'google-play']} />
              </IconButton>
            </Tooltip>
            <Tooltip title="1Campus App iOS">
              <IconButton
                style={{ color: 'rgba(0,0,0,.54)' }}
                onClick={() =>
                  state.communication.postMessage(
                    JSON.stringify({
                      action: 'CREATE_NEW_PAGE',
                      data: {
                        key: 'https://apps.apple.com/app/1campus/id1273657906',
                        url: 'https://apps.apple.com/app/1campus/id1273657906',
                      },
                    }),
                  )
                }
              >
                <FontAwesomeIcon icon={['fab', 'app-store-ios']} />
              </IconButton>
            </Tooltip>
            {state.container.type === 'chrome' && (
              <Tooltip title="Chrome Extension">
                <IconButton
                  style={{ color: 'rgba(0,0,0,.54)' }}
                  onClick={() =>
                    state.communication.postMessage(
                      JSON.stringify({
                        action: 'CREATE_NEW_PAGE',
                        data: {
                          key:
                            'https://chrome.google.com/webstore/detail/1campus-classroom/inkindidpjmakdjimcehellilgdfmldl?hl=zh-TW',
                          url:
                            'https://chrome.google.com/webstore/detail/1campus-classroom/inkindidpjmakdjimcehellilgdfmldl?hl=zh-TW',
                        },
                      }),
                    )
                  }
                >
                  <FontAwesomeIcon icon={['fas', 'puzzle-piece']} />
                </IconButton>
              </Tooltip>
            )}
          </div>
          <Typography variant="caption" style={{ color: 'rgba(0,0,0,.54)' }}>
            POWERED BY ISCHOOL INC. {state.version}
            {state.container.version ? `:${state.container.version}` : ''}
          </Typography>
        </div>
      </div>
    </Grow>
  ) : (
    <React.Fragment />
  );
};

export default Component;
