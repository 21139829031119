import React, { createContext, useEffect, useMemo, useReducer } from 'react';
import {
  createMuiTheme,
  makeStyles,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import { teal } from '@material-ui/core/colors';
import { useMediaQuery } from '@material-ui/core';
import { Route, Router, Switch } from 'react-router-dom';
import { createMemoryHistory } from 'history';

import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/performance';

import * as Assets from './assets';
import { Board, Broadcast, Chooser, Main, Manage, Teach } from './components';

const firebaseConfig = {
  apiKey: 'AIzaSyBTAR8KFcKwzx-JsdqWlTU7UmXG4NaThGA',
  authDomain: 'classroom-1campus.firebaseapp.com',
  databaseURL: 'https://classroom-1campus.firebaseio.com',
  projectId: 'classroom-1campus',
  storageBucket: 'classroom-1campus.appspot.com',
  messagingSenderId: '973016711227',
  appId: '1:973016711227:web:f616b25243cd6b92c21c1e',
  measurementId: 'G-8H3JZJ3YHW',
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.performance();

const AppContext = createContext({});
const history = createMemoryHistory();

const initialState = {
  version: '21M26W20',
  darkMode: false,
  communication: {
    connected: false,
    postMessage: (message) => {
      try {
        const { action, data } = JSON.parse(message);
        if (action === 'CREATE_NEW_PAGE') {
          window.open(data.url, '_blank');
        }
      } catch {
        // empty
      }
    },
  },
  container: { type: 'chrome', version: '' },
  tts: {
    speak: (text) => {
      if (!window.speechSynthesis) {
        return;
      }

      window.speechSynthesis.cancel();

      const tts = new SpeechSynthesisUtterance();
      tts.lang = 'zh-TW';
      tts.text = text;
      window.speechSynthesis.speak(tts);
    },
    stop: () => {
      if (!window.speechSynthesis) {
        return;
      }

      window.speechSynthesis.cancel();
    },
    exists: !!window.speechSynthesis,
  },
};

const stateReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'SET_COMMUNICATION': {
      return { ...state, communication: payload };
    }
    case 'SET_CONTAINER': {
      return { ...state, container: payload };
    }
    case 'SET_DEVICE': {
      return { ...state, device: payload };
    }
    case 'SET_TTS': {
      return { ...state, tts: payload };
    }
    case 'SET_CODE_VALUE': {
      return { ...state, codeValue: payload };
    }
    case 'SET_ACCESS_TOKEN': {
      return { ...state, accessToken: payload };
    }
    case 'SET_MY_INFO': {
      return { ...state, myInfo: payload };
    }
    case 'SET_ROOM_ID': {
      return { ...state, roomId: payload };
    }
    case 'SET_TARGET': {
      return { ...state, target: payload };
    }
    case 'SET_CONFIG': {
      return { ...state, config: payload };
    }
    case 'SET_STUDENTS': {
      return { ...state, students: payload };
    }
    case 'CHOOSE_APPS_ITEM': {
      return { ...state, chooseAppsItem: payload };
    }
    case 'TOGGLE_DARK_MODE': {
      return { ...state, darkMode: payload };
    }
    default:
      return state;
  }
};

const useStyles = makeStyles((theme) => ({
  banner: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: -1,
    backgroundColor: '#e8e5db',
    backgroundImage: `url(${Assets.Banner})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'top',
  },
  root: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    backgroundImage: `url(${Assets.Background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'bottom',
  },
}));

const Component = () => {
  const classes = useStyles();
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [state, dispatch] = useReducer(stateReducer, initialState);

  useMemo(
    () =>
      dispatch({
        type: 'TOGGLE_DARK_MODE',
        payload: prefersDarkMode,
      }),
    [prefersDarkMode],
  );

  let theme = useMemo(() => {
    return createMuiTheme({
      palette: {
        primary: { main: state.darkMode ? teal[400] : teal[700] },
        type: state.darkMode ? 'dark' : 'light',
      },
    });
    // eslint-disable-next-line
  }, [state.darkMode]);

  useEffect(() => {
    let tokenInfo = window.localStorage.getItem('tokenInfo');
    const roomId = window.localStorage.getItem('roomId');
    const accessToken = window.localStorage.getItem('accessToken');

    if (tokenInfo) {
      tokenInfo = JSON.parse(tokenInfo);
      history.replace('/chooser');
    } else if (roomId && accessToken) {
      dispatch({ type: 'SET_ROOM_ID', payload: roomId });
      dispatch({ type: 'SET_ACCESS_TOKEN', payload: accessToken });
      history.replace(`/teach/false`);
    } else {
      history.replace('/main');
    }

    const messageListener = (event) => {
      try {
        const { action, data } = JSON.parse(event.data);

        switch (action) {
          case 'START_COMMUNICATION': {
            dispatch({
              type: 'SET_COMMUNICATION',
              payload: {
                connected: true,
                postMessage: (value) => {
                  if (window.android) {
                    window.android.postMessage(value);
                  } else {
                    event.source.postMessage(value, event.origin);
                  }
                },
              },
            });

            dispatch({
              type: 'SET_CONTAINER',
              payload: {
                ...data,
                type: data.type === 'chrome' ? data.type : 'native',
              },
            });

            if (data.deviceId) {
              firebase
                .firestore()
                .doc(`/devices/${data.deviceId}`)
                .get()
                .then((doc) => {
                  if (doc.exists) {
                    const device = doc.data();

                    dispatch({
                      type: 'SET_DEVICE',
                      payload: { id: device.deviceId, ...device },
                    });

                    if (history.location.pathname === '/main') {
                      history.replace('/board');
                    }
                  }
                });
            }

            if (data.type === 'native') {
              dispatch({
                type: 'SET_TTS',
                payload: {
                  speak: (text) => {
                    (window.android || window).postMessage(
                      JSON.stringify({
                        action: 'TEXT_TO_SPEECH_START',
                        data: { text },
                      }),
                    );
                  },
                  stop: () => {
                    (window.android || window).postMessage(
                      JSON.stringify({
                        action: 'TEXT_TO_SPEECH_STOP',
                      }),
                    );
                  },
                },
              });
            }

            break;
          }
          default: {
            /** empty */
          }
        }
      } catch (error) {
        /** empty */
      }
    };

    window.addEventListener('message', messageListener);

    return () => {
      window.removeEventListener('message', messageListener);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!state.codeValue) return;

    const unsubscribe = firebase
      .firestore()
      .doc(`/pubsubs/${state.codeValue}`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const {
            event,
            roomId,
            dsns,
            accessToken,
            teacherName,
            deviceId,
          } = doc.data();

          doc.ref.delete();

          if (deviceId) {
            state.communication.postMessage(
              JSON.stringify({
                action: 'SAVE_DEVICE_ID',
                data: { deviceId },
              }),
            );

            window.location.reload();
          }

          switch (event) {
            case 'SIGN_IN': {
              state.communication.postMessage(
                JSON.stringify({
                  action: 'SIGNIN',
                  data: { roomId },
                }),
              );

              window.localStorage.setItem('roomId', roomId);
              dispatch({ type: 'SET_ROOM_ID', payload: roomId });

              window.localStorage.setItem('accessToken', accessToken);
              dispatch({ type: 'SET_ACCESS_TOKEN', payload: accessToken });

              history.replace(`/teach/true`);
              break;
            }
            case 'MANAGE_BROADCAST': {
              history.replace(
                `/manage/broadcast/${teacherName}/${dsns}/${accessToken}`,
              );
              break;
            }
            case 'MANAGE_DEVICE': {
              history.replace(
                `/manage/device/${teacherName}/${dsns}/${accessToken}`,
              );
              break;
            }
            default: {
              /** empty */
            }
          }
        }
      });

    return () => unsubscribe();
    // eslint-disable-next-line
  }, [state.communication, state.codeValue]);

  const chooserCallback = (data) => {
    window.localStorage.removeItem('tokenInfo');

    const { action, payload } = data;

    switch (action) {
      case 'SIGN_IN': {
        state.communication.postMessage(
          JSON.stringify({
            action: 'SIGNIN',
            data: { roomId: payload.roomId },
          }),
        );

        window.localStorage.setItem('roomId', payload.roomId);
        dispatch({ type: 'SET_ROOM_ID', payload: payload.roomId });

        window.localStorage.setItem('accessToken', payload.accessToken);
        dispatch({ type: 'SET_ACCESS_TOKEN', payload: payload.accessToken });

        history.replace(`/teach/true`);
        return;
      }
      case 'EXIT_CHOOSER': {
        history.replace(state.device ? '/board' : '/main');
        break;
      }
      case 'MANAGE_BROADCAST': {
        const { userName, dsns, accessToken } = payload;
        history.replace(`/manage/broadcast/${userName}/${dsns}/${accessToken}`);
        return;
      }
      case 'MANAGE_DEVICE': {
        const { userName, dsns, accessToken } = payload;
        history.replace(`/manage/device/${userName}/${dsns}/${accessToken}`);
        return;
      }
      default: {
        /** empty */
      }
    }
  };

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <MuiThemeProvider theme={theme}>
        <div className={classes.banner}></div>
        <div className={classes.root}>
          <Broadcast />
          <Router history={history}>
            <Switch>
              <Route
                path="/chooser"
                children={<Chooser callback={chooserCallback} />}
              />
              <Route path="/main" children={<Main />} />
              <Route path="/board" children={<Board />} />
              <Route path="/teach/:first" children={<Teach />} />
              <Route
                path="/manage/:type/:userName/:dsns/:accessToken"
                children={<Manage />}
              />
            </Switch>
          </Router>
        </div>
      </MuiThemeProvider>
    </AppContext.Provider>
  );
};

export default Component;
export { AppContext };
