import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Avatar,
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Snackbar,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  TextField,
  Typography,
} from '@material-ui/core';
import { Close as CloseIcon, Delete as DeleteIcon } from '@material-ui/icons';

import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import { AppContext } from '../App';

const Route = {
  Onecampus: [
    {
      key: 'School',
      label: '請選擇學校、1Know、建立的班級',
    },
  ],
  OnecampusSelfClass: [
    {
      key: 'School',
      label: '請選擇學校、1Know、建立的班級',
    },
    { key: 'Class', label: '請選擇班級' },
  ],
  OnecampusCategory: [
    {
      key: 'School',
      label: '請選擇學校、1Know、建立的班級',
    },
    { key: 'Category', label: '請選擇類別' },
  ],
  OnecampusCategoryClass: [
    {
      key: 'School',
      label: '請選擇學校、1Know、建立的班級',
    },
    {
      key: 'Category',
      label: '請選擇類別',
    },
    { key: 'Class', label: '請選擇班級' },
  ],
  DsnsCategory: [{ key: 'Category', label: '請選擇類別' }],
  DsnsCategoryClass: [
    {
      key: 'Category',
      label: '請選擇類別',
    },
    { key: 'Class', label: '請選擇班級' },
  ],
  DsnsClassroom: [
    { key: 'Category', label: '請選擇類別' },
    { key: 'Custom', label: '請選擇班級' },
  ],
  DsnsClassroomName: [
    { key: 'Category', label: '請選擇類別' },
    { key: 'Custom', label: '請選擇班級' },
    { key: 'ClassName', label: '請輸入班級名稱' },
  ],
  DsnsClassroomNameStudent: [
    { key: 'Category', label: '請選擇類別' },
    { key: 'Custom', label: '請選擇班級' },
    { key: 'ClassName', label: '請輸入班級名稱' },
    { key: 'ClassStudents', label: '請輸入班級人數' },
  ],
  OneknowOrgs: [
    {
      key: 'School',
      label: '請選擇學校、1Know、建立的班級',
    },
    { key: 'Org', label: '請選擇組織' },
  ],
  OneknowSelfGroup: [
    {
      key: 'School',
      label: '請選擇學校、1Know、建立的班級',
    },
    { key: 'Group', label: '請選擇群組' },
  ],
  OneknowOrgGroup: [
    {
      key: 'School',
      label: '請選擇學校、1Know、建立的班級',
    },
    { key: 'Org', label: '請選擇組織' },
    { key: 'Group', label: '請選擇群組' },
  ],
  Custom: [
    {
      key: 'School',
      label: '請選擇學校、1Know、建立的班級',
    },
    { key: 'Custom', label: '請選擇班級' },
  ],
  CustomName: [
    {
      key: 'School',
      label: '請選擇學校、1Know、建立的班級',
    },
    { key: 'Custom', label: '請選擇班級' },
    { key: 'ClassName', label: '請輸入班級名稱' },
  ],
  CustomStudent: [
    {
      key: 'School',
      label: '請選擇學校、1Know、建立的班級',
    },
    { key: 'Custom', label: '請選擇班級' },
    { key: 'ClassName', label: '請輸入班級名稱' },
    { key: 'ClassStudents', label: '請輸入班級人數' },
  ],
};

const useStyles = makeStyles((theme) => ({
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(3),
  },
  buttonbar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: theme.spacing(1, 0, 0),
  },
}));

const SERVICE_USER_TOKEN =
  'https://us-central1-classroom-1campus.cloudfunctions.net/getUserToken?accessToken=_ACCESS_TOKEN_';
const SERVICE_ME_INFO =
  'https://auth.ischool.com.tw/services/me.php?access_token=_ACCESS_TOKEN_';
const SERVICE_USER_INFO =
  'https://m.1know.net/services/account/user?accessToken=_ACCESS_TOKEN_';
const SERVICE_SESSION_ID =
  'https://dsns.ischool.com.tw/_DSNS_/1campus.mobile.v2.teacher/DS.Base.Connect?stt=PassportAccessToken&AccessToken=_ACCESS_TOKEN_&parser=spliter&content=RequestSessionID&rsptype=json';
const SERVICE_MY_INFO =
  'https://dsns.ischool.com.tw/_DSNS_/1campus.mobile.v2.teacher/classroom.GetMyInfo?rsptype=json&stt=PassportAccessToken&AccessToken=_ACCESS_TOKEN_&parser=spliter';
const SERVICE_ALL_CLASSES =
  'https://dsns.ischool.com.tw/_DSNS_/1campus.mobile.v2.dominator/GetAllClasses?rsptype=json&stt=PassportAccessToken&AccessToken=_ACCESS_TOKEN_&parser=spliter';
const SERVICE_CC_ITEMS =
  'https://dsns.ischool.com.tw/_DSNS_/1campus.mobile.v2.teacher/classroom.GetCCItems?rsptype=json&stt=PassportAccessToken&AccessToken=_ACCESS_TOKEN_&parser=spliter';
const SERVICE_GROUPS =
  'https://m.1know.net/services/group?accessToken=_ACCESS_TOKEN_';

const Component = ({ callback }) => {
  const styles = useStyles();
  const theme = useTheme();
  const { state } = useContext(AppContext);
  const [tokenInfo] = useState(
    JSON.parse(window.localStorage.getItem('tokenInfo')),
  );
  const [loading, setLoading] = useState(true);
  const [schools, setSchools] = useState([]);
  const [categories, setCategories] = useState([]);
  const [classesLoading, setClassesLoading] = useState(true);
  const [classes, setClasses] = useState([]);
  const [orgs, setOrgs] = useState([]);
  const [groupsLoading, setGroupsLoading] = useState(true);
  const [groups, setGroups] = useState([]);
  const [customsLoading, setCustomsLoading] = useState(true);
  const [customs, setCustoms] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState([]);
  const [inputClassName, setInputClassName] = useState('');
  const [inputStudentCount, setInputStudentCount] = useState(1);
  const [author, setAuthor] = useState({});
  const [removeItem, setRemoveItem] = useState(null);
  const [schoolInfo, setSchoolInfo] = useState({});

  useEffect(() => {
    fetch(SERVICE_USER_TOKEN.replace(/_ACCESS_TOKEN_/gi, tokenInfo.accessToken))
      .then((response) => response.json())
      .then((response) => {
        if (!response.token) {
          window.localStorage.clear();
          window.location.reload();
          return;
        }

        firebase
          .auth()
          .signInWithCustomToken(response.token)
          .then(() => {
            setAuthor(response);

            if (tokenInfo.dsns !== '1campus') {
              return getSchool({ ...tokenInfo, author: response });
            }

            Promise.all([
              new Promise((rs) => {
                fetch(
                  SERVICE_ME_INFO.replace(
                    /_ACCESS_TOKEN_/gi,
                    tokenInfo.accessToken,
                  ),
                )
                  .then((response) => response.json())
                  .then((response) => {
                    rs(response);
                  });
              }),
              new Promise((rs) => {
                fetch(
                  SERVICE_USER_INFO.replace(
                    /_ACCESS_TOKEN_/gi,
                    tokenInfo.accessToken,
                  ),
                )
                  .then((response) => response.json())
                  .then((response) => {
                    rs(response);
                  })
                  .catch((error) => rs({ error }));
              }),
            ]).then(([me, user]) => {
              setLoading(false);
              setSchools(
                [].concat(me.application || []).map((item) => ({
                  ...item,
                  avatar: item.name.substr(0, 1),
                  dsns: item.ap_name,
                  key: item.ap_name,
                })),
              );
              setSteps(Route.Onecampus);

              if (!user.error) {
                setOrgs([
                  {
                    key: '1know-self',
                    avatar: '個',
                    name: '1Know 個人',
                    dsns: '',
                  },
                  ...[].concat(user.orgs || []).map((item) => ({
                    key: `1know-${item.domain}`,
                    avatar: item.name.substr(0, 1),
                    ...item,
                    dsns: item.metadata
                      ? Object.keys(item.metadata.OneCampus)[0]
                      : 'none',
                  })),
                ]);
              }
            });
          });
      });
    // eslint-disable-next-line
  }, []);

  const getSchool = (school) => {
    setClassesLoading(true);

    fetch(
      SERVICE_SESSION_ID.replace(/_DSNS_/gi, school.dsns).replace(
        /_ACCESS_TOKEN_/gi,
        tokenInfo.accessToken,
      ),
    )
      .then((response) => response.json())
      .then((response) => {
        Promise.all([
          new Promise((rs) => {
            fetch(
              SERVICE_MY_INFO.replace(/_DSNS_/gi, school.dsns).replace(
                /_ACCESS_TOKEN_/gi,
                tokenInfo.accessToken,
              ),
            )
              .then((response) => response.json())
              .then((response) => {
                rs({
                  ...school,
                  teacher: (response.__Body || response).Teacher || {
                    Name: author.name,
                  },
                });
              });
          }),
          new Promise((rs) => {
            fetch(
              SERVICE_ALL_CLASSES.replace(/_DSNS_/gi, school.dsns).replace(
                /_ACCESS_TOKEN_/gi,
                tokenInfo.accessToken,
              ),
            )
              .then((response) => response.json())
              .then((response) => {
                const classes = []
                  .concat(
                    ((response.__Body || response).ClassList || {}).Class || [],
                  )
                  .filter((item) => item.Status === 1)
                  .map((item) => ({
                    UID: `${item.ClassID}`,
                    Name: `${item.ClassName}`,
                    Type: 'Class',
                    GradeYear: item.GradeYear,
                    TeacherName: `${item.TeacherName}`,
                    avatar: `${item.ClassName}`.substr(0, 1),
                    key: `allclasses-${item.ClassID}`,
                  }));

                return rs(classes);
              })
              .catch(() => {
                rs([]);
              });
          }),
          new Promise((rs) => {
            fetch(
              SERVICE_CC_ITEMS.replace(/_DSNS_/gi, school.dsns).replace(
                /_ACCESS_TOKEN_/gi,
                tokenInfo.accessToken,
              ),
            )
              .then((response) => response.json())
              .then((response) => {
                const items = []
                  .concat(
                    ((response.__Body || response).Items || {}).Item || [],
                  )
                  .map((item) => ({
                    ...item,
                    TeacherName: '',
                    avatar: `${item.Name}`.substr(0, 1),
                    key: `ccitems-${item.UID}`,
                  }));

                return rs(items);
              })
              .catch(() => {
                rs([]);
              });
          }),
        ]).then(([info, allItems, myItems]) => {
          setSchoolInfo(info);

          if (myItems.length > 0 && tokenInfo.uid) {
            const item = myItems.filter(
              (item) =>
                `${item.UID}` === tokenInfo.uid &&
                item.Type.toUpperCase() === tokenInfo.type.toUpperCase(),
            )[0];

            if (item) {
              const roomId = `${info.dsns}.${item.Type.toLowerCase()}.${
                item.UID
              }`;
              const target = {
                author: tokenInfo.author
                  ? tokenInfo.author.uuid
                  : '- author uuid -',
                dsns: info.dsns,
                code: Math.random().toString(36).substr(2, 6).toUpperCase(),
                teacher: { name: info.teacher.Name },
                classroom: {
                  gid: item.GroupId || '----',
                  uid: item.UID,
                  name: item.Name,
                  type: item.Type,
                },
                container: state.container,
                type: '1campus',
              };

              start(roomId, target);

              // firebase
              //   .firestore()
              //   .doc(`/classrooms/${roomId}`)
              //   .set({
              //     ...target,
              //     status: 'open',
              //     timestamp: firebase.firestore.FieldValue.serverTimestamp(),
              //   })
              //   .then(() =>
              //     callback({
              //       action: 'SIGN_IN',
              //       payload: {
              //         roomId,
              //         accessToken: tokenInfo.accessToken,
              //       },
              //     }),
              //   );

              return;
            }
          }

          setLoading(false);

          if (allItems.length > 0) {
            const sections = [];

            if (tokenInfo.dsns !== '1campus') {
              sections.push({
                title: '建立的班級',
                avatar: '建',
                key: `category-custom`,
              });
            }

            sections.push({
              title: '授課的班級',
              avatar: '授',
              data: myItems,
              key: `category-ccitems`,
            });

            allItems.forEach((item) => {
              const title = item.GradeYear ? `${item.GradeYear} 年級` : '其它';

              if (sections.length === 0) {
                sections.push({
                  title,
                  avatar: title.substr(0, 1),
                  data: [item],
                  key: `category-${title}`,
                });
              } else if (title === sections[sections.length - 1].title) {
                sections[sections.length - 1].data.push(item);
              } else {
                sections.push({
                  title,
                  avatar: title.substr(0, 1),
                  data: [item],
                  key: `category-${title}`,
                });
              }
            });

            if (tokenInfo.dsns !== '1campus') {
              setSteps(Route.DsnsCategory);
            } else {
              setSteps(Route.OnecampusCategory);
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
            setCategories(sections);
            return setClassesLoading(false);
          }

          if (tokenInfo.dsns !== '1campus') {
            setSteps(Route.DsnsCategory);
            setCategories([
              {
                title: '建立的班級',
                avatar: '建',
                key: `category-custom`,
              },
              {
                title: '授課的班級',
                avatar: '授',
                data: myItems,
                key: `category-ccitems`,
              },
            ]);
            setClassesLoading(false);
          } else {
            setSteps(Route.OnecampusSelfClass);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setCategories([]);
            setClasses(myItems);
            setClassesLoading(false);
          }
        });
      });
  };

  const get1Know = (org) => {
    if (!org) return;

    setGroupsLoading(true);

    fetch(
      SERVICE_GROUPS.replace(
        /_ACCESS_TOKEN_/gi,
        org.dsns
          ? `${tokenInfo.accessToken}&dsns=${org.dsns}`
          : tokenInfo.accessToken,
      ),
    )
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          const items = response
            .filter((item) => item.role !== 'member')
            .sort((x, y) => (x.name > y.name ? 1 : -1))
            .map((item) => ({
              ...item,
              key: `1know.group.${item.uqid}`,
              avatar: item.name.substr(0, 1),
            }));

          setGroups(items);
          return setGroupsLoading(false);
        }

        setGroups([]);
        setGroupsLoading(false);
      })
      .catch((error) => {
        setGroups([]);
        setGroupsLoading(false);
      });
  };

  const getCustoms = () => {
    setCustomsLoading(true);
    setSteps(Route.Custom);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

    firebase
      .firestore()
      .collection('classes')
      .where('author', '==', author.uuid)
      .get()
      .then((querySnapshot) => {
        const customs = [];
        querySnapshot.forEach((doc) => {
          customs.push({
            id: doc.id,
            ...doc.data(),
            key: `custom.${doc.id}`,
            avatar: doc.data().name.substr(0, 1),
          });
        });
        customs.sort((x, y) => (x.name > y.name ? 1 : -1));

        setCustoms(customs);
        setCustomsLoading(false);
      });
  };

  const getStep = (step) => {
    switch (step) {
      case 'School': {
        return (
          <React.Fragment>
            <List>
              {schools.map((item, index) => (
                <React.Fragment key={item.key}>
                  <ListItem button onClick={() => getSchool(item)}>
                    <ListItemAvatar>
                      <Avatar
                        style={{
                          color: theme.palette.getContrastText(
                            theme.palette.primary.main,
                          ),
                          backgroundColor: theme.palette.primary.main,
                        }}
                      >
                        {item.avatar}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={item.name} />
                  </ListItem>
                  {schools.length > 1 && index < schools.length - 1 && (
                    <Divider variant="inset" />
                  )}
                </React.Fragment>
              ))}
              {schools.length > 0 && <Divider variant="inset" />}
              <ListItem
                key="1know"
                button
                onClick={() => {
                  if (orgs.length > 1) {
                    setSteps(Route.OneknowOrgs);
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                  } else {
                    get1Know(orgs[0]);
                    setSteps(Route.OneknowSelfGroup);
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                  }
                }}
              >
                <ListItemAvatar>
                  <Avatar
                    style={{
                      color: theme.palette.getContrastText(
                        theme.palette.primary.main,
                      ),
                      backgroundColor: theme.palette.primary.main,
                    }}
                  >
                    1K
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="1Know" />
              </ListItem>
              <Divider variant="inset" />
              <ListItem key="customs" button onClick={() => getCustoms()}>
                <ListItemAvatar>
                  <Avatar
                    style={{
                      color: theme.palette.getContrastText(
                        theme.palette.primary.main,
                      ),
                      backgroundColor: theme.palette.primary.main,
                    }}
                  >
                    建
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="建立的班級" />
              </ListItem>
            </List>
          </React.Fragment>
        );
      }
      case 'Category': {
        return (
          <React.Fragment>
            {classesLoading ? (
              <CircularProgress
                color="primary"
                style={{ margin: theme.spacing(2) }}
              />
            ) : categories.length === 0 ? (
              <Typography variant="h6" color="secondary">
                尚無可以上課的班級
              </Typography>
            ) : (
              <List>
                {categories.map((item, index) => (
                  <React.Fragment key={item.key}>
                    <ListItem
                      button
                      onClick={() => {
                        if (item.key === 'category-custom') {
                          return getCustoms();
                        }

                        if (tokenInfo.dsns !== '1campus') {
                          setSteps(Route.DsnsCategoryClass);
                        } else {
                          setSteps(Route.OnecampusCategoryClass);
                        }

                        setActiveStep((prevActiveStep) => prevActiveStep + 1);
                        setClasses(item.data);
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar
                          style={{
                            color: theme.palette.getContrastText(
                              theme.palette.primary.main,
                            ),
                            backgroundColor: theme.palette.primary.main,
                          }}
                        >
                          {item.avatar}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={item.title} />
                    </ListItem>
                    {categories.length > 1 && index < categories.length - 1 && (
                      <Divider variant="inset" />
                    )}
                  </React.Fragment>
                ))}
              </List>
            )}
            <div className={styles.buttonbar}>
              {state.communication.connected && (
                <React.Fragment>
                  <Button
                    color="primary"
                    onClick={() =>
                      callback({
                        action: 'MANAGE_BROADCAST',
                        payload: {
                          userName: schoolInfo.teacher.name,
                          dsns: schoolInfo.dsns,
                          accessToken: tokenInfo.accessToken,
                        },
                      })
                    }
                  >
                    無聲廣播
                  </Button>
                  <Button
                    color="primary"
                    onClick={() =>
                      callback({
                        action: 'MANAGE_DEVICE',
                        payload: {
                          userName: schoolInfo.teacher.name,
                          dsns: schoolInfo.dsns,
                          accessToken: tokenInfo.accessToken,
                        },
                      })
                    }
                  >
                    裝置管理
                  </Button>
                </React.Fragment>
              )}
              <div style={{ flex: 1 }}></div>
              {tokenInfo.dsns === '1campus' && (
                <Button
                  color="primary"
                  onClick={() => {
                    setCategories([]);
                    setSteps(Route.Onecampus);
                    setActiveStep((prevActiveStep) => prevActiveStep - 1);
                  }}
                >
                  上一步
                </Button>
              )}
            </div>
          </React.Fragment>
        );
      }
      case 'Class': {
        return (
          <React.Fragment>
            {classes.length === 0 ? (
              <Typography variant="h6" color="secondary">
                尚無可以上課的班級
              </Typography>
            ) : (
              <List>
                {classes.map((item, index) => (
                  <React.Fragment key={item.key}>
                    <ListItem
                      button
                      onClick={() => {
                        const roomId = `${
                          schoolInfo.dsns
                        }.${item.Type.toLowerCase()}.${item.UID}`;
                        const target = {
                          author: author.uuid,
                          dsns: schoolInfo.dsns,
                          code: Math.random()
                            .toString(36)
                            .substr(2, 6)
                            .toUpperCase(),
                          teacher: { name: schoolInfo.teacher.Name },
                          classroom: {
                            gid: item.GroupId || '----',
                            uid: item.UID,
                            name: item.Name,
                            type: item.Type,
                          },
                          container: state.container,
                          type: '1campus',
                        };

                        start(roomId, target);

                        // firebase
                        //   .firestore()
                        //   .doc(`/classrooms/${roomId}`)
                        //   .set({
                        //     ...target,
                        //     status: 'open',
                        //     timestamp:
                        //       firebase.firestore.FieldValue.serverTimestamp(),
                        //   })
                        //   .then(() =>
                        //     callback({
                        //       action: 'SIGN_IN',
                        //       payload: {
                        //         roomId,
                        //         accessToken: tokenInfo.accessToken,
                        //       },
                        //     }),
                        //   );
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar
                          style={{
                            color: theme.palette.getContrastText(
                              theme.palette.primary.main,
                            ),
                            backgroundColor: theme.palette.primary.main,
                          }}
                        >
                          {item.avatar}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={item.Name}
                        secondary={item.TeacherName}
                      />
                    </ListItem>
                    {classes.length > 1 && index < classes.length - 1 && (
                      <Divider variant="inset" />
                    )}
                  </React.Fragment>
                ))}
              </List>
            )}
            <div className={styles.buttonbar}>
              <Button
                color="primary"
                onClick={() => {
                  if (categories.length === 0) {
                    setSteps(Route.Onecampus);
                  } else if (tokenInfo.dsns !== '1campus') {
                    setSteps(Route.DsnsCategory);
                  } else {
                    setSteps(Route.OnecampusCategory);
                  }

                  setActiveStep((prevActiveStep) => prevActiveStep - 1);
                }}
              >
                上一步
              </Button>
            </div>
          </React.Fragment>
        );
      }
      case 'Org': {
        return (
          <React.Fragment>
            <List>
              {orgs.map((item, index) => (
                <React.Fragment key={item.key}>
                  <ListItem
                    button
                    onClick={() => {
                      get1Know(item);
                      setSteps(Route.OneknowOrgGroup);
                      setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        style={{
                          color: theme.palette.getContrastText(
                            theme.palette.primary.main,
                          ),
                          backgroundColor: theme.palette.primary.main,
                        }}
                      >
                        {item.avatar}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={item.name} />
                  </ListItem>
                  {orgs.length > 1 && index < orgs.length - 1 && (
                    <Divider variant="inset" />
                  )}
                </React.Fragment>
              ))}
            </List>
            <div className={styles.buttonbar}>
              <Button
                color="primary"
                onClick={() => {
                  setSteps(Route.Onecampus);
                  setActiveStep((prevActiveStep) => prevActiveStep - 1);
                }}
              >
                上一步
              </Button>
            </div>
          </React.Fragment>
        );
      }
      case 'Group': {
        return (
          <React.Fragment>
            {groups.length === 0 ? (
              groupsLoading ? (
                <CircularProgress
                  color="primary"
                  style={{ margin: theme.spacing(2) }}
                />
              ) : (
                <React.Fragment>
                  <Typography variant="h6" color="secondary">
                    尚無可以上課的群組
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    請先上 1Know 平台建立內容!
                  </Typography>
                </React.Fragment>
              )
            ) : (
              <List>
                {groups.map((item, index) => (
                  <React.Fragment key={item.key}>
                    <ListItem
                      button
                      onClick={() => {
                        const roomId = `1know.group.${item.uqid}`;
                        const target = {
                          author: author.uuid,
                          dsns: '1know',
                          code: Math.random()
                            .toString(36)
                            .substr(2, 6)
                            .toUpperCase(),
                          teacher: {
                            name: author.name,
                          },
                          classroom: {
                            gid: item.uqid,
                            uid: item.uqid,
                            name: item.name,
                            logo: item.logo,
                          },
                          container: state.container,
                          type: '1know',
                        };

                        start(roomId, target);

                        // firebase
                        //   .firestore()
                        //   .doc(`/classrooms/${roomId}`)
                        //   .set({
                        //     ...target,
                        //     status: 'open',
                        //     timestamp:
                        //       firebase.firestore.FieldValue.serverTimestamp(),
                        //   })
                        //   .then(() =>
                        //     callback({
                        //       action: 'SIGN_IN',
                        //       payload: {
                        //         roomId,
                        //         accessToken: tokenInfo.accessToken,
                        //       },
                        //     }),
                        //   );
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar src={item.logo}></Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={item.name} />
                    </ListItem>
                    {groups.length > 1 && index < groups.length - 1 && (
                      <Divider variant="inset" />
                    )}
                  </React.Fragment>
                ))}
              </List>
            )}
            <div className={styles.buttonbar}>
              <Button
                color="primary"
                onClick={() => {
                  if (orgs.length > 1) {
                    setSteps(Route.OneknowOrgs);
                  } else {
                    setSteps(Route.Onecampus);
                  }

                  setActiveStep((prevActiveStep) => prevActiveStep - 1);
                }}
              >
                上一步
              </Button>
            </div>
          </React.Fragment>
        );
      }
      case 'Custom': {
        return (
          <React.Fragment>
            {customs.length === 0 ? (
              customsLoading ? (
                <CircularProgress
                  color="primary"
                  style={{ margin: theme.spacing(2) }}
                />
              ) : (
                <Typography variant="h6" color="secondary">
                  尚無建立的班級
                </Typography>
              )
            ) : (
              <List>
                {customs.map((item, index) => (
                  <React.Fragment key={item.key}>
                    <ListItem
                      button
                      onClick={() => {
                        const roomId = `custom.${item.id}`;
                        const target = {
                          author: author.uuid,
                          dsns: 'custom',
                          code: Math.random()
                            .toString(36)
                            .substr(2, 6)
                            .toUpperCase(),
                          teacher: {
                            name: author.name,
                          },
                          classroom: {
                            gid: '----',
                            uid: item.id,
                            name: item.name,
                            students: item.students,
                          },
                          container: state.container,
                          type: 'custom',
                        };

                        start(roomId, target);

                        // firebase
                        //   .firestore()
                        //   .doc(`/classrooms/${roomId}`)
                        //   .set({
                        //     ...target,
                        //     status: 'open',
                        //     timestamp:
                        //       firebase.firestore.FieldValue.serverTimestamp(),
                        //   })
                        //   .then(() =>
                        //     callback({
                        //       action: 'SIGN_IN',
                        //       payload: {
                        //         roomId,
                        //         accessToken: tokenInfo.accessToken,
                        //       },
                        //     }),
                        //   );
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar
                          style={{
                            color: theme.palette.getContrastText(
                              theme.palette.primary.main,
                            ),
                            backgroundColor: theme.palette.primary.main,
                          }}
                        >
                          {item.avatar}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={item.name} />
                      <ListItemSecondaryAction
                        onClick={() => setRemoveItem({ ...item, index })}
                      >
                        <IconButton edge="end" aria-label="delete">
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    {customs.length > 1 && index < customs.length - 1 && (
                      <Divider variant="inset" />
                    )}
                  </React.Fragment>
                ))}
              </List>
            )}
            {removeItem && (
              <Snackbar
                open={true}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                message={`確定刪除「${removeItem.name}」？`}
                action={
                  <React.Fragment>
                    <Button
                      color="secondary"
                      onClick={() => setRemoveItem(null)}
                    >
                      取消
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => {
                        customs.splice(removeItem.index, 1);

                        firebase
                          .firestore()
                          .doc(`/classes/${removeItem.id}`)
                          .delete();

                        setRemoveItem(null);
                      }}
                    >
                      確定
                    </Button>
                  </React.Fragment>
                }
              ></Snackbar>
            )}
            <div className={styles.buttonbar}>
              {tokenInfo.dsns !== 'classroom' && (
                <Button
                  color="primary"
                  onClick={() => {
                    if (tokenInfo.dsns !== '1campus') {
                      setSteps(Route.DsnsCategory);
                    } else {
                      setSteps(Route.Onecampus);
                    }

                    setActiveStep((prevActiveStep) => prevActiveStep - 1);
                    setInputClassName('');
                    setInputStudentCount(1);
                  }}
                >
                  上一步
                </Button>
              )}
              <Button
                color="primary"
                onClick={() => {
                  if (tokenInfo.dsns !== '1campus') {
                    setSteps(Route.DsnsClassroomName);
                  } else {
                    setSteps(Route.CustomName);
                  }

                  setActiveStep((prevActiveStep) => prevActiveStep + 1);
                  setRemoveItem(null);
                }}
              >
                建立班級
              </Button>
            </div>
          </React.Fragment>
        );
      }
      case 'ClassName': {
        return (
          <React.Fragment>
            <TextField
              variant="outlined"
              placeholder="班級名稱"
              fullWidth
              value={inputClassName}
              onInput={(e) => {
                setInputClassName(e.target.value);
              }}
            />
            <div className={styles.buttonbar}>
              <Button
                color="primary"
                onClick={() => {
                  if (tokenInfo.dsns !== '1campus') {
                    setSteps(Route.DsnsClassroom);
                  } else {
                    setSteps(Route.Custom);
                  }

                  setActiveStep((prevActiveStep) => prevActiveStep - 1);
                  setInputClassName('');
                  setInputStudentCount(1);
                }}
              >
                上一步
              </Button>
              <Button
                disabled={!inputClassName}
                color="primary"
                onClick={() => {
                  setSteps(Route.CustomStudent);
                  setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }}
              >
                下一步
              </Button>
            </div>
          </React.Fragment>
        );
      }
      case 'ClassStudents': {
        return (
          <React.Fragment>
            <TextField
              variant="outlined"
              placeholder="學生人數"
              type="number"
              inputProps={{ min: 1, step: 1 }}
              value={inputStudentCount}
              onInput={(e) => {
                setInputStudentCount(parseInt(e.target.value, 10));
              }}
            />
            <div className={styles.buttonbar}>
              <Button
                color="primary"
                onClick={() => {
                  setSteps(Route.CustomName);
                  setActiveStep((prevActiveStep) => prevActiveStep - 1);
                }}
              >
                上一步
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  if (author.uuid) {
                    firebase
                      .firestore()
                      .collection('classes')
                      .add({
                        author: author.uuid,
                        name: inputClassName,
                        students: inputStudentCount,
                        timestamp:
                          firebase.firestore.FieldValue.serverTimestamp(),
                      })
                      .then((docRef) => {
                        const roomId = `custom.${docRef.id}`;
                        const target = {
                          author: author.uuid,
                          dsns: 'custom',
                          code: Math.random()
                            .toString(36)
                            .substr(2, 6)
                            .toUpperCase(),
                          teacher: {
                            name: author.name,
                          },
                          classroom: {
                            gid: '----',
                            uid: docRef.id,
                            name: inputClassName,
                            students: inputStudentCount,
                          },
                          container: state.container,
                          type: 'custom',
                        };

                        start(roomId, target);

                        // firebase
                        //   .firestore()
                        //   .doc(`/classrooms/${roomId}`)
                        //   .set({
                        //     ...target,
                        //     status: 'open',
                        //     timestamp:
                        //       firebase.firestore.FieldValue.serverTimestamp(),
                        //   })
                        //   .then(() =>
                        //     callback({
                        //       action: 'SIGN_IN',
                        //       payload: {
                        //         roomId,
                        //         accessToken: tokenInfo.accessToken,
                        //       },
                        //     }),
                        //   );
                      });
                  }
                }}
              >
                完成
              </Button>
            </div>
          </React.Fragment>
        );
      }
      default: {
        /** empty */
      }
    }
  };

  const start = (roomId, target) => {
    firebase
      .firestore()
      .doc(`/classrooms/${roomId}`)
      .get()
      .then((doc) => {
        if (doc.exists) {
          firebase
            .firestore()
            .doc(`/classrooms/${roomId}`)
            .update({
              author: target.author,
              code: target.code,
              container: target.container,
              status: 'open',
              timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then(() =>
              callback({
                action: 'SIGN_IN',
                payload: {
                  roomId,
                  accessToken: tokenInfo.accessToken,
                },
              }),
            );

          return;
        }

        firebase
          .firestore()
          .doc(`/classrooms/${roomId}`)
          .set({
            ...target,
            status: 'open',
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          })
          .then(() =>
            callback({
              action: 'SIGN_IN',
              payload: {
                roomId,
                accessToken: tokenInfo.accessToken,
              },
            }),
          );
      });
  };

  return loading ? (
    <Backdrop open={true}>
      <div className={styles.loading}>
        <CircularProgress
          color="primary"
          style={{ margin: theme.spacing(2), color: '#f1f1f1' }}
        />
        <Typography variant="h6" style={{ color: '#f1f1f1' }}>
          資料讀取中...
        </Typography>
      </div>
    </Backdrop>
  ) : (
    <Dialog open={true}>
      <DialogActions>
        <Typography
          variant="body2"
          color="primary"
          style={{ marginLeft: theme.spacing(1) }}
        >
          ISCHOOL INC. {state.version}
        </Typography>
        <div style={{ flex: 1 }} />
        {!window.opener && (
          <IconButton
            size="small"
            onClick={() =>
              callback({
                action: 'EXIT_CHOOSER',
              })
            }
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogActions>
      <DialogContent
        style={{ padding: theme.spacing(1), width: theme.spacing(34) }}
      >
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          style={{ padding: theme.spacing(1) }}
        >
          {steps.map((step) => (
            <Step key={step.key}>
              <StepLabel>{step.label}</StepLabel>
              <StepContent style={{ padding: 0 }}>
                {getStep(step.key)}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </DialogContent>
    </Dialog>
  );
};

export default Component;
