import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { deepOrange } from '@material-ui/core/colors';
import {
  Avatar,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Popover,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  CheckCircleOutline as ConfirmIcon,
  HighlightOffOutlined as CancleIcon,
  MoreVert as MoreIcon,
  NotInterested as RemoveIcon,
  Person as StudentIcon,
  Tune as SettingIcon,
  ThumbUpOutlined as ScoreIcon,
} from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import moment from 'moment';

import * as firebase from 'firebase/app';
import 'firebase/firestore';

import * as Assets from '../../../assets';
import { AppContext } from '../../../App';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(1, 1, 1, 2),
  },
  loading: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    flex: 1,
    display: 'flex',
  },
  setting: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  noneItems: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemHeader: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(2, 2, 0),
  },
  online: {
    borderWidth: 3,
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
  },
  offline: {
    borderWidth: 3,
    borderColor: 'transparent',
    borderStyle: 'solid',
  },
  balanceAvatar: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  plusAvatar: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
    marginLeft: theme.spacing(2),
  },
  negAvatar: {
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    backgroundColor: theme.palette.secondary.main,
    marginLeft: theme.spacing(2),
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
  },
}));

const SERVICE_MEMBERS =
  'https://m.1know.net/services/group/_GID_/members?accessToken=_ACCESS_TOKEN_';
const SERVICE_STUDENTS =
  'https://dsns.ischool.com.tw/_DSNS_/1campus.mobile.v2.teacher/classroom.GetStudents?rsptype=json&stt=session&sessionid=_SESSION_ID_&parser=spliter&content=UID:_UID_;Type:_TYPE_';

const Component = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { state, dispatch } = useContext(AppContext);
  const [mode, setMode] = useState('loading');
  const [scores, setScores] = useState({});
  const [students, setStudents] = useState([]);
  const [selected, setSelected] = useState(null);
  const [inputTextStudents, setInputTextStudents] = useState('');
  const [studentAnchorEl, setStudentAnchorEl] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  useEffect(() => {
    if (!state.target) return;

    fetchStudents();

    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/score`)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          firebase
            .firestore()
            .doc(`/classrooms/${state.roomId}/events/score`)
            .set({
              timestamp: new Date().toISOString(),
            });
        }
      });

    const unsubscribe = firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/score`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          setScores(doc.data());
        }
      });

    return () => unsubscribe();
    // eslint-disable-next-line
  }, [state.target]);

  const fetchStudents = () => {
    setMode('loading');

    if (state.target.type === '1know') {
      fetch(
        SERVICE_MEMBERS.replace(/_GID_/gi, state.target.classroom.gid).replace(
          /_ACCESS_TOKEN_/gi,
          state.accessToken,
        ),
      )
        .then((response) => response.json())
        .then((response) => {
          const students = response
            .filter((item) => item.role === 'member')
            .map((item, i) => ({
              ID: item.user_uqid || item.uqid,
              ClassName: '',
              Name: `${item.last_name}${item.first_name}`,
              Photo: item.photo,
              SeatNo: i + 1,
            }));

          setStudents(students);
          dispatch({ type: 'SET_STUDENTS', payload: students });
          setMode('student');
        })
        .catch(() => {
          setStudents(students);
          dispatch({ type: 'SET_STUDENTS', payload: students });
          setMode('student');
        });
    } else if (state.target.type === 'custom') {
      const students = [];
      if (typeof state.target.classroom.students === 'number') {
        for (let i = 0; i < state.target.classroom.students; i += 1) {
          students.push({
            ID: i + 1,
            ClassName: '',
            Name: `學生 ${i + 1}`,
            Photo: Assets.Monster[i % 10],
            SeatNo: i + 1,
          });
        }
      } else {
        const names = state.target.classroom.students.split(',');
        names.forEach((name, i) => {
          if (name) {
            students.push({
              ID: i + 1,
              ClassName: '',
              Name: `${i + 1} ${name.trim()}`,
              Photo: Assets.Monster[i % 10],
              SeatNo: i + 1,
            });
          }
        });
      }

      setStudents(students);
      dispatch({ type: 'SET_STUDENTS', payload: students });
      setMode('student');
    } else {
      fetch(
        SERVICE_STUDENTS.replace(/_DSNS_/gi, state.target.dsns)
          .replace(/_SESSION_ID_/gi, state.target.sessionid)
          .replace(/_UID_/gi, state.target.classroom.uid)
          .replace(/_TYPE_/gi, state.target.classroom.type),
      )
        .then((response) => response.json())
        .then((response) => {
          const students = []
            .concat(
              ((response.__Body || response).Students || {}).Student || [],
            )
            .map((student, idx) => ({
              ...student,
              Photo: student.Photo
                ? `data:image/png;base64,${student.Photo}`
                : Assets.Monster[idx % 10],
              DSNS: true,
            }));

          setStudents(students);
          dispatch({ type: 'SET_STUDENTS', payload: students });
          setMode('student');
        });
    }
  };

  const plus = () => {
    let score = scores[`student_${selected.ID}`] || 0;
    score += 1;

    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/score`)
      .update({
        [`student_${selected.ID}`]: score,
        target: {
          name: selected.Name,
          score: 1,
        },
        timestamp: Date.now(),
      });

    setTimeout(() => {
      firebase
        .firestore()
        .doc(`/classrooms/${state.roomId}/events/score`)
        .update({
          target: firebase.firestore.FieldValue.delete(),
          timestamp: Date.now(),
        });
    }, 3000);

    setSelected(null);
    setStudentAnchorEl(null);
  };

  const neg = () => {
    let score = scores[`student_${selected.ID}`] || 0;
    score -= 1;

    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/score`)
      .update({
        [`student_${selected.ID}`]: score,
        target: {
          name: selected.Name,
          score: -1,
        },
        timestamp: Date.now(),
      });

    setTimeout(() => {
      firebase
        .firestore()
        .doc(`/classrooms/${state.roomId}/events/score`)
        .update({
          target: firebase.firestore.FieldValue.delete(),
          timestamp: Date.now(),
        });
    }, 3000);

    setSelected(null);
    setStudentAnchorEl(null);
  };

  const download = (name, content) => {
    var element = document.createElement('a');
    element.setAttribute(
      'href',
      `data:text/plain;charset=utf-8,\uFEFF${encodeURIComponent(content)}`,
    );
    element.setAttribute('download', name);
    element.style.display = 'none';
    (document.body || {}).appendChild(element);
    element.click();
    (document.body || {}).removeChild(element);
  };

  const renderContent = () => {
    switch (mode) {
      case 'loading': {
        return (
          <div className={classes.loading}>
            <CircularProgress color="primary" />
          </div>
        );
      }
      case 'student': {
        return (
          <List style={{ flex: 1 }}>
            {students.map((student, sdx) => (
              <React.Fragment key={`student-${student.ID}`}>
                <ListItem
                  button
                  onClick={(e) => {
                    setSelected(student);
                    setStudentAnchorEl(e.currentTarget);
                  }}
                >
                  <ListItemAvatar>
                    <Avatar
                      src={student.Photo}
                      className={
                        student.online ? classes.online : classes.offline
                      }
                      imgProps={{ style: { backgroundColor: 'transparent' } }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      student.DSNS
                        ? `${student.SeatNo} ${student.Name}`
                        : student.Name
                    }
                    secondary={student.ClassName}
                  />
                  {scores[`student_${student.ID}`] !== undefined && (
                    <Avatar
                      className={
                        scores[`student_${student.ID}`] === 0
                          ? classes.balanceAvatar
                          : scores[`student_${student.ID}`] > 0
                          ? classes.plusAvatar
                          : classes.negAvatar
                      }
                    >
                      {scores[`student_${student.ID}`]}
                    </Avatar>
                  )}
                </ListItem>
                {sdx !== students.length - 1 && <Divider variant="inset" />}
              </React.Fragment>
            ))}
          </List>
        );
      }
      case 'setting': {
        return (
          <div className={classes.setting}>
            <TextField
              autoFocus
              variant="outlined"
              value={inputTextStudents}
              style={{ marginBottom: 16 }}
              onInput={(e) => {
                setInputTextStudents(e.target.value);
              }}
            />
            <List>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    style={{ backgroundColor: theme.palette.primary.main }}
                  >
                    A
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="輸入學生人數，依人數顯示【學生 1、學生 2、學生 3】" />
              </ListItem>
              <ListItem>
                <img
                  src={Assets.Student.Student01}
                  style={{ width: 240 }}
                  alt="輸入學生人數"
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    style={{ backgroundColor: theme.palette.primary.main }}
                  >
                    B
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="輸入學生姓名，使用逗號分隔【AAA,BBB,CCC】，依輸入姓名顯示【1
              AAA、2 BBB、3 CCC】"
                />
              </ListItem>
              <ListItem>
                <img
                  src={Assets.Student.Student02}
                  style={{ width: 240 }}
                  alt="輸入學生姓名"
                />
              </ListItem>
            </List>
            <div className={classes.row}>
              <IconButton color="secondary" onClick={() => setMode('student')}>
                <CancleIcon style={{ fontSize: '3rem' }} />
              </IconButton>
              <div style={{ width: theme.spacing(3) }} />
              <IconButton
                color="primary"
                onClick={() => {
                  const students = parseInt(inputTextStudents, 10);
                  state.target.classroom.students = isNaN(students)
                    ? inputTextStudents
                    : students;

                  firebase
                    .firestore()
                    .doc(`/classrooms/${state.roomId}`)
                    .update({ classroom: { ...state.target.classroom } });

                  firebase
                    .firestore()
                    .doc(`/classes/${state.target.classroom.uid}`)
                    .update({ students: state.target.classroom.students })
                    .then(() => {
                      fetchStudents();
                    });
                }}
              >
                <ConfirmIcon style={{ fontSize: '3rem' }} />
              </IconButton>
            </div>
          </div>
        );
      }
      default: {
        return <div />;
      }
    }
  };

  return (
    <div className={classes.root}>
      <Popover
        open={Boolean(menuAnchorEl)}
        anchorEl={menuAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={() => setMenuAnchorEl(null)}
        disableRestoreFocus
      >
        <List subheader={<ListSubheader>報表下載</ListSubheader>}>
          <ListItem
            button
            onClick={() => {
              const timestamp = moment().format('MMDDHHmm');
              const content = [`學生編號,班級名稱,座號,姓名`];
              students.forEach((i) =>
                content.push(`${i.ID},${i.ClassName},${i.SeatNo},${i.Name}`),
              );

              download(
                `${state.target.classroom.name}_學生名單_${timestamp}.csv`,
                content.join('\r\n'),
              );

              setMenuAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <StudentIcon />
            </ListItemIcon>
            <ListItemText>學生名單</ListItemText>
          </ListItem>
          <ListItem
            button
            onClick={() => {
              const timestamp = moment().format('MMDDHHmm');
              const content = [`班級名稱,座號,姓名,得分`];
              students.forEach((i) =>
                content.push(
                  `${i.ClassName},${i.SeatNo},${i.Name},${
                    scores[`student_${i.ID}`] !== undefined
                      ? scores[`student_${i.ID}`]
                      : ''
                  }`,
                ),
              );

              download(
                `${state.target.classroom.name}_課堂表現_${timestamp}.csv`,
                content.join('\r\n'),
              );

              setMenuAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <ScoreIcon />
            </ListItemIcon>
            <ListItemText>課堂表現</ListItemText>
          </ListItem>
        </List>
        <Divider variant="fullWidth" />
        <List>
          <ListItem
            button
            onClick={() => {
              enqueueSnackbar('課堂表現已清除', { autoHideDuration: 1500 });

              setMenuAnchorEl(null);

              firebase
                .firestore()
                .doc(`/classrooms/${state.roomId}/events/score`)
                .set({
                  timestamp: new Date().toISOString(),
                });
            }}
          >
            <ListItemIcon>
              <RemoveIcon />
            </ListItemIcon>
            <ListItemText>清除表現</ListItemText>
          </ListItem>
        </List>
        {state.target && state.target.type.toLowerCase() === 'custom' && (
          <React.Fragment>
            <Divider variant="fullWidth" />
            <List>
              <ListItem
                button
                onClick={() => {
                  setInputTextStudents(state.target.classroom.students);
                  setMode('setting');
                  setMenuAnchorEl(null);
                }}
              >
                <ListItemIcon>
                  <SettingIcon />
                </ListItemIcon>
                <ListItemText>編輯學生</ListItemText>
              </ListItem>
            </List>
          </React.Fragment>
        )}
      </Popover>
      <Popover
        open={Boolean(studentAnchorEl)}
        anchorEl={studentAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        onClose={() => {
          setSelected(null);
          setStudentAnchorEl(null);
        }}
        disableRestoreFocus
      >
        <List
          subheader={
            <ListSubheader>
              {selected ? `${selected.SeatNo} ${selected.Name}` : ''}
            </ListSubheader>
          }
        >
          <ListItem>
            <Button variant="contained" color="secondary" onClick={neg}>
              減 1 分
            </Button>
            <div style={{ width: theme.spacing(1) }} />
            <Button variant="contained" color="primary" onClick={plus}>
              加 1 分
            </Button>
          </ListItem>
        </List>
      </Popover>
      <div className={classes.header}>
        {mode === 'student' && (
          <Typography variant="h5">班級學生 {students.length} 人</Typography>
        )}
        {mode === 'setting' && <Typography variant="h5">編輯學生</Typography>}
        <div style={{ flex: 1 }} />
        <IconButton
          color="inherit"
          onClick={(event) => setMenuAnchorEl(event.currentTarget)}
        >
          <MoreIcon />
        </IconButton>
      </div>
      <div className={classes.content}>{renderContent()}</div>
    </div>
  );
};

export default Component;
