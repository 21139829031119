import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Avatar,
  Button,
  BottomNavigation,
  BottomNavigationAction,
  Chip,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Menu,
  MenuItem,
  Slide,
  Slider,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  CheckCircleOutline as ConfirmIcon,
  HighlightOffOutlined as CancleIcon,
  List as ListIcon,
  MoreVert as MoreIcon,
  Pets as PetsIcon,
  PhotoFilter as TransitionIcon,
} from '@material-ui/icons';

import moment from 'moment';
import * as firebase from 'firebase/app';
import 'firebase/firestore';

import { AppContext } from '../../../App';

const useStyles = makeStyles((theme) => ({
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  modal: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
    borderRadius: 0,
    padding: theme.spacing(2),
    backgroundColor: '#fff',
  },
  section: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  centerContent: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
  },
  bottom: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: theme.spacing(7),
    padding: theme.spacing(1),
  },
  avatar: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
  },
  chip: {
    fontSize: '1rem',
    margin: theme.spacing(0.5, 0),
  },
}));

const Component = ({ callback }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { state } = useContext(AppContext);
  const [target, setTarget] = useState('全班');
  const [method, setMethod] = useState('隨機');
  const [number, setNumber] = useState(3);
  const [rangeNoA, setRangeNoA] = useState(1);
  const [rangeNoB, setRangeNoB] = useState(1);
  const [exclude, setExclude] = useState('');
  const [transition, setTransition] = useState('Fade');
  const [inputText, setInputText] = useState(1);
  const [seatNoRange, setSeatNoRange] = useState([
    1,
    state.students[state.students.length - 1].SeatNo,
  ]);
  const [inputTextExclude, setInputTextExclude] = useState('');
  const [targetVisible, setTargetVisible] = useState(false);
  const [methodVisible, setMethodVisible] = useState(false);
  const [numberVisible, setNumberVisible] = useState(false);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputRangeVisible, setInputRangeVisible] = useState(false);
  const [excludeVisible, setExcludeVisible] = useState(false);
  const [transitionVisible, setTransitionVisible] = useState(false);
  const [historyVisible, setHistoryVisible] = useState(false);
  const [histories, setHistories] = useState([]);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection(`/classrooms/${state.roomId}/randoms`)
      .onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (doc.id !== '0000000000000') {
            items.push({
              id: doc.id,
              data,
              primaryText: `從 ${
                data.target === '座號'
                  ? `${data.rangeNoA} - ${data.rangeNoB}`
                  : data.target
              } 學生中 ${data.method} 抽 ${data.number} 人`,
              secondaryText: moment(data.timestamp).format(
                'YYYY-MM-DD HH:mm:ss',
              ),
            });
          }
        });

        items.sort((x, y) => (x.timestamp > y.timestamp ? 1 : -1));
        setHistories(
          items.map((item, index) => ({ ...item, avatar: index + 1 })),
        );
      });

    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/groups/0000000000000`)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const { results } = doc.data();

          if (results) {
            setGroups(
              results.map((group) => {
                return state.students.filter((student) =>
                  group.split(',').includes(`${student.ID}`),
                );
              }),
            );
          }
        }
      });

    return () => unsubscribe();
    // eslint-disable-next-line
  }, []);

  const start = () => {
    if (number === 0) {
      return;
    }

    const timestamp = Date.now();
    let event = {};
    let excludes = exclude.split(',');
    if (excludes.length === 1 && excludes[0] === '') excludes = [];

    if (target === '全班') {
      event = {
        timestamp,
        method,
        target,
        number,
        exclude,
        transition,
        results: state.students
          .filter((item) => !excludes.includes(`${item.SeatNo}`))
          .map((item) => {
            const student = { ...item };
            student.key = `student-${item.ID}`;
            student.sort = Math.random();
            return student;
          })
          .sort((x, y) => x.sort - y.sort)
          .slice(0, number)
          .sort((x, y) => parseInt(x.SeatNo, 10) - parseInt(y.SeatNo, 10))
          .map((item) => item.ID),
      };
    } else if (target === '座號') {
      event = {
        timestamp,
        method,
        target,
        number,
        exclude,
        transition,
        rangeNoA,
        rangeNoB,
        results: state.students
          .filter((item) => !excludes.includes(`${item.SeatNo}`))
          .filter((item) => item.SeatNo >= rangeNoA && item.SeatNo <= rangeNoB)
          .map((item) => {
            const student = { ...item };
            student.key = `student-${item.ID}`;
            student.sort = Math.random();
            return student;
          })
          .sort((x, y) => x.sort - y.sort)
          .slice(0, number)
          .map((student) => student.ID),
      };
    } else if (target === '小組') {
      event = {
        timestamp,
        method,
        target,
        number,
        exclude,
        transition,
        results: groups
          .map((group) =>
            group
              .filter((item) => !excludes.includes(`${item.SeatNo}`))
              .map((item) => ({
                ...item,
                key: `student-${item.ID}`,
                sort: Math.random(),
              }))
              .sort((x, y) => x.sort - y.sort)
              .slice(0, number),
          )
          .map((group) => group.map((student) => student.ID).join()),
      };
    }

    callback();

    firebase.firestore().doc(`/classrooms/${state.roomId}/events/main`).set({
      key: 'classroom.random.open',
      timestamp,
    });

    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/randoms/0000000000000`)
      .set(event);
    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/randoms/${timestamp}`)
      .set(event);
  };

  const renderTargetModal = () =>
    targetVisible && (
      <div className={clsx(classes.section, classes.modal)}>
        <div className={classes.content}>
          {(groups.length > 0
            ? [
                { key: 'target-1', value: '全班' },
                { key: 'target-2', value: '座號' },
                { key: 'target-3', value: '小組' },
              ]
            : [
                { key: 'target-1', value: '全班' },
                { key: 'target-2', value: '座號' },
              ]
          ).map((item) => (
            <Chip
              key={`item-${item.key}`}
              className={classes.chip}
              label={item.value}
              variant="default"
              color={target === item.value ? 'secondary' : 'primary'}
              onClick={() => {
                setTargetVisible(false);
                setTarget(item.value);

                if (item.value === '座號') {
                  setInputRangeVisible(true);
                }
              }}
            />
          ))}
        </div>
        <div className={classes.row}>
          <IconButton color="primary" onClick={() => setTargetVisible(false)}>
            <ConfirmIcon style={{ fontSize: '3rem' }} />
          </IconButton>
        </div>
      </div>
    );

  const renderMethodModal = () =>
    methodVisible && (
      <div className={clsx(classes.section, classes.modal)}>
        <div className={classes.content}>
          {[{ key: 'method-1', value: '隨機' }].map((item) => (
            <Chip
              key={`item-${item.key}`}
              className={classes.chip}
              label={item.value}
              variant="default"
              color={method === item.value ? 'secondary' : 'primary'}
              onClick={() => {
                setMethodVisible(false);
                setMethod(item.value);
              }}
            />
          ))}
        </div>
        <div className={classes.row}>
          <IconButton color="primary" onClick={() => setMethodVisible(false)}>
            <ConfirmIcon style={{ fontSize: '3rem' }} />
          </IconButton>
        </div>
      </div>
    );

  const renderNumberModal = () =>
    numberVisible && (
      <div className={clsx(classes.section, classes.modal)}>
        <div className={classes.content}>
          {[
            { key: 'number-1', value: 1 },
            { key: 'number-2', value: 2 },
            { key: 'number-3', value: 3 },
            { key: 'number-4', value: 4 },
            { key: 'number-5', value: 5 },
            { key: 'number-6', value: '自訂' },
          ].map((item) => (
            <Chip
              key={`item-${item.key}`}
              className={classes.chip}
              label={item.value}
              variant="default"
              color={number === item.value ? 'secondary' : 'primary'}
              onClick={() => {
                setNumberVisible(false);
                if (item.value !== '自訂') {
                  setNumber(item.value);
                } else {
                  setInputVisible(true);
                }
              }}
            />
          ))}
        </div>
        <div className={classes.row}>
          <IconButton color="primary" onClick={() => setNumberVisible(false)}>
            <ConfirmIcon style={{ fontSize: '3rem' }} />
          </IconButton>
        </div>
      </div>
    );

  const renderInputModal = () =>
    inputVisible && (
      <div className={clsx(classes.section, classes.modal)}>
        <div className={classes.content}>
          <TextField
            autoFocus
            label="請輸入人數"
            type="number"
            variant="outlined"
            value={inputText}
            inputProps={{
              min: 1,
            }}
            onInput={(e) => {
              setInputText(e.target.value);
            }}
          />
        </div>
        <div className={classes.row}>
          <IconButton color="secondary" onClick={() => setInputVisible(false)}>
            <CancleIcon style={{ fontSize: '3rem' }} />
          </IconButton>
          <div style={{ width: theme.spacing(3) }} />
          <IconButton
            color="primary"
            onClick={() => {
              setInputVisible(false);
              setInputText(inputText || 1);
              setNumber(inputText || 1);
            }}
          >
            <ConfirmIcon style={{ fontSize: '3rem' }} />
          </IconButton>
        </div>
      </div>
    );

  const renderInputRangeModal = () =>
    inputRangeVisible && (
      <div className={clsx(classes.section, classes.modal)}>
        <div className={classes.content}>
          <Typography variant="h6" gutterBottom>
            開始座號：{seatNoRange[0]}
          </Typography>
          <Typography variant="h6" gutterBottom>
            結束座號：{seatNoRange[1]}
          </Typography>
          <Slider
            min={1}
            max={state.students[state.students.length - 1].SeatNo}
            value={seatNoRange}
            onChange={(e, v) => setSeatNoRange(v)}
            valueLabelDisplay="auto"
          />
        </div>
        <div className={classes.row}>
          <IconButton
            color="secondary"
            onClick={() => setInputRangeVisible(false)}
          >
            <CancleIcon style={{ fontSize: '3rem' }} />
          </IconButton>
          <div style={{ width: theme.spacing(3) }} />
          <IconButton
            color="primary"
            onClick={() => {
              setInputRangeVisible(false);
              setRangeNoA(seatNoRange[0]);
              setRangeNoB(seatNoRange[1]);
            }}
          >
            <ConfirmIcon style={{ fontSize: '3rem' }} />
          </IconButton>
        </div>
      </div>
    );

  const renderExcludeModal = () =>
    excludeVisible && (
      <div className={clsx(classes.section, classes.modal)}>
        <div className={classes.content}>
          <TextField
            autoFocus
            label="請輸入排除座號，使用逗號分隔"
            variant="outlined"
            value={inputTextExclude}
            onInput={(e) => {
              setInputTextExclude(e.target.value);
            }}
          />
        </div>
        <div className={classes.row}>
          <IconButton
            color="secondary"
            onClick={() => setExcludeVisible(false)}
          >
            <CancleIcon style={{ fontSize: '3rem' }} />
          </IconButton>
          <div style={{ width: theme.spacing(3) }} />
          <IconButton
            color="primary"
            onClick={() => {
              setExcludeVisible(false);
              setInputTextExclude(inputTextExclude || '');
              setExclude(inputTextExclude || '');
            }}
          >
            <ConfirmIcon style={{ fontSize: '3rem' }} />
          </IconButton>
        </div>
      </div>
    );

  const renderTransitionModal = () =>
    transitionVisible && (
      <div className={clsx(classes.section, classes.modal)}>
        <div className={classes.content}>
          {[
            { key: 'transition-1', value: 'Fade', label: '淡進' },
            { key: 'transition-2', value: 'Grow', label: '擴展' },
            { key: 'transition-3', value: 'Slide', label: '滑動' },
            { key: 'transition-4', value: 'Zoom', label: '放大' },
          ].map((item) => (
            <Chip
              key={`item-${item.key}`}
              className={classes.chip}
              label={item.label}
              variant="default"
              color={transition === item.value ? 'secondary' : 'primary'}
              onClick={() => {
                setTransitionVisible(false);
                setTransition(item.value);
              }}
            />
          ))}
        </div>
        <div className={classes.row}>
          <IconButton
            color="primary"
            onClick={() => setTransitionVisible(false)}
          >
            <ConfirmIcon style={{ fontSize: '3rem' }} />
          </IconButton>
        </div>
      </div>
    );

  const renderHistoryModal = () =>
    historyVisible && (
      <div
        className={clsx(classes.section, classes.modal)}
        style={{ padding: 0 }}
      >
        <List>
          {histories.map((item, index) => (
            <React.Fragment key={item.data.timestamp}>
              <HistoryItem item={item} callback={callback} />
              {index !== histories.length - 1 && (
                <Divider variant="fullWidth" />
              )}
            </React.Fragment>
          ))}
        </List>
      </div>
    );

  const renderContent = () => (
    <div className={classes.section}>
      <div className={clsx(classes.content, classes.centerContent)}>
        {target === '座號' ? (
          <div className={classes.row}>
            <Typography variant="h5">從</Typography>
            <Button onClick={() => setTargetVisible(true)}>
              <Typography variant="h4" color="primary">
                {rangeNoA}
              </Typography>
              <Typography variant="h5" style={{ margin: theme.spacing(0, 1) }}>
                -
              </Typography>
              <Typography variant="h4" color="primary">
                {rangeNoB}
              </Typography>
            </Button>
            <Typography variant="h5">號學生中</Typography>
          </div>
        ) : (
          <div className={classes.row}>
            <Typography variant="h5">從</Typography>
            <Button onClick={() => setTargetVisible(true)}>
              <Typography variant="h4" color="primary">
                {target}
              </Typography>
            </Button>
            <Typography variant="h5">學生中</Typography>
          </div>
        )}
        <div className={classes.row}>
          <Button onClick={() => setMethodVisible(true)}>
            <Typography variant="h4" color="primary">
              {method}
            </Typography>
          </Button>
          <Typography variant="h5">抽</Typography>
          <Button onClick={() => setNumberVisible(true)}>
            <Typography variant="h4" color="primary">
              {number}
            </Typography>
          </Button>
          <Typography variant="h5">人</Typography>
        </div>
        <div className={classes.row}>
          <Button onClick={() => setExcludeVisible(true)}>
            <Typography variant="h4" color="primary">
              排除 {exclude !== '' ? exclude.split(',').length : '0'} 人
            </Typography>
          </Button>
        </div>
        <div className={classes.row} style={{ marginTop: theme.spacing(2) }}>
          <IconButton color="primary" onClick={start}>
            <PetsIcon style={{ fontSize: '3rem' }} />
          </IconButton>
        </div>
      </div>
      <div className={classes.bottom}>
        <BottomNavigation showLabels>
          <BottomNavigationAction
            label="歷史記錄"
            icon={<ListIcon />}
            onClick={() => setHistoryVisible(true)}
          />
          <BottomNavigationAction
            label="特效設定"
            icon={<TransitionIcon />}
            onClick={() => setTransitionVisible(true)}
          />
        </BottomNavigation>
      </div>
    </div>
  );

  return (
    <Slide in direction="up" timeout={1000}>
      <div className={classes.main}>
        {renderTargetModal()}
        {renderMethodModal()}
        {renderNumberModal()}
        {renderInputModal()}
        {renderInputRangeModal()}
        {renderExcludeModal()}
        {renderTransitionModal()}
        {renderHistoryModal()}
        {renderContent()}
      </div>
    </Slide>
  );
};

export default Component;

const HistoryItem = ({ item, callback }) => {
  const classes = useStyles();
  const { state } = useContext(AppContext);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  return (
    <>
      <Menu
        onClose={() => setMenuAnchorEl(null)}
        open={Boolean(menuAnchorEl)}
        anchorEl={menuAnchorEl}
      >
        <MenuItem
          onClick={() => {
            let results = [];
            if (['全班', '座號'].includes(item.data.target)) {
              results = [
                {
                  caption: '',
                  data: state.students.filter((student) =>
                    item.data.results
                      .join()
                      .split(',')
                      .includes(`${student.ID}`),
                  ),
                },
              ];
            } else {
              results = item.data.results.map((item, index) => ({
                caption: `第 ${index + 1} 組 ${item.split(',').length} 人`,
                data: state.students.filter((student) =>
                  item.split(',').includes(`${student.ID}`),
                ),
              }));
            }

            const timestamp = moment(item.data.timestamp).format('MMDDHHmmss');
            const content = [item.primaryText];
            if (item.data.exclude) {
              content.push(`排除座號 ${item.data.exclude}`);
            }
            if (item.data.target !== '全班') {
              results.forEach((item) => {
                content.push(item.caption);
                item.data.map((i) =>
                  content.push(`${i.ID},${i.ClassName},${i.SeatNo},${i.Name}`),
                );
              });
            } else {
              results[0].data.forEach((i) =>
                content.push(`${i.ID},${i.ClassName},${i.SeatNo},${i.Name}`),
              );
            }

            var element = document.createElement('a');
            element.setAttribute(
              'href',
              `data:text/plain;charset=utf-8,\uFEFF${encodeURIComponent(
                content.join('\r\n'),
              )}`,
            );
            element.setAttribute(
              'download',
              `${state.target.classroom.name}_隨機抽人_${timestamp}.csv`,
            );
            element.style.display = 'none';
            (document.body || {}).appendChild(element);
            element.click();
            (document.body || {}).removeChild(element);

            setMenuAnchorEl(null);
          }}
        >
          下載
        </MenuItem>
        <MenuItem
          onClick={() => {
            firebase
              .firestore()
              .doc(`/classrooms/${state.roomId}/randoms/${item.id}`)
              .delete();
            setMenuAnchorEl(null);
          }}
        >
          刪除
        </MenuItem>
      </Menu>
      <ListItem
        button
        onClick={() => {
          firebase
            .firestore()
            .doc(`/classrooms/${state.roomId}/events/main`)
            .set({
              key: 'classroom.random.open',
              timestamp: Date.now(),
            });
          firebase
            .firestore()
            .doc(`/classrooms/${state.roomId}/randoms/0000000000000`)
            .set(item.data);
          callback();
        }}
      >
        <ListItemAvatar>
          <Avatar className={classes.avatar}>{item.avatar}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={item.primaryText}
          secondary={item.secondaryText}
        />
        <ListItemSecondaryAction>
          <IconButton edge="end" onClick={(e) => setMenuAnchorEl(e.target)}>
            <MoreIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
};
