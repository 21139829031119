import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Avatar,
  Button,
  BottomNavigation,
  BottomNavigationAction,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  ClearAll as ClearAllIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
  Done as DoneIcon,
  DoneAll as CheckAllIcon,
  HourglassEmpty as EmptyIcon,
  PlaylistAddCheck as EditIcon,
  RecordVoiceOver as VoiceIcon,
  TextFields as TextIcon,
  VideoLibrary as VideoIcon,
} from '@material-ui/icons';

import * as firebase from 'firebase/app';
import 'firebase/firestore';

import { withStore } from '../context';

const useStyles = makeStyles((theme) => ({
  normalAvatar: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
  },
  selectedAvatar: {
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    backgroundColor: theme.palette.secondary.main,
  },
}));

const Component = ({ dsns, devices, userName, target, changeTarget }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [current, setCurrent] = useState(null);
  const [sending, setSending] = useState(false);
  const [choose, setChoose] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');

  useEffect(() => {
    if (target) {
      setCurrent({ ...target });
    }
    // eslint-disable-next-line
  }, [target]);

  useEffect(() => {
    if (!current) {
      changeTarget(null);
    }
    // eslint-disable-next-line
  }, [current]);

  const sendMessage = () => {
    if (sending || !current) {
      return;
    }

    const { title, type, content, url, targets } = current;

    if (title === '') {
      setAlertMsg('訊息標題不可空白');
      setAlert(true);
      return;
    }
    if (['text', 'voice'].includes(type) && content === '') {
      setAlertMsg('訊息內文不可空白');
      setAlert(true);
      return;
    }
    if (type === 'video' && url === '') {
      setAlertMsg('YouTube 或 Vimeo 影片網址不可空白');
      setAlert(true);
      return;
    }
    if (targets.length === 0) {
      setAlertMsg('沒有選取任何對象裝置');
      setAlert(true);
      return;
    }

    setSending(true);

    const docId = current.docId || `${Date.now()}`;
    const custom = ['text', 'voice'].includes(type)
      ? { type, content }
      : { type, url };

    firebase
      .firestore()
      .doc(`/broadcasts/${docId}`)
      .set({
        dsns: dsns,
        title,
        targets,
        ...custom,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        createBy: userName,
      })
      .then(() => {
        firebase
          .firestore()
          .doc(`/pubsubs/${dsns}.broadcast`)
          .set({
            title,
            ...custom,
            targets,
            docId,
          })
          .then(() => {
            firebase.firestore().doc(`/pubsubs/${dsns}.broadcast`).delete();
          });
      });

    setCurrent(null);
    setSending(false);
  };

  const deleteMessage = (item) => {
    firebase.firestore().doc(`/broadcasts/${item.docId}`).delete();

    firebase
      .firestore()
      .collection(`/broadcasts/${item.docId}/devices`)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
      });

    setCurrent(null);
  };

  const renderDeviceDialog = () =>
    choose &&
    current && (
      <Dialog open={choose} onClose={() => setChoose(false)}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            paddingRight: theme.spacing(1),
          }}
        >
          <DialogTitle>選擇發送教室/裝置</DialogTitle>
          <div style={{ flex: 1 }} />
          <IconButton onClick={() => setChoose(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent style={{ padding: 0 }}>
          <List>
            {devices.map((item) => (
              <ListItem
                key={`choose-${item.deviceId}`}
                button
                onClick={() => {
                  const targets = [...current.targets];

                  if (!targets.includes(item.deviceId)) {
                    targets.push(item.deviceId);
                  } else {
                    targets.splice(targets.indexOf(item.deviceId), 1);
                  }

                  setCurrent({
                    ...current,
                    targets,
                  });
                }}
              >
                <ListItemAvatar>
                  <Avatar
                    className={
                      current.targets.includes(item.deviceId)
                        ? classes.selectedAvatar
                        : classes.normalAvatar
                    }
                  >
                    {current.targets.includes(item.deviceId) ? (
                      <DoneIcon />
                    ) : (
                      item.label
                    )}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={item.classroomName}
                  secondary={item.deviceId}
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Tooltip title="全選" placement="top">
            <IconButton
              onClick={() =>
                setCurrent({
                  ...current,
                  targets: devices.map((device) => device.deviceId),
                })
              }
            >
              <CheckAllIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="清除全選" placement="top">
            <IconButton
              onClick={() => {
                setCurrent({
                  ...current,
                  targets: [],
                });
              }}
            >
              <ClearAllIcon />
            </IconButton>
          </Tooltip>
        </DialogActions>
      </Dialog>
    );

  const renderConfirmDialog = () =>
    confirm && (
      <Dialog open={confirm}>
        <DialogTitle>確定刪除這則訊息？</DialogTitle>
        <DialogActions>
          <div style={{ flex: 1 }} />
          <Button color="secondary" onClick={() => setConfirm(false)}>
            取消
          </Button>
          <Button
            color="primary"
            onClick={() => {
              setConfirm(false);
              deleteMessage(current);
            }}
          >
            確定
          </Button>
        </DialogActions>
      </Dialog>
    );

  const renderAlertDialog = () =>
    alert && (
      <Dialog open={alert}>
        <DialogTitle>{alertMsg}</DialogTitle>
        <DialogActions>
          <div style={{ flex: 1 }} />
          <Button color="primary" onClick={() => setAlert(false)}>
            確定
          </Button>
        </DialogActions>
      </Dialog>
    );

  return !current ? (
    <Paper
      style={{
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0,0,0,.5)',
      }}
    >
      <Button
        color="secondary"
        variant="contained"
        size="large"
        onClick={() => {
          setCurrent({
            title: '',
            type: 'text',
            content: '',
            url: '',
            targets: [],
          });
        }}
      >
        新增訊息
      </Button>
    </Paper>
  ) : (
    <React.Fragment>
      {renderDeviceDialog()}
      {renderConfirmDialog()}
      {renderAlertDialog()}
      <Paper style={{ flex: 1, padding: theme.spacing(2) }}>
        <div style={{ margin: theme.spacing(1) }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Typography variant="subtitle1">
              {`發送到 ${current.targets.length} 個裝置`}
            </Typography>
            <Tooltip title="選擇裝置">
              <IconButton
                size="small"
                style={{ marginLeft: theme.spacing(1) }}
                onClick={() => setChoose(true)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              padding: theme.spacing(1, 0.5),
            }}
          >
            {devices
              .filter((item) => current.targets.includes(item.deviceId))
              .map((item) => {
                const readed = current.devices
                  ? (
                      current.devices.filter(
                        (device) => item.deviceId === device.deviceId,
                      )[0] || { readed: false }
                    ).readed
                  : false;

                return (
                  <Chip
                    key={`device-${item.deviceId}`}
                    variant="default"
                    color={readed ? 'primary' : 'default'}
                    label={item.classroomName}
                    style={{ margin: theme.spacing(0.5) }}
                    icon={readed ? <DoneIcon /> : <EmptyIcon />}
                  />
                );
              })}
          </div>
        </div>
        <div style={{ margin: 8 }}>
          <Typography variant="subtitle1" gutterBottom>
            訊息標題
          </Typography>
          <TextField
            fullWidth
            multiline
            rowsMax="2"
            value={current.title}
            margin="normal"
            variant="outlined"
            onChange={(e) =>
              setCurrent({
                ...current,
                title: e.target.value,
              })
            }
          />
        </div>
        <div style={{ margin: 8 }}>
          <Typography variant="subtitle1" gutterBottom>
            訊息類型
          </Typography>
          <div style={{ margin: theme.spacing(2, 0) }}>
            <BottomNavigation
              showLabels
              style={{ display: 'block' }}
              value={['text', 'voice', 'video'].indexOf(current.type)}
              onChange={(event, value) => {
                setCurrent({
                  ...current,
                  type: ['text', 'voice', 'video'][value],
                });
              }}
            >
              <BottomNavigationAction label="文字" icon={<TextIcon />} />
              <BottomNavigationAction label="語音" icon={<VoiceIcon />} />
              <BottomNavigationAction label="影片" icon={<VideoIcon />} />
            </BottomNavigation>
          </div>
        </div>
        {['text', 'voice'].includes(current.type) && (
          <div style={{ margin: theme.spacing(1) }}>
            <Typography variant="subtitle1" gutterBottom>
              訊息內文
            </Typography>
            <TextField
              fullWidth
              multiline
              rowsMax="6"
              value={current.content}
              margin="normal"
              variant="outlined"
              onChange={(e) =>
                setCurrent({
                  ...current,
                  content: e.target.value,
                })
              }
            />
          </div>
        )}
        {current.type === 'video' && (
          <div style={{ margin: theme.spacing(1) }}>
            <Typography variant="subtitle1" gutterBottom>
              YouTube 或 Vimeo 影片網址
            </Typography>
            <TextField
              fullWidth
              multiline
              rowsMax="4"
              value={current.url}
              margin="normal"
              variant="outlined"
              onChange={(e) =>
                setCurrent({
                  ...current,
                  url: e.target.value,
                })
              }
            />
          </div>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(1),
          }}
        >
          {current.docId && (
            <IconButton onClick={() => setConfirm(true)}>
              <DeleteIcon />
            </IconButton>
          )}
          <div style={{ flex: 1 }} />
          {!sending && (
            <Button
              color="secondary"
              variant="contained"
              onClick={() => setCurrent(null)}
            >
              取消
            </Button>
          )}
          <div style={{ width: theme.spacing(1) }} />
          <Button
            variant="contained"
            color={sending ? 'secondary' : 'primary'}
            onClick={sendMessage}
          >
            {sending ? '發送中...' : current.docId ? '重新發送' : '發送'}
          </Button>
        </div>
      </Paper>
    </React.Fragment>
  );
};

export default withStore(Component);
