import React, { useContext, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Button,
  Chip,
  IconButton,
  Paper,
  Slide,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  CheckCircleOutline as ConfirmIcon,
  HighlightOffOutlined as CancleIcon,
  PlayCircleOutline as PlayIcon,
} from '@material-ui/icons';

import * as firebase from 'firebase/app';
import 'firebase/firestore';

import { AppContext } from '../../../App';

const useStyles = makeStyles((theme) => ({
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  modal: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
    borderRadius: 0,
    padding: theme.spacing(2),
  },
  section: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  centerContent: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
  },
  chip: {
    fontSize: '1rem',
    margin: theme.spacing(0.5, 0),
  },
}));

const Component = ({ callback }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { state } = useContext(AppContext);
  const [method, setMethod] = useState('倒數');
  const [number, setNumber] = useState(30);
  const [inputText, setInputText] = useState(1);
  const [methodVisible, setMethodVisible] = useState(false);
  const [numberVisible, setNumberVisible] = useState(false);
  const [inputVisible, setInputVisible] = useState(false);

  const start = () => {
    firebase.firestore().doc(`/classrooms/${state.roomId}/events/main`).set({
      key: 'classroom.timer.open',
      timestamp: Date.now(),
    });

    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/timer`)
      .set({
        method,
        number: method === '正數' ? 0 : number,
        command: 'start',
        timestamp: Date.now(),
      });

    callback();
  };

  const renderInputDialog = () =>
    inputVisible && (
      <Paper className={clsx(classes.section, classes.modal)}>
        <div className={classes.content}>
          <TextField
            autoFocus
            label="請輸入秒數"
            type="number"
            variant="outlined"
            value={inputText}
            inputProps={{
              min: 1,
            }}
            onInput={(e) => {
              setInputText(e.target.value);
            }}
          />
        </div>
        <div className={classes.row}>
          <IconButton color="secondary" onClick={() => setInputVisible(false)}>
            <CancleIcon style={{ fontSize: '3rem' }} />
          </IconButton>
          <div style={{ width: theme.spacing(4) }} />
          <IconButton
            color="primary"
            onClick={() => {
              setInputVisible(false);
              setInputText(inputText || 5);
              setNumber(inputText || 5);
            }}
          >
            <ConfirmIcon style={{ fontSize: '3rem' }} />
          </IconButton>
        </div>
      </Paper>
    );

  const renderMethodDialog = () =>
    methodVisible && (
      <Paper className={clsx(classes.section, classes.modal)}>
        <div className={classes.content}>
          {[
            { key: 'method-1', value: '正數' },
            { key: 'method-2', value: '倒數' },
          ].map((item) => (
            <Chip
              key={`item-${item.key}`}
              className={classes.chip}
              label={item.value}
              variant="default"
              color={method === item.value ? 'secondary' : 'primary'}
              onClick={() => {
                setMethodVisible(false);
                setMethod(item.value);
              }}
            />
          ))}
        </div>
        <div className={classes.row}>
          <IconButton color="primary" onClick={() => setMethodVisible(false)}>
            <ConfirmIcon style={{ fontSize: '3rem' }} />
          </IconButton>
        </div>
      </Paper>
    );

  const renderNumberDialog = () =>
    numberVisible && (
      <Paper className={clsx(classes.section, classes.modal)}>
        <div className={classes.content}>
          {[
            { key: 'number-1', value: 10 },
            { key: 'number-2', value: 20 },
            { key: 'number-3', value: 30 },
            { key: 'number-4', value: 60 },
            { key: 'number-5', value: 90 },
            { key: 'number-6', value: '自訂' },
          ].map((item) => (
            <Chip
              key={`item-${item.key}`}
              className={classes.chip}
              label={item.value}
              variant="default"
              color={number === item.value ? 'secondary' : 'primary'}
              onClick={() => {
                setNumberVisible(false);
                if (item.value !== '自訂') {
                  setNumber(item.value);
                } else {
                  setInputVisible(true);
                }
              }}
            />
          ))}
        </div>
        <div className={classes.row}>
          <IconButton color="primary" onClick={() => setNumberVisible(false)}>
            <ConfirmIcon style={{ fontSize: '3rem' }} />
          </IconButton>
        </div>
      </Paper>
    );

  const renderContent = () => (
    <div className={classes.section}>
      <div className={clsx(classes.content, classes.centerContent)}>
        <div className={classes.row}>
          <Button onClick={() => setMethodVisible(true)}>
            <Typography variant="h4" color="primary">
              {method}
            </Typography>
          </Button>
          <Typography variant="h5">計時</Typography>
          {method === '倒數' && (
            <>
              <Button onClick={() => setNumberVisible(true)}>
                <Typography variant="h4" color="primary">
                  {number}
                </Typography>
              </Button>
              <Typography variant="h5">秒</Typography>
            </>
          )}
        </div>
        <div className={classes.row} style={{ marginTop: theme.spacing(2) }}>
          <IconButton color="primary" onClick={() => start()}>
            <PlayIcon style={{ fontSize: '3rem' }} />
          </IconButton>
        </div>
      </div>
    </div>
  );

  return (
    <Slide in direction="up" timeout={1000}>
      <div className={classes.main}>
        {renderMethodDialog()}
        {renderInputDialog()}
        {renderNumberDialog()}
        {renderContent()}
      </div>
    </Slide>
  );
};

export default Component;
