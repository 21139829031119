import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from '@material-ui/core';

import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';

import { withStore } from '../../context';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    overflow: 'auto',
    padding: theme.spacing(1),
  },
  leftContent: {
    width: 240,
    position: 'relative',
    overflow: 'auto',
    border: '1px solid #ccc',
    borderRadius: theme.spacing(1),
  },
  leftList: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  rightContent: {
    position: 'relative',
    overflow: 'auto',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  rightItems: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  loading: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Component = ({ target }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [days] = useState([
    '星期日',
    '星期一',
    '星期二',
    '星期三',
    '星期四',
    '星期五',
    '星期六',
  ]);
  const [periods] = useState([
    { caption: '第一節', beginTime: '08:00', endTime: '09:00' },
    { caption: '第二節', beginTime: '09:00', endTime: '10:00' },
    { caption: '第三節', beginTime: '10:00', endTime: '11:00' },
    { caption: '第四節', beginTime: '11:00', endTime: '12:00' },
    { caption: '第五節', beginTime: '13:00', endTime: '14:00' },
    { caption: '第六節', beginTime: '14:00', endTime: '15:00' },
    { caption: '第七節', beginTime: '15:00', endTime: '16:00' },
    { caption: '第八節', beginTime: '16:00', endTime: '17:00' },
    { caption: '第九節', beginTime: '18:00', endTime: '19:00' },
    { caption: '第十節', beginTime: '19:00', endTime: '20:00' },
    { caption: '第十一節', beginTime: '20:00', endTime: '21:00' },
    { caption: '第十二節', beginTime: '21:00', endTime: '22:00' },
  ]);
  const [sections, setSections] = useState([]);
  const [current, setCurrent] = useState(null);
  const [saving, setSaving] = useState(false);
  const [confirm, setConfirm] = useState(false);

  useEffect(() => {
    if (!target) {
      return;
    }

    const unsubscribe = firebase
      .firestore()
      .collection(`/devices/${target.deviceId}/schedule`)
      .onSnapshot((querySnapshot) => {
        let data = days.map((day, dIdx) => ({
          title: day,
          data: periods.map((period, pIdx) => ({
            docId: `event_${dIdx}_${pIdx + 1}`,
            day,
            period: pIdx + 1,
            caption: period.caption,
            courseName: '',
            teacherName: '',
            beginTime: period.beginTime,
            endTime: period.endTime,
          })),
        }));

        querySnapshot.forEach((doc) => {
          data = data.map((section) => ({
            title: section.title,
            data: section.data.map((item) =>
              item.docId === doc.id ? { ...doc.data(), docId: doc.id } : item,
            ),
          }));
        });

        setSections(data);
      });

    return () => unsubscribe();
    // eslint-disable-next-line
  }, [target]);

  const saveItem = () => {
    if (saving || !current) {
      return;
    }

    setSaving(true);

    const data = {
      day: current.day,
      period: current.period,
      caption: current.caption,
      courseName: current.courseName,
      teacherName: current.teacherName,
      beginTime: current.beginTime,
      endTime: current.endTime,
    };

    firebase
      .firestore()
      .doc(`/devices/${target.deviceId}/schedule/${current.docId}`)
      .set(data)
      .then((_) => {
        setSaving(false);
        setCurrent(null);
      })
      .catch((_) => {
        setSaving(false);
      });
  };

  const deleteItem = (item) => {
    firebase
      .firestore()
      .doc(`/devices/${target.deviceId}/schedule/${item.docId}`)
      .delete();

    setCurrent(null);
  };

  const renderConfirmDialog = () =>
    confirm &&
    current && (
      <Dialog open={confirm}>
        <DialogTitle>
          {`確定清除「${current.day} ${current.caption}」此節課程資訊？`}
        </DialogTitle>
        <DialogActions>
          <div style={{ flex: 1 }} />
          <Button color="secondary" onClick={() => setConfirm(false)}>
            取消
          </Button>
          <Button
            color="primary"
            onClick={() => {
              setConfirm(false);
              deleteItem(current);
            }}
          >
            確定
          </Button>
        </DialogActions>
      </Dialog>
    );

  return (
    <div className={classes.root}>
      {renderConfirmDialog()}
      <div className={classes.leftContent}>
        <div className={classes.leftList}>
          {sections.map((section, index) => (
            <List key={`section-${index}`}>
              <Typography
                variant="h5"
                color="primary"
                style={{ padding: theme.spacing(0, 2) }}
              >
                {section.title}
              </Typography>
              {section.data.map((item) => (
                <ListItem
                  key={item.docId}
                  button
                  selected={current && current.docId === item.docId}
                  onClick={() => setCurrent(item)}
                >
                  <ListItemText
                    primary={
                      <span
                        style={{
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          wordBreak: 'break-all',
                          display: '-webkit-box',
                          whiteSpace: 'pre-line',
                        }}
                      >
                        {item.courseName || '課程名稱'}
                      </span>
                    }
                    secondary={
                      <span>
                        <span
                          style={{
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            wordBreak: 'break-all',
                            display: '-webkit-box',
                            whiteSpace: 'pre-line',
                          }}
                        >
                          {item.teacherName}
                        </span>
                        <span>
                          {`${item.caption} : ${item.beginTime} - ${item.endTime}`}
                        </span>
                      </span>
                    }
                  />
                </ListItem>
              ))}
            </List>
          ))}
        </div>
      </div>
      {current ? (
        <div className={classes.rightContent}>
          <div className={classes.rightItems}>
            <Typography variant="h5" gutterBottom>
              {current.day} {current.caption}
            </Typography>
            <TextField
              label="課程名稱"
              value={current.courseName || ''}
              margin="normal"
              variant="outlined"
              onChange={(e) =>
                setCurrent({
                  ...current,
                  courseName: e.target.value,
                })
              }
            />
            <div style={{ height: theme.spacing(2) }} />
            <TextField
              label="教師姓名"
              value={current.teacherName || ''}
              margin="normal"
              variant="outlined"
              onChange={(e) =>
                setCurrent({
                  ...current,
                  teacherName: e.target.value,
                })
              }
            />
            <div style={{ height: theme.spacing(2) }} />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TextField
                  label="上課時間"
                  type="time"
                  value={current.beginTime || ''}
                  margin="normal"
                  variant="outlined"
                  onChange={(e) =>
                    setCurrent({
                      ...current,
                      beginTime: e.target.value,
                    })
                  }
                />
              </div>
              <div style={{ width: theme.spacing(2) }} />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TextField
                  label="下課時間"
                  type="time"
                  value={current.endTime || ''}
                  margin="normal"
                  variant="outlined"
                  onChange={(e) =>
                    setCurrent({
                      ...current,
                      endTime: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div style={{ height: theme.spacing(2) }} />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <IconButton onClick={() => setConfirm(true)}>
                <Icon>delete</Icon>
              </IconButton>
              <div style={{ flex: 1 }} />
              {!saving && (
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => setCurrent(null)}
                >
                  取消
                </Button>
              )}
              <div style={{ width: theme.spacing(1) }} />
              <Button color="primary" variant="contained" onClick={saveItem}>
                {saving ? '儲存中...' : '儲存'}
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <React.Fragment />
      )}
    </div>
  );
};

export default withStore(Component);
