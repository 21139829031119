import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import { AppContext } from '../../App';

import Action from './action';
import Main from './main';
import Content from './content';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
  },
}));

const SERVICE_USER_TOKEN =
  'https://us-central1-classroom-1campus.cloudfunctions.net/getUserToken?accessToken=_ACCESS_TOKEN_';
const SERVICE_AUTH =
  'https://us-central1-classroom-1campus.cloudfunctions.net/auth?accessToken=_ACCESS_TOKEN_&state=users:_UUID_';
const SERVICE_SESSION_ID =
  'https://dsns.ischool.com.tw/_DSNS_/1campus.mobile.v2.teacher/DS.Base.Connect?stt=PassportAccessToken&AccessToken=_ACCESS_TOKEN_&parser=spliter&content=RequestSessionID&rsptype=json';

const Component = () => {
  const classes = useStyles();
  const { first } = useParams();

  const { state, dispatch } = useContext(AppContext);

  const [target, setTarget] = useState(null);
  const [config, setConfig] = useState(null);

  const [behavior, setBehavior] = useState(null);

  useEffect(() => {
    let authInterval;

    fetch(SERVICE_USER_TOKEN.replace(/_ACCESS_TOKEN_/gi, state.accessToken))
      .then((response) => response.json())
      .then((response) => {
        if (!response.token) {
          window.localStorage.clear();
          window.location.reload();
          return;
        }

        firebase
          .auth()
          .signInWithCustomToken(response.token)
          .then(() => {
            const url = SERVICE_AUTH.replace(
              /_ACCESS_TOKEN_/gi,
              state.accessToken,
            ).replace(/_UUID_/gi, response.uuid);

            fetch(url);
            authInterval = setInterval(() => fetch(url), 3000000);

            firebase
              .firestore()
              .doc(`/classrooms/${state.roomId}`)
              .get()
              .then((doc) => {
                if (doc.exists) {
                  const target = doc.data();
                  const { status, dsns, teacher } = target;

                  dispatch({
                    type: 'SET_MY_INFO',
                    payload: { ...response, ...teacher },
                  });

                  if (status === 'close') {
                    window.localStorage.clear();
                    window.location.reload();
                    return;
                  }

                  let domain = '';
                  const match = dsns.match(
                    /([a-z0-9.]+)\.([a-z0-9]+)\.edu\.tw/,
                  );

                  if (match) {
                    domain = `${match[2]}.edu.tw`;
                  }

                  setTarget({ ...target, domain });
                } else {
                  window.localStorage.clear();
                  window.location.reload();
                }
              })
              .catch(() => {
                window.localStorage.clear();
                window.location.reload();
              });

            if (first === 'true') {
              firebase
                .firestore()
                .collection(`/classrooms/${state.roomId}/chats`)
                .get()
                .then((querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    doc.ref.delete();
                  });
                });

              firebase
                .firestore()
                .collection(`/classrooms/${state.roomId}/events`)
                .get()
                .then((querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    if (
                      !['random', 'group', 'score', 'meet'].includes(doc.id)
                    ) {
                      doc.ref.delete();
                    }
                  });
                });
            }
          });
      });

    return () => window.clearInterval(authInterval);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!target) return;

    if (['1know', 'custom'].includes(target.dsns)) {
      firebase
        .firestore()
        .doc('/configs/1campus')
        .get()
        .then((doc) => {
          if (doc.exists) {
            setConfig(JSON.parse(doc.data().content));
          }
        });

      dispatch({ type: 'SET_TARGET', payload: target });
    } else {
      Promise.all([
        new Promise((rs) => {
          firebase
            .firestore()
            .doc('/configs/1campus')
            .get()
            .then((doc) => rs(doc.exists ? doc.data() : null));
        }),
        new Promise((rs) => {
          firebase
            .firestore()
            .doc(`/configs/${target.domain || 'NONE_DOMAIN'}`)
            .get()
            .then((doc) => rs(doc.exists ? doc.data() : null));
        }),
        new Promise((rs) => {
          firebase
            .firestore()
            .doc(`/configs/${target.dsns || 'NONE_DSNS'}`)
            .get()
            .then((doc) => rs(doc.exists ? doc.data() : null));
        }),
        new Promise((rs) => {
          fetch(
            SERVICE_SESSION_ID.replace(/_DSNS_/gi, target.dsns).replace(
              /_ACCESS_TOKEN_/gi,
              state.accessToken,
            ),
          )
            .then((response) => response.json())
            .then((response) => {
              rs(response.SessionID);
            });
        }),
      ]).then(([defaultConfig, domainConfig, dsnsConfig, sessionid]) => {
        setConfig(
          JSON.parse(
            (dsnsConfig || domainConfig || defaultConfig || {}).content,
          ),
        );

        if (sessionid) {
          return dispatch({
            type: 'SET_TARGET',
            payload: { ...target, sessionid },
          });
        }

        dispatch({ type: 'SET_TARGET', payload: target });
      });
    }
    // eslint-disable-next-line
  }, [target]);

  useEffect(() => {
    if (!config) return;

    dispatch({ type: 'SET_CONFIG', payload: config });

    const unsubscribe1 = firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/behavior`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          state.communication.postMessage(
            JSON.stringify({
              action: 'BACK_MAIN_PAGE',
            }),
          );

          setBehavior(doc.data());
          setTimeout(() => setBehavior(null), 3000);
        }
      });

    const unsubscribe2 = firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const { status } = doc.data();

          if (status === 'close') {
            state.communication.postMessage(
              JSON.stringify({
                action: 'SIGNOUT',
              }),
            );

            window.localStorage.clear();
            window.location.reload();
          }
        }
      });

    firebase.firestore().doc(`/classrooms/${state.roomId}`).update({
      container: state.container,
    });

    return () => {
      unsubscribe1();
      unsubscribe2();
    };
    // eslint-disable-next-line
  }, [config]);

  return (
    <SnackbarProvider maxSnack={3}>
      <div className={classes.root}>
        {behavior && (
          <Dialog open={true}>
            <DialogTitle>{behavior.student.name}</DialogTitle>
            <DialogContent>
              <Typography
                variant="h1"
                color={behavior.points > 0 ? 'primary' : 'secondary'}
              >
                {behavior.name}
              </Typography>
            </DialogContent>
          </Dialog>
        )}
        <Action />
        <Main />
        <Content />
      </div>
    </SnackbarProvider>
  );
};

export default Component;
