import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Fade,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Mic as MicIcon, MicNone as MicNoneIcon } from '@material-ui/icons';

import * as firebase from 'firebase/app';
import 'firebase/firestore';

import { AppContext } from '../../../App';

const useStyles = makeStyles((theme) => ({
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  row: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  normalButton: {
    border: `2px solid rgba(0, 0, 0, 0.54)`,
  },
  speechingButton: {
    border: `2px solid ${theme.palette.secondary.main}`,
  },
  failed: {
    color: theme.palette.error.main,
  },
}));

const Component = ({ callback }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { state } = useContext(AppContext);
  const [stt] = useState(new window.webkitSpeechRecognition());
  const [speeching, setSpeeching] = useState(false);
  const [transcript, setTranscript] = useState('');
  const [failed, setFailed] = useState(false);
  const [alert, setAlert] = useState(false);

  useEffect(() => {
    const timerCommander = (command) => {
      setFailed(false);

      firebase.firestore().doc(`/classrooms/${state.roomId}/events/main`).set({
        key: 'classroom.timer.open',
        timestamp: Date.now(),
      });

      let [, method, number] = command;
      method = ['倒數'].includes(method) ? method : '倒數';

      if (number === '0') {
        return;
      } else if (number === '兩') {
        number = 2;
      } else {
        number = parseInt(number, 10);
      }

      callback();

      firebase.firestore().doc(`/classrooms/${state.roomId}/events/timer`).set({
        method,
        number,
        command: 'start',
        timestamp: Date.now(),
      });
    };

    const randomCommander = (command) => {
      firebase.firestore().doc(`/classrooms/${state.roomId}/events/main`).set({
        key: 'classroom.random.open',
        timestamp: Date.now(),
      });

      let [, target, method, number] = command;
      target = ['全班', '小組'].includes(target) ? target : '全班';
      method = ['隨機'].includes(method) ? method : '隨機';

      if (number === '0') {
        return;
      } else if (number === '兩') {
        number = 2;
      } else {
        number = parseInt(number, 10);
      }

      if (target === '全班') {
        firebase
          .firestore()
          .doc(`/classrooms/${state.roomId}/events/random`)
          .set({
            method,
            target,
            number,
            results: state.students
              .map((item) => {
                const student = { ...item };
                student.key = `student-${item.ID}`;
                student.sort = Math.random();
                return student;
              })
              .sort((x, y) => x.sort - y.sort)
              .slice(0, number)
              .sort((x, y) => parseInt(x.SeatNo, 10) - parseInt(y.SeatNo, 10))
              .map((item) => item.ID),
          });
      } else if (target === '小組') {
        firebase
          .firestore()
          .doc(`/classrooms/${state.roomId}/events/group`)
          .get()
          .then((doc) => {
            if (doc.exists) {
              const { results } = doc.data();

              if (results) {
                const groups = results.map((group) => {
                  return state.students.filter((student) =>
                    group.split(',').includes(`${student.ID}`),
                  );
                });

                firebase
                  .firestore()
                  .doc(`/classrooms/${state.roomId}/events/random`)
                  .set({
                    method,
                    target,
                    number,
                    results: groups
                      .map((group) =>
                        group
                          .map((item) => ({
                            ...item,
                            key: `student-${item.ID}`,
                            sort: Math.random(),
                          }))
                          .sort((x, y) => x.sort - y.sort)
                          .slice(0, number),
                      )
                      .map((group) =>
                        group.map((student) => student.ID).join(),
                      ),
                  });
              }
            }
          });
      }
    };

    const groupCommander = (command) => {
      firebase.firestore().doc(`/classrooms/${state.roomId}/events/main`).set({
        key: 'classroom.group.open',
        timestamp: Date.now(),
      });

      let [, method, number] = command;
      method = ['隨機'].includes(method) ? method : '隨機';

      try {
        number = parseInt(number, 10);
      } catch {
        number = { 兩: 2 }[number];
      }

      if (number === 0 || number === undefined) return;

      let data = [];
      let results = [];

      data = state.students
        .map((item) => {
          const student = { ...item };
          student.key = `student-${item.ID}`;
          student.sort = Math.random();
          student.type = 'result_student';
          return student;
        })
        .sort((x, y) => x.sort - y.sort);

      for (let i = 0; i < number; i += 1) {
        results.push([]);
      }

      data.forEach((item, index) => {
        results[index % number].push(item);
      });

      results = results.map((group) =>
        group.map((student) => student.ID).join(),
      );

      firebase.firestore().doc(`/classrooms/${state.roomId}/events/group`).set({
        method,
        number,
        results,
      });

      firebase
        .firestore()
        .doc(`/classrooms/${state.roomId}/events/random`)
        .delete();
    };

    stt.continuous = true;
    stt.interimResults = true;
    stt.lang = 'cmn-Hant-TW';
    stt.onresult = (event) => {
      const { isFinal } = event.results[event.results.length - 1];
      const { transcript: aTranscript } = event.results[
        event.results.length - 1
      ][0];

      setTranscript(aTranscript);

      if (isFinal) {
        const timerCommand = aTranscript.match(
          /[^#]*?(倒數|.*)計時?(([0-9]+)|兩)秒/,
        );
        const randomCommand = aTranscript.match(
          /[^#]*?(全班|小組|.*)[^#]*?(隨機|.*)抽(([0-9]+)|兩)人/,
        );
        const groupCommand = aTranscript.match(
          /[^#]*?(隨機|.*)分(([0-9]+)|兩)組/,
        );

        setSpeeching(false);
        stt.stop();

        setTimeout(() => {
          if (timerCommand) {
            timerCommander(timerCommand);
          } else if (randomCommand) {
            randomCommander(randomCommand);
          } else if (groupCommand) {
            groupCommander(groupCommand);
          } else {
            setFailed(true);
          }
        }, 500);
      }
    };

    toggle();

    return () => stt.stop();
    // eslint-disable-next-line
  }, []);

  const toggle = () => {
    if (speeching) {
      setSpeeching(false);
      stt.stop();
    } else {
      setSpeeching(true);
      stt.start();
    }
  };

  return (
    <React.Fragment>
      <Dialog open={alert}>
        <DialogTitle>學生尚未分組!</DialogTitle>
        <DialogActions>
          <div style={{ flex: 1 }} />
          <Button color="primary" onClick={() => setAlert(false)}>
            確定
          </Button>
        </DialogActions>
      </Dialog>
      <Fade in timeout={1000}>
        <div className={classes.main}>
          <div className={classes.content}>
            <Typography variant="h6">倒數計時 10 秒</Typography>
            <Typography variant="h6">全班隨機抽 5 人</Typography>
            <Typography variant="h6">小組隨機抽 1 人</Typography>
            <Typography variant="h6">隨機分 5 組</Typography>
            <div style={{ height: theme.spacing(3) }} />
            <Typography
              variant="h5"
              className={clsx({ [classes.failed]: failed })}
            >
              {transcript}
            </Typography>
          </div>
          <div className={classes.row}>
            <IconButton
              color={speeching ? 'secondary' : 'default'}
              className={
                speeching ? classes.speechingButton : classes.normalButton
              }
              onClick={toggle}
            >
              {speeching ? (
                <MicIcon style={{ fontSize: '3rem' }} />
              ) : (
                <MicNoneIcon style={{ fontSize: '3rem' }} />
              )}
            </IconButton>
          </div>
        </div>
      </Fade>
    </React.Fragment>
  );
};

export default Component;
