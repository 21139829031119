import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { MoreVert as MoreIcon } from '@material-ui/icons';
import moment from 'moment';

import * as firebase from 'firebase/app';
import 'firebase/firestore';

import * as Assets from '../../assets';
import { AppContext } from '../../App';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#1bc1bf',
    backgroundImage: `url(${Assets.Schedule})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'top',
    position: 'relative',
    width: theme.spacing(46),
    height: theme.spacing(55),
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  topContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  periodTimeText: {
    color: '#f1f1f1',
  },
  courseNameText: {
    color: '#f1f1f1',
    marginTop: theme.spacing(3),
  },
  teacherNameText: {
    color: '#f1f1f1',
    marginTop: theme.spacing(2),
  },
  bottomContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  nextEventCourseNameText: {
    fontSize: '1.5rem',
    color: '#f1f1f1',
  },
  nextEventTeacherNameText: {
    fontSize: '1rem',
    color: '#f1f1f1',
  },
  moreButton: {
    position: 'absolute',
    top: 8,
    right: 8,
    zIndex: 1,
    color: '#f1f1f1',
  },
}));

const Component = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { state } = useContext(AppContext);
  const [currentEvent, setCurrentEvent] = useState({});
  const [nextEvent1, setNextEvent1] = useState({});
  const [nextEvent2, setNextEvent2] = useState({});
  const [events, setEvents] = useState([]);
  const [timestamp, setTimestamp] = useState(Date.now());
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const day = moment().day();
    const today = [
      '星期日',
      '星期一',
      '星期二',
      '星期三',
      '星期四',
      '星期五',
      '星期六',
    ][day];

    const unsubscribe = firebase
      .firestore()
      .collection(`/devices/${state.device.id}/schedule`)
      .where('day', '==', today)
      .onSnapshot((querySnapshot) => {
        const data = [];
        querySnapshot.forEach((doc) => {
          data.push(doc.data());
        });
        data.sort((x, y) => (x.period > y.period ? 1 : -1));

        setEvents(data);
        setTimestamp(Date.now());
      });

    return () => unsubscribe();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const today = moment().format('YYYY-MM-DD');
    const currentTime = moment();

    let index = 0;
    events.forEach((event) => {
      const beginTime = moment(`${today} ${event.beginTime}`);
      if (currentTime >= beginTime) {
        index = event.period - 1;
      }
    });

    setCurrentEvent(
      events[index]
        ? {
            ...events[index],
            periodTime: `${events[index].beginTime} - ${events[index].endTime}`,
          }
        : {
            period: 0,
            beginTime: '00:00',
            endTime: '00:00',
            periodTime: '08:00 - 09:00',
            courseName: '這一節課',
            teacherName: '授課教師',
          },
    );
    setNextEvent1(
      events[index + 1]
        ? {
            ...events[index + 1],
            periodTime: `${events[index + 1].beginTime} - ${
              events[index + 1].endTime
            }`,
          }
        : null,
    );
    setNextEvent2(
      events[index + 2]
        ? {
            ...events[index + 2],
            periodTime: `${events[index + 2].beginTime} - ${
              events[index + 2].endTime
            }`,
          }
        : null,
    );

    const timeout = setTimeout(() => {
      setTimestamp(Date.now());
    }, 60 * 1000);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [timestamp]);

  const renderDialog = () =>
    open && (
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>今日課表</DialogTitle>
        {events.length > 0 ? (
          <DialogContent style={{ minWidth: 280 }}>
            <List>
              {events.map((item, idx) => (
                <React.Fragment key={`event-${idx}`}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar
                        style={{ backgroundColor: theme.palette.primary.main }}
                      >
                        {idx + 1}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={item.courseName}
                      secondary={`${item.beginTime} - ${item.endTime} ${item.teacherName}`}
                    />
                  </ListItem>
                  {events.length > 1 && idx < events.length - 1 && (
                    <Divider variant="inset" />
                  )}
                </React.Fragment>
              ))}
            </List>
          </DialogContent>
        ) : (
          <DialogContent>
            <Typography variant="h4" color="error" gutterBottom>
              還沒有今日課表啦!
            </Typography>
          </DialogContent>
        )}
      </Dialog>
    );

  return (
    <Paper elevation={3} className={classes.root}>
      {renderDialog()}
      <Tooltip title="今日課表">
        <IconButton
          className={classes.moreButton}
          onClick={() => setOpen(true)}
        >
          <MoreIcon />
        </IconButton>
      </Tooltip>
      <div className={classes.content}>
        <div className={classes.topContent}>
          <Typography variant="h4" className={classes.periodTimeText}>
            {currentEvent.periodTime}
          </Typography>
          <Typography variant="h3" className={classes.courseNameText}>
            {currentEvent.courseName}
          </Typography>
          <Typography variant="h5" className={classes.teacherNameText}>
            {currentEvent.teacherName}
          </Typography>
        </div>
        <div className={classes.bottomContent}>
          <List style={{ flex: 1, padding: 0 }}>
            {nextEvent1 && (
              <ListItem
                key="nextEvent1"
                style={{ backgroundColor: 'rgba(0,0,0,.17)' }}
              >
                <ListItemAvatar>
                  <Avatar
                    style={{
                      color: '#f1f1f1',
                      backgroundColor: 'rgba(0,0,0,.3)',
                    }}
                  >
                    {nextEvent1.period}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={nextEvent1.courseName}
                  secondary={`${nextEvent1.periodTime} ${nextEvent1.teacherName}`}
                  classes={{
                    primary: classes.nextEventCourseNameText,
                    secondary: classes.nextEventTeacherNameText,
                  }}
                />
              </ListItem>
            )}
            {nextEvent2 && (
              <ListItem
                key="nextEvent2"
                style={{ backgroundColor: 'rgba(0,0,0,.17)' }}
              >
                <ListItemAvatar>
                  <Avatar
                    style={{
                      color: '#f1f1f1',
                      backgroundColor: 'rgba(0,0,0,.3)',
                    }}
                  >
                    {nextEvent2.period}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={nextEvent2.courseName}
                  secondary={`${nextEvent2.periodTime} ${nextEvent2.teacherName}`}
                  classes={{
                    primary: classes.nextEventCourseNameText,
                    secondary: classes.nextEventTeacherNameText,
                  }}
                />
              </ListItem>
            )}
          </List>
        </div>
      </div>
    </Paper>
  );
};

export default Component;
