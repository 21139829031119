import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Typography,
} from '@material-ui/core';
import moment from 'moment';

import '../../assets/icomoon/style.css';
import { AppContext } from '../../App';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#212121',
    width: theme.spacing(52),
    height: theme.spacing(40),
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  topContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  bottomContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginTop: theme.spacing(4),
  },
  leftContent: {
    flex: 6.5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rightContent: {
    flex: 5.5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  countyText: {
    fontSize: '3rem',
    color: '#f1f1f1',
  },
  tempText: {
    fontSize: '2rem',
    color: '#f1f1f1',
    marginTop: theme.spacing(0.5),
  },
  ciText: {
    fontSize: '1.5rem',
    color: '#f1f1f1',
    textAlign: 'center',
    padding: theme.spacing(0, 2),
  },
  wxText: {
    fontSize: '1.2rem',
    color: '#f1f1f1',
    textAlign: 'center',
    padding: theme.spacing(0, 2),
    marginTop: theme.spacing(1),
  },
  popText: {
    fontSize: '1.2rem',
    color: '#f1f1f1',
  },
  uviText: {
    fontSize: '1.2rem',
    color: '#f1f1f1',
    marginTop: theme.spacing(0.5),
  },
  pm25Text: {
    fontSize: '1.2rem',
    color: '#f1f1f1',
    marginTop: theme.spacing(0.5),
  },
  icon: {
    fontSize: '6rem',
    color: '#f1f1f1',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  powered: {
    color: '#f1f1f1',
  },
}));

const Component = () => {
  const classes = useStyles();
  const { state } = useContext(AppContext);
  const [county] = useState(state.device.county);
  const [icon, setIcon] = useState('icon-day-01');
  const [wx, setWx] = useState('');
  const [ci, setCi] = useState('');
  const [minTemp, setMinTemp] = useState('');
  const [maxTemp, setMaxTemp] = useState('');
  const [pop, setPop] = useState('');
  const [uvi, setUvi] = useState('');
  const [pm25, setPM25] = useState('');
  const [cleanOpen, toggleCleanOpen] = useState(false);

  useEffect(() => {
    const messageListener = (event) => {
      try {
        const { action, data } = JSON.parse(event.data);

        switch (action) {
          case 'RESPONSE_OPENDATA_WEATHER': {
            parseWeatherResponse(data);
            break;
          }
          case 'RESPONSE_OPENDATA_UVI': {
            parseUVIResponse(data);
            break;
          }
          case 'RESPONSE_OPENDATA_PM_25': {
            parsePM25Response(data);
            break;
          }
          default: {
            /** empty */
          }
        }
      } catch (err) {
        /** empty */
      }
    };

    window.addEventListener('message', messageListener);

    sendRequest();

    const interval = setInterval(sendRequest, 60 * 60 * 1000);

    return () => {
      window.removeEventListener('message', messageListener);
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  const sendRequest = () => {
    state.communication.postMessage(
      JSON.stringify({
        action: 'REQUEST_OPENDATA_WEATHER',
        data: { county },
      }),
    );

    state.communication.postMessage(
      JSON.stringify({
        action: 'REQUEST_OPENDATA_UVI',
        data: { county },
      }),
    );

    state.communication.postMessage(
      JSON.stringify({
        action: 'REQUEST_OPENDATA_PM_25',
      }),
    );
  };

  const parseWeatherResponse = (response) => {
    const [location] = response.records.location;
    const [wxNode] = location.weatherElement.filter(
      (item) => item.elementName === 'Wx',
    )[0].time;
    const [ciNode] = location.weatherElement.filter(
      (item) => item.elementName === 'CI',
    )[0].time;
    const [mintNode] = location.weatherElement.filter(
      (item) => item.elementName === 'MinT',
    )[0].time;
    const [maxtNode] = location.weatherElement.filter(
      (item) => item.elementName === 'MaxT',
    )[0].time;
    const [popNode] = location.weatherElement.filter(
      (item) => item.elementName === 'PoP',
    )[0].time;

    setIcon(
      `${
        moment().hours() >= 5 && moment().hours() < 17
          ? 'icon-day'
          : 'icon-night'
      }-${
        wxNode.parameter.parameterValue < 10
          ? `0${wxNode.parameter.parameterValue}`
          : wxNode.parameter.parameterValue
      }`,
    );
    setWx(wxNode.parameter.parameterName);
    setCi(ciNode.parameter.parameterName);
    setMinTemp(`${mintNode.parameter.parameterName}°`);
    setMaxTemp(`${maxtNode.parameter.parameterName}°`);
    setPop(`${popNode.parameter.parameterName} %`);
  };

  const parseUVIResponse = (response) => {
    const [uv = { UVI: '' }] = response
      .sort((x, y) =>
        Date.parse(x.PublishTime) > Date.parse(y.PublishTime) ? 1 : -1,
      )
      .filter((item) => item.County.substr(0, 2) === county.substr(0, 2));

    setUvi(uv.UVI);
  };

  const parsePM25Response = (response) => {
    const [feed = { PM2_5_AVG: '' }] = []
      .concat(response.feeds || [])
      .filter((item) => item.device_id === state.device.airbox.deviceId);

    setPM25(`${feed.PM2_5_AVG || '--'} µg/m³`);
  };

  const renderCleanDialog = () =>
    cleanOpen && (
      <Dialog open={cleanOpen} onClose={() => toggleCleanOpen(false)}>
        <DialogTitle>移除裝置綁定</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`裝置代碼「${state.device.id}」將從此瀏覽器移除`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div style={{ flex: 1 }} />
          <Button color="secondary" onClick={() => toggleCleanOpen(false)}>
            取消
          </Button>
          <Button
            color="primary"
            onClick={() => {
              state.communication.postMessage(
                JSON.stringify({
                  action: 'REMOVE_DEVICE_ID',
                }),
              );

              window.location.reload();
            }}
          >
            確定
          </Button>
        </DialogActions>
      </Dialog>
    );

  return (
    <Paper elevation={3} className={classes.root}>
      {renderCleanDialog()}
      <div className={classes.content}>
        <div className={classes.topContent}>
          <div className={classes.leftContent}>
            <i className={clsx(icon, classes.icon)} />
          </div>
          <div className={classes.rightContent}>
            <div className={classes.countyText}>{county}</div>
            <div className={classes.tempText}>{`${minTemp} - ${maxTemp}`}</div>
          </div>
        </div>
        <div className={classes.bottomContent}>
          <div className={classes.leftContent}>
            <div className={classes.ciText}>{ci}</div>
            <div className={classes.wxText}>{wx}</div>
          </div>
          <div className={classes.rightContent}>
            <div className={classes.popText}>{`降雨機率：${pop}`}</div>
            <div className={classes.uviText}>{`紫外線：${uvi}`}</div>
            <div className={classes.pm25Text}>{`PM2.5：${pm25}`}</div>
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        <Button onClick={() => toggleCleanOpen(true)}>
          <Typography variant="caption" className={classes.powered}>
            {`POWERED BY ISCHOOL INC. ${state.version}.${state.container.version}.${state.device.id} `}
          </Typography>
        </Button>
      </div>
    </Paper>
  );
};

export default Component;
