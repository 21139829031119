import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Avatar,
  Button,
  Dialog,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  AlarmOn as AlarmOnIcon,
  AlarmOff as AlarmOffIcon,
  Close as UncheckIcon,
  Done as CheckIcon,
  HighlightOffOutlined as CloseIcon,
  ThumbUpOutlined as ScoreIcon,
} from '@material-ui/icons';

import Chart from 'chart.js';
import 'chartjs-plugin-colorschemes/src/plugins/plugin.colorschemes';
import { Tableau10 } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.tableau';

import * as firebase from 'firebase/app';
import 'firebase/firestore';

import { AppContext } from '../../../App';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgba(0,0,0,.67)',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  caption: {
    color: '#f1f1f1',
    marginLeft: theme.spacing(2),
  },
  scoreIcon: {
    color: '#f1f1f1',
    fontSize: '3rem',
  },
  statusIcon: {
    color: '#f1f1f1',
    fontSize: '3rem',
  },
  closeIcon: {
    color: '#f1f1f1',
    fontSize: '3rem',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(3),
    overflow: 'auto',
  },
  students: {
    overflow: 'auto',
    minWidth: 200,
  },
  chart: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkAvatar: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
  },
  uncheckAvatar: {
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    backgroundColor: theme.palette.secondary.main,
  },
  buttons1: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: theme.spacing(1),
  },
  buttons2: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
}));

const Component = ({ callback }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { state } = useContext(AppContext);
  const [result, setResult] = useState({});
  const [scores, setScores] = useState({});
  const [status, setStatus] = useState('');
  const [number, setNumber] = useState(1);
  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [correct, setCorrect] = useState(1);
  const [countdown, setCountdown] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    const unsubscribe1 = firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/interactives/0000000000000`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const item = doc.data();

          setResult(item);
          setNumber(item.number);
          setCountdown(item.time || 0);

          const data = Array.from({ length: item.number }).map(() => 0);
          Object.keys(item).forEach((key) => {
            if (key.includes('student')) {
              data[item[key].content - 1] = data[item[key].content - 1] + 1;
            }
          });

          const labels = Array.from({ length: item.number }).map(() => '');
          Array.from({ length: item.number }).forEach((i, idx) => {
            labels[idx] = `第 ${idx + 1} 項 ${data[idx]} 票`;
          });

          var ctx = (document.getElementById('chart') || {}).getContext('2d');

          new Chart(ctx, {
            type: 'doughnut',
            data: {
              labels,
              datasets: [{ data }],
            },
            options: {
              responsive: true,
              legend: {
                position: 'right',
                labels: { fontSize: 14, fontColor: '#f1f1f1' },
              },
              layout: {
                padding: {
                  left: theme.spacing(2),
                  right: theme.spacing(2),
                  top: theme.spacing(2),
                  bottom: theme.spacing(2),
                },
              },
              tooltips: { enabled: false },
              plugins: {
                colorschemes: {
                  scheme: Tableau10,
                },
              },
            },
          });

          setStatus(item.status);
        }
      });

    const unsubscribe2 = firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/score`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          setScores(doc.data());
        }
      });

    return () => {
      unsubscribe1();
      unsubscribe2();
      clearInterval(timer);

      firebase
        .firestore()
        .doc(`/classrooms/${state.roomId}/interactives/0000000000000`)
        .update({
          status: 'close',
          timestamp: Date.now(),
        });
    };
    // eslint-disable-next-line
  }, []);

  const giveScore = () => {
    const data = {
      timestamp: Date.now(),
    };

    Object.keys(result).forEach((key) => {
      if (result[key].content === correct) {
        const score = scores[key] || 0;
        data[key] = score + 1;
      }
    });

    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/score`)
      .update(data);

    setOpen(false);
  };

  const toggleStatus = () => {
    firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/interactives/0000000000000`)
      .update({
        status: status === 'open' ? 'close' : 'open',
      });

    setCurrentTime(0);
    clearInterval(timer);

    if (status === 'close') {
      if (countdown > 0) {
        let _time = countdown;
        const _timer = setInterval(() => {
          if (_time === 0) {
            firebase
              .firestore()
              .doc(`/classrooms/${state.roomId}/interactives/0000000000000`)
              .update({
                status: 'close',
                timestamp: Date.now(),
              });

            return clearInterval(_timer);
          }

          _time = _time - 1;
          setCurrentTime(_time);
        }, 1000);
        setTimer(_timer);
      }
    }
  };

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Stepper activeStep={activeStep} orientation="vertical">
          <Step key="step-1">
            <StepLabel>請選擇正確答案</StepLabel>
            <StepContent>
              <div className={classes.buttons1}>
                {Array.from({ length: number }).map((_, idx) => (
                  <Button
                    key={`ans-${idx}`}
                    variant="contained"
                    color="primary"
                    style={{ margin: theme.spacing(0.5) }}
                    onClick={() => {
                      setCorrect(idx + 1);
                      setActiveStep(activeStep + 1);
                    }}
                  >
                    {idx + 1}
                  </Button>
                ))}
              </div>
            </StepContent>
          </Step>
          <Step key="step-2">
            <StepLabel>確定回答 {correct} 的學生加 1 分？</StepLabel>
            <StepContent>
              <div className={classes.buttons2}>
                <Button
                  color="primary"
                  onClick={() => setActiveStep(activeStep - 1)}
                >
                  上一步
                </Button>
                <Button color="primary" onClick={giveScore}>
                  確定
                </Button>
              </div>
            </StepContent>
          </Step>
        </Stepper>
      </Dialog>
      <div className={classes.header}>
        <Typography variant="h4" className={classes.caption}>
          快問快答
        </Typography>
        {currentTime > 0 && (
          <Typography
            variant="h4"
            className={classes.caption}
            style={{ marginLeft: 40 }}
          >
            倒數 {currentTime} 秒
          </Typography>
        )}
        <div style={{ flex: 1 }} />
        <Tooltip title="學生加分">
          <IconButton
            onClick={() => {
              setActiveStep(0);
              setOpen(true);
            }}
          >
            <ScoreIcon className={classes.scoreIcon} />
          </IconButton>
        </Tooltip>
        <Tooltip title={status === 'open' ? '關閉互動' : '開啟互動'}>
          <IconButton onClick={toggleStatus}>
            {status === 'open' ? (
              <AlarmOnIcon className={classes.statusIcon} />
            ) : (
              <AlarmOffIcon className={classes.statusIcon} />
            )}
          </IconButton>
        </Tooltip>
        <Tooltip title="關閉">
          <IconButton onClick={callback}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </Tooltip>
      </div>
      <div className={classes.content}>
        <Paper className={classes.students}>
          <List>
            {state.students.map((student) => (
              <ListItem key={`student-${student.ID}`}>
                <ListItemAvatar>
                  <Avatar
                    className={
                      result[`student_${student.ID}`]
                        ? classes.checkAvatar
                        : classes.uncheckAvatar
                    }
                  >
                    {result[`student_${student.ID}`] ? (
                      <CheckIcon />
                    ) : (
                      <UncheckIcon />
                    )}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={`${student.SeatNo} ${student.Name}`} />
              </ListItem>
            ))}
          </List>
        </Paper>
        <div className={classes.chart}>
          <canvas id="chart"></canvas>
        </div>
      </div>
    </div>
  );
};

export default Component;
