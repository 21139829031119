import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Divider,
  Icon,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  TextField,
} from '@material-ui/core';
import moment from 'moment';

import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';

import { withStore } from '../../context';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    overflow: 'auto',
  },
  leftContent: {
    width: 240,
    position: 'relative',
    overflow: 'auto',
    border: '1px solid #ccc',
    borderRadius: theme.spacing(1),
  },
  leftList: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  rightContent: {
    position: 'relative',
    overflow: 'auto',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  rightItems: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  loading: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Component = ({ userName, target }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [storageBucket] = useState(firebase.app('classroom-1campus-devices'));
  const [items, setItems] = useState([]);
  const [saving, setSaving] = useState(false);
  const [progress, setProgress] = useState(0);
  const [current, setCurrent] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');

  useEffect(() => {
    if (!target) {
      return;
    }

    const unsubscribe = firebase
      .firestore()
      .collection(`/devices/${target.deviceId}/photo`)
      .onSnapshot((querySnapshot) => {
        const data = [];
        querySnapshot.forEach((doc) => {
          const item = doc.data();
          data.push({
            docId: doc.id,
            title: item.title,
            url: item.url,
            file: item.file,
            time: moment(new Date(parseInt(doc.id, 10))).format(
              'YYYY-MM-DD HH:mm',
            ),
          });
        });
        data.sort((x, y) => (y.sort > x.sort ? 1 : -1));

        setItems(data);
      });

    return () => unsubscribe();
    // eslint-disable-next-line
  }, [target]);

  const saveItem = () => {
    if (saving || !current) {
      return;
    }

    setSaving(true);

    const { title, url, file, docId } = current;

    const data = {
      title,
      url: url || '',
      time: firebase.firestore.FieldValue.serverTimestamp(),
      createBy: userName,
    };

    if (file) {
      data.file = file;
    }

    firebase
      .firestore()
      .doc(`/devices/${target.deviceId}/photo/${docId || Date.now()}`)
      .set(data)
      .then((_) => {
        setSaving(false);
        setCurrent(null);
      })
      .catch((_) => {
        setSaving(false);
      });
  };

  const deleteItem = (item) => {
    if (item.docId) {
      firebase
        .firestore()
        .doc(`/devices/${target.deviceId}/photo/${item.docId}`)
        .delete();
    }

    if (item.file) {
      storageBucket.storage().ref().child(item.file).delete();
    }

    setCurrent(null);
  };

  const renderConfirmDialog = () =>
    confirm && (
      <Dialog open={confirm}>
        <DialogTitle>確定刪除這張照片？</DialogTitle>
        <DialogActions>
          <div style={{ flex: 1 }} />
          <Button color="secondary" onClick={() => setConfirm(false)}>
            取消
          </Button>
          <Button
            color="primary"
            onClick={() => {
              setConfirm(false);
              deleteItem(current);
            }}
          >
            確定
          </Button>
        </DialogActions>
      </Dialog>
    );

  const renderAlertDialog = () =>
    alert && (
      <Dialog open={alert}>
        <DialogTitle>{alertMsg}</DialogTitle>
        <DialogActions>
          <div style={{ flex: 1 }} />
          <Button color="primary" onClick={() => setAlert(false)}>
            確定
          </Button>
        </DialogActions>
      </Dialog>
    );

  return (
    <div className={classes.root}>
      {renderConfirmDialog()}
      {renderAlertDialog()}
      <div className={classes.leftContent}>
        <List className={classes.leftList}>
          {items.map((item, index) => (
            <React.Fragment key={`item-${item.docId}`}>
              <ListItem button onClick={() => setCurrent(item)}>
                <ListItemAvatar>
                  <Avatar src={item.url} />
                </ListItemAvatar>
                <ListItemText primary={item.title} />
              </ListItem>
              {index < items.length - 1 && <Divider variant="fullWidth" />}
            </React.Fragment>
          ))}
        </List>
      </div>
      {current ? (
        <div className={classes.rightContent}>
          <div className={classes.rightItems}>
            <TextField
              label="照片標題"
              multiline
              rowsMax="2"
              value={current.title}
              margin="normal"
              variant="outlined"
              onChange={(e) => {
                setCurrent({
                  ...current,
                  title: e.target.value,
                });
              }}
            />
            <div style={{ height: theme.spacing(2) }} />
            <TextField
              label="照片網址"
              multiline
              rowsMax="2"
              value={current.url}
              margin="normal"
              variant="outlined"
              onChange={(e) => {
                setCurrent({
                  ...current,
                  url: e.target.value,
                });
              }}
            />
            {current.isNew && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  margin: theme.spacing(2, 0),
                }}
              >
                <input
                  type="file"
                  accept="image/*"
                  style={{ fontSize: '1rem' }}
                  onChange={(e) => {
                    const [file] = e.target.files;

                    if (!file) {
                      return;
                    }

                    if (file.size > 500000) {
                      setAlertMsg('檔案大小必須小於 500k。');
                      setAlert(true);
                      return;
                    }

                    const uploadTask = storageBucket
                      .storage()
                      .ref()
                      .child(`${target.deviceId}/${file.name}`)
                      .put(file);

                    uploadTask.on(
                      'state_changed',
                      (snapshot) =>
                        setProgress(
                          (snapshot.bytesTransferred / snapshot.totalBytes) *
                            100,
                        ),
                      (error) => {
                        setAlertMsg(`Upload is failed: ${error}`);
                        setAlert(true);
                      },
                      () => {
                        uploadTask.snapshot.ref
                          .getDownloadURL()
                          .then((downloadURL) => {
                            setCurrent({
                              ...current,
                              title: file.name,
                              url: downloadURL,
                              file: `${target.deviceId}/${file.name}`,
                            });
                          });
                      },
                    );
                  }}
                />
                <div style={{ height: theme.spacing(2) }} />
                <LinearProgress
                  variant="determinate"
                  value={progress}
                  style={{ width: '100%' }}
                />
              </div>
            )}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              {current.docId && (
                <IconButton onClick={() => setConfirm(true)}>
                  <Icon>delete</Icon>
                </IconButton>
              )}
              <div style={{ flex: 1 }} />
              {!saving && (
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => setCurrent(null)}
                >
                  取消
                </Button>
              )}
              <div style={{ width: theme.spacing(2) }} />
              <Button color="primary" variant="contained" onClick={saveItem}>
                {saving ? '儲存中...' : '儲存'}
              </Button>
            </div>
            {current.url && (
              <div
                style={{
                  backgroundImage: `url('${current.url}')`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  width: 480,
                  height: 300,
                  margin: theme.spacing(2),
                }}
              />
            )}
          </div>
        </div>
      ) : (
        <div
          className={classes.rightContent}
          style={{
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              setCurrent({ title: '', url: '', isNew: true });
              setProgress(0);
            }}
          >
            新增照片
          </Button>
        </div>
      )}
    </div>
  );
};

export default withStore(Component);
