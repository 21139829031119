import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import {
  Chat as ChatIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  ColorLens as ThemeIcon,
  ExitToApp as ExitIcon,
  Menu as MenuIcon,
  Mic as VoiceIcon,
  MoreVert as MoreIcon,
  Payment as CardIcon,
  TextFormat as CodeIcon,
  VideoCall as VideoCallIcon,
  VoiceChat as VoiceChatIcon,
} from '@material-ui/icons';
import QRCode from 'qrcode.react';

import * as firebase from 'firebase/app';
import 'firebase/firestore';

import * as Assets from '../../../assets';
import { AppContext } from '../../../App';

import Apps from './apps';
import Students from './students';

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end',
    ...theme.mixins.toolbar,
  },
  logoHeader: {
    flex: 1,
    display: 'flex',
    alignItems: 'flex-end',
    padding: theme.spacing(0, 1),
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    backgroundColor: '#e8e5db',
    backgroundImage: `url(${Assets.Background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'bottom',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const Component = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { state, dispatch } = useContext(AppContext);
  const [open, setOpen] = useState(true);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [exitApp, setExitApp] = useState(false);
  const [shareMeet, setShareMeet] = useState(false);
  const [editMeetUrl, setEditMeetUrl] = useState(false);
  const [meetUrl, setMeetUrl] = useState('');

  useEffect(() => {
    if (!state.roomId) return;

    const unsubscribe1 = firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const { darkMode } = doc.data();
          if (darkMode !== undefined) {
            dispatch({
              type: 'TOGGLE_DARK_MODE',
              payload: darkMode,
            });
          }
        }
      });

    const unsubscribe2 = firebase
      .firestore()
      .doc(`/classrooms/${state.roomId}/events/meet`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const { url } = doc.data();
          if (url) {
            setMeetUrl(url);
            setEditMeetUrl(false);
            setShareMeet(true);
            return;
          }
        }

        setEditMeetUrl(true);
      });

    return () => {
      unsubscribe1();
      unsubscribe2();
    };
    // eslint-disable-next-line
  }, [state.roomId]);

  useEffect(() => {
    if (!state.myInfo) return;

    const unsubscribe = firebase
      .firestore()
      .doc(`/users/${state.myInfo.uuid}`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const token = doc.data();
          window.localStorage.setItem('accessToken', token.accessToken);
          dispatch({ type: 'SET_ACCESS_TOKEN', payload: token.accessToken });
        }
      });

    return () => unsubscribe();
    // eslint-disable-next-line
  }, [state.myInfo]);

  const clickHandler = (key) => {
    firebase.firestore().doc(`/classrooms/${state.roomId}/events/main`).set({
      key,
      timestamp: Date.now(),
    });
  };

  return (
    <div className={classes.root}>
      {shareMeet && (
        <Dialog open={true} onClose={() => setShareMeet(false)}>
          {!editMeetUrl ? (
            <>
              <DialogActions>
                <div style={{ flex: 1 }} />
                <Button color="primary" onClick={() => setEditMeetUrl(true)}>
                  編輯連結
                </Button>
              </DialogActions>
              <DialogContent>
                <QRCode
                  value={meetUrl}
                  size={240}
                  fgColor={state.darkMode ? '#f1f1f1' : '#454545'}
                  bgColor="transparent"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    window.open(meetUrl, '_blank');
                  }}
                />
                <DialogContentText style={{ marginTop: theme.spacing(2) }}>
                  掃描或點擊 QRCode 加入
                </DialogContentText>
              </DialogContent>
            </>
          ) : (
            <>
              <DialogTitle>請輸入 Google Meet 視訊會議連結</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  fullWidth
                  placeholder="https://meet.google.com/xxx-xxxx-xxx"
                  variant="outlined"
                  value={meetUrl}
                  style={{ minWidth: 300 }}
                  onChange={(e) => setMeetUrl(e.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<VideoCallIcon />}
                  onClick={() =>
                    state.communication.postMessage(
                      JSON.stringify({
                        action: 'CREATE_NEW_PAGE',
                        data: {
                          key: 'https://meet.google.com/new',
                          url: 'https://meet.google.com/new',
                        },
                      }),
                    )
                  }
                >
                  新會議
                </Button>
                <div style={{ flex: 1 }} />
                <Button
                  color="primary"
                  onClick={() => {
                    firebase
                      .firestore()
                      .doc(`/classrooms/${state.roomId}/events/meet`)
                      .set({ url: meetUrl, timestamp: Date.now() });

                    firebase
                      .firestore()
                      .doc(`/classrooms/${state.roomId}`)
                      .update({ meet: meetUrl });

                    setShareMeet(false);
                  }}
                >
                  儲存
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      )}
      {exitApp && (
        <Dialog open={true} onClose={() => setShareMeet(false)}>
          <DialogTitle>確定離開教室？</DialogTitle>
          <DialogContent>
            <DialogContentText>
              如果您使用的是公共電腦，確定是否已登出其它第三方應用服務，例如：Google、1Know
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={() => setExitApp(false)}>
              取消
            </Button>
            <Button
              color="primary"
              onClick={() =>
                firebase
                  .firestore()
                  .doc(`/classrooms/${state.roomId}`)
                  .update({ status: 'close' })
              }
            >
              確定
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Popover
        open={Boolean(menuAnchorEl)}
        anchorEl={menuAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={() => setMenuAnchorEl(null)}
        disableRestoreFocus
      >
        <List>
          <ListItem
            button
            onClick={() => {
              clickHandler('classroom.chat.open');
              setMenuAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <ChatIcon />
            </ListItemIcon>
            <ListItemText>互動問答</ListItemText>
          </ListItem>
          <ListItem
            button
            onClick={() => {
              clickHandler('classroom.code.open');
              setMenuAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <CodeIcon />
            </ListItemIcon>
            <ListItemText>教室代碼</ListItemText>
          </ListItem>
          {state.container.type === 'chrome' && (
            <ListItem
              button
              onClick={() => {
                dispatch({
                  type: 'CHOOSE_APPS_ITEM',
                  payload: {
                    key: 'classroom.voice',
                    timestamp: Date.now(),
                  },
                });
                setMenuAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <VoiceIcon />
              </ListItemIcon>
              <ListItemText>語音指令</ListItemText>
            </ListItem>
          )}
          <ListItem
            button
            onClick={() => {
              firebase
                .firestore()
                .doc(`/classrooms/${state.roomId}`)
                .update({ darkMode: !state.darkMode });

              setMenuAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <ThemeIcon />
            </ListItemIcon>
            <ListItemText>切換佈景</ListItemText>
          </ListItem>
          <Divider variant="fullWidth" />
          {state.container.type === 'native' && (
            <ListItem
              button
              onClick={() => {
                clickHandler('classroom.card.open');
                setMenuAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <CardIcon />
              </ListItemIcon>
              <ListItemText>卡片管理</ListItemText>
            </ListItem>
          )}
          <ListItem
            button
            onClick={() => {
              state.tts.speak('確定離開教室');
              setExitApp(true);
              setMenuAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <ExitIcon />
            </ListItemIcon>
            <ListItemText>離開教室</ListItemText>
          </ListItem>
        </List>
      </Popover>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(true)}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          {state.myInfo && state.target && (
            <Typography variant="h6" noWrap>
              {`${state.myInfo.name} 老師您好，這堂課是 ${state.target.classroom.name}`}
            </Typography>
          )}
          <div style={{ flex: 1 }}></div>
          {state.container.type === 'chrome' && (
            <Button
              color="inherit"
              startIcon={<VoiceChatIcon />}
              onClick={() => setShareMeet(true)}
            >
              Google Meet
            </Button>
          )}
          <IconButton
            color="inherit"
            onClick={(event) => setMenuAnchorEl(event.currentTarget)}
          >
            <MoreIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <div className={classes.drawerHeader}>
          <div className={classes.logoHeader}>
            <img
              src={state.darkMode ? Assets.DarkLogo : Assets.LightLogo}
              alt="Classroom"
              style={{ height: 40 }}
            />
            <Typography
              variant="caption"
              color="primary"
              style={{ margin: theme.spacing(0, 1) }}
            >
              {state.version}
            </Typography>
          </div>
          <IconButton onClick={() => setOpen(false)}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <Students />
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        <Apps />
      </main>
    </div>
  );
};

export default Component;
